<div class="asset-add-container">
  <div>
    <h5>Tenant</h5>
    <ion-row>
      <ion-col>
        <sis-sismap-sidepane-autocomplete-selector
          [candidates]="tenants"
          [filterFn]="filterTenants"
          [formatFn]="formatTenants"
          [compareFn]="compareTenants"
          [disabled]="tenants?.length === 0"
          (selectedElement)="setSelectedTenant($event)"
          placeholder="Tenant"
        ></sis-sismap-sidepane-autocomplete-selector>
      </ion-col>
      <ion-col sizeXl="1" sizeLg="1" *ngIf="true" class="refresh-button-container">
        <button
          mat-icon-button
          matTooltip="Tenants aktualisieren"
          [matTooltipShowDelay]="200"
          (click)="loadAvailableTenants()"
        >
          <ion-icon name="refresh"> </ion-icon>
        </button>
      </ion-col>
    </ion-row>
  </div>
  <div>
    <h5>Kategorie</h5>
    <ion-row>
      <ion-col>
        <sis-sismap-sidepane-autocomplete-selector
          [candidates]="layerService.availableCategories$ | async"
          [filterFn]="filterCategories"
          [formatFn]="formatCategory"
          [compareFn]="compareCategories"
          [disabled]="(allTenantAssets$ | async)?.length === 0"
          (selectedElement)="setCategoryFilter($event)"
          placeholder="Kategorie"
        ></sis-sismap-sidepane-autocomplete-selector>
      </ion-col>
    </ion-row>
  </div>
  <div class="elements-section">
    <div>
      <h5>Elemente</h5>
      <sis-sismap-sidepane-asset-list
        [elements]="tenantAssets$ | async"
        [isLoading]="tenantAssetsLoading"
        (clickedElement)="selectDisabledElement($event)"
        (buttonClicked)="handleNewAssetActionButtonClicked($event)"
        (itemDraggedToMap)="itemDraggedToMap($event)"
        [actionButtons]="newElementListButtons"
        [showRefreshButton]="true"
        (refreshTable)="refreshTenantAssets()"
        [enableDragging]="true"
      ></sis-sismap-sidepane-asset-list>
    </div>
    <div>
      <h5>Elemente auf der Karte</h5>
      <sis-sismap-sidepane-asset-list
        [elements]="mapElements$ | async"
        (clickedElement)="selectExistingActiveElement($event)"
        (buttonClicked)="handleCurrentAssetActionButtonClicked($event)"
        [selectedElement]="selectedElement$ | async"
        [showChangedCheckbox]="true"
        [actionButtons]="currentElementListButtons"
      ></sis-sismap-sidepane-asset-list>
    </div>
  </div>
</div>

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkMenuModule } from '@angular/cdk/menu';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { StatusAdapter } from 'src/app/maps/domain/livedata/status.adapter';
import { StatusService } from 'src/app/maps/domain/livedata/status.service';
import { CustomIconAdapter } from 'src/app/maps/domain/masterdata/custom-icon.adapter';
import { GastroAdapter } from 'src/app/maps/domain/masterdata/gastro.adapter';
import { LiftAdapter } from 'src/app/maps/domain/masterdata/lift.adapter';
import { MasterDataAdapter } from 'src/app/maps/domain/masterdata/masterdata.adapter';
import { PlaceAdapter } from 'src/app/maps/domain/masterdata/place.adapter';
import { POIAdapter } from 'src/app/maps/domain/masterdata/poi.adapter';
import { SlopeAdapter } from 'src/app/maps/domain/masterdata/slope.adapter';
import { TrailAdapter } from 'src/app/maps/domain/masterdata/trail.adapter';
import { WebcamAdapter } from 'src/app/maps/domain/masterdata/webcam.adapter';
import { WindAdapter } from 'src/app/maps/domain/masterdata/wind.adapter';
import { AssetSidepaneComponent } from 'src/app/maps/map/asset-sidepane/asset-sidepane.component';
import { AssetSidepaneContentComponent } from 'src/app/maps/map/asset-sidepane/asset-sidepane-content/asset-sidepane-content.component';
import { EnterTheViewportNotifierDirective } from 'src/app/maps/map/enter-viewport-notifier.directive';
import { IconService } from 'src/app/maps/map/icon-service';
import { LayerService } from 'src/app/maps/map/layer-selector/layer.service';
import { LayerSelectorComponent } from 'src/app/maps/map/layer-selector/layer-selector.component';
import { LayerSelectorItemComponent } from 'src/app/maps/map/layer-selector/layer-selector-item/layer-selector-item.component';
import { MapComponent } from 'src/app/maps/map/map.component';
import { MapElementContainerComponent } from 'src/app/maps/map/map-element-layer/map-element-container.component';
import { MapElementCurveComponent } from 'src/app/maps/map/map-element-layer/map-element-curve/map-element-curve.component';
import { MapElementIconComponent } from 'src/app/maps/map/map-element-layer/map-element-icon/map-element-icon.component';
import { AddAssetTabComponent } from 'src/app/maps/map/sidepane/add-asset-tab/add-asset-tab.component';
import { AssetListComponent } from 'src/app/maps/map/sidepane/add-asset-tab/asset-list/asset-list.component';
import { AssetListItemComponent } from 'src/app/maps/map/sidepane/add-asset-tab/asset-list/asset-list-item/asset-list-item.component';
import { AutocompleteSelectorComponent } from 'src/app/maps/map/sidepane/add-asset-tab/autocomplete-selector/autocomplete-selector.component';
import { AssetEditorTabComponent } from 'src/app/maps/map/sidepane/asset-editor-tab/asset-editor-tab.component';
import { SidepaneComponent } from 'src/app/maps/map/sidepane/sidepane.component';
import { WebcamModalComponent } from 'src/app/maps/map/webcam-modal/webcam-modal.component';
import { WindDisplayCalculatorService } from 'src/app/maps/map/wind-display-calculator.service';
import { WindIconService } from 'src/app/maps/map/wind-icon.service';
import { ZoomService } from 'src/app/maps/map/zoom.service';
import { HoursMinutesPipe } from 'src/app/maps/pipes/hours-minutes.pipe';

@NgModule({
  declarations: [
    AddAssetTabComponent,
    AssetEditorTabComponent,
    AssetListComponent,
    AssetListItemComponent,
    AutocompleteSelectorComponent,
    EnterTheViewportNotifierDirective,
    LayerSelectorComponent,
    LayerSelectorItemComponent,
    MapComponent,
    MapElementContainerComponent,
    MapElementCurveComponent,
    MapElementIconComponent,
    SidepaneComponent,
    AssetSidepaneComponent,
    AssetSidepaneContentComponent,
    WebcamModalComponent,
    HoursMinutesPipe,
  ],
  providers: [
    CustomIconAdapter,
    GastroAdapter,
    IconService,
    LayerService,
    LiftAdapter,
    MasterDataAdapter,
    PlaceAdapter,
    POIAdapter,
    SlopeAdapter,
    StatusAdapter,
    StatusService,
    TrailAdapter,
    WebcamAdapter,
    WindAdapter,
    WindDisplayCalculatorService,
    WindIconService,
    ZoomService,
  ],
  exports: [LayerSelectorComponent, MapComponent],
  imports: [
    CdkMenuModule,
    CommonModule,
    CoreModule,
    DragDropModule,
    FormsModule,
    IonicModule,
    LeafletModule,
    MatAutocompleteModule,
    MatTooltipModule,
    ReactiveFormsModule,
    ScrollingModule,
    TranslateModule,
    MatButtonModule,
  ],
})
export class MapModule {}

import { PointExpression } from 'leaflet';
import { Season } from 'src/app/core/domain/season.enum';
import { WritablePart } from 'src/app/core/utils/type-helper';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';

export class Webcam implements MapElement {
  static readonly page: string = 'page';
  static readonly iframe: string = 'iframe';
  static readonly external: string = 'external';

  static readonly ratio_16_9: string = '16:9';
  static readonly ratio_4_3: string = '4:3';
  static readonly ratio_3_2: string = '3:2';
  static readonly ratio_other: string = 'other';

  static readonly defaultIconSize: PointExpression = [18, 18];
  static readonly empty: Webcam = new Webcam({ guid: null });

  static readonly types = [Webcam.page, Webcam.iframe, Webcam.external] as const;
  static readonly aspectRatios = [Webcam.ratio_16_9, Webcam.ratio_4_3, Webcam.ratio_3_2, Webcam.ratio_other] as const;

  readonly category = SisMapAssetCategory.Webcam;

  guid: string;
  name: string;
  season: Season;
  type: (typeof Webcam.types)[number];
  iconPositions: MapPosition[];
  iconWidth: number;
  iconHeight: number;
  iconImageName = 'webcam';
  paneName = 'webcam';
  url: string;
  aspectRatio: (typeof Webcam.aspectRatios)[number];

  constructor(init?: Partial<WritablePart<Webcam>>) {
    Object.assign(this, init);
  }
}

import { OperatingInfoEntry } from 'src/app/operating-info/domain/operating-info-entry.model';
import { OperatingInfoTextAdapter } from 'src/app/operating-info/domain/operating-info-text.adapter';

export class OperatingInfoEntryAdapter {
  static adapt(items: any[]): OperatingInfoEntry[] {
    if (!items) {
      return null;
    }

    const entries: OperatingInfoEntry[] = [];

    items.forEach((i: any) => {
      entries.push({
        libraryGuid: i.libraryGuid,
        tenantGuid: i.guid,
        priority: Number(i.priority),
        color: i.color,
        texts: OperatingInfoTextAdapter.adapt(i.texts),
        timestamp: new Date(i.timestamp),
      });
    });

    return entries;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OperatingInfoEntry } from 'src/app/operating-info/domain/operating-info-entry.model';

@Component({
  selector: 'sis-operating-info-item',
  templateUrl: './operating-info-item.component.html',
  styleUrls: ['./operating-info-item.component.scss'],
})
export class OperatingInfoItemComponent implements OnInit {
  @Input() entry: OperatingInfoEntry;

  language: string;
  itemColor: string;
  operatingInfoTitle: string;
  operatingInfoText: string;
  operatingInfoTimestamp: Date;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    if (this.entry) {
      const browserLanguage = this.translateService.getBrowserLang();
      this.language = browserLanguage.match(/de|en|fr|it/) ? browserLanguage : 'en';

      this.itemColor = this.entry.color;
      this.operatingInfoTitle =
        this.entry.texts.find((text) => text.language === this.language)?.title ?? this.entry.texts[0].title;
      this.operatingInfoText =
        this.entry.texts.find((text) => text.language === this.language)?.text ?? this.entry.texts[0].text;
      this.operatingInfoTimestamp = this.entry.timestamp;
    }
  }
}

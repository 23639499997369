<ion-row
  class="sis-tenant-title-small"
  [ngStyle]="
    this.mobileStyleTenant
      ? {
          color: this.config?.tenantTitleColor,
          background: this.config?.tenantColor
        }
      : {}
  "
>
  <ion-col>
    {{ this.tenantAlias }}
  </ion-col>
</ion-row>

<ion-grid
  *ngFor="let weatherStation of this.weatherStations; trackBy: trackByGuid"
  [ngClass]="this.mobileStyleTenant ? 'sis-webreport-meteo-grid-tenant-style' : 'sis-webreport-meteo-grid-sisag-style'"
>
  <ion-row
    class="sis-webreport-meteo-station-row"
    [ngClass]="
      this.mobileStyleTenant
        ? 'sis-webreport-meteo-station-row-tenant-style'
        : 'sis-webreport-meteo-station-row-sisag-style'
    "
    style="margin-bottom: 10px; height: 45px"
  >
    <ion-col size="10" style="align-content: center">
      <span style="font-size: 20px">{{ this.weatherStation.location }}</span>
      <span style="margin-left: 15px">{{
        this.weatherStation.altitude.slice(0, -1) + ' ' + ('webreport.term.metersAboveSeaLevel' | translate)
      }}</span>
    </ion-col>
    <ion-col size="2" *ngIf="this.showWeather" style="align-content: center">
      <ion-img
        *ngIf="weatherStation.weather"
        class="sis-webreport-meteo-mobile-image"
        [src]="
          this.mobileStyleTenant
            ? 'assets/icons-meteo/desktop/' + weatherStation.weather + '.svg'
            : 'assets/icons-meteo/mobile/' + weatherStation.weather + '.svg'
        "
      ></ion-img>
    </ion-col>
  </ion-row>

  <ion-row
    class="sis-webreport-meteo-station-row"
    *ngIf="this.showTemperature && weatherStation.temperature !== null && weatherStation.temperature !== undefined"
  >
    <ion-col>
      {{ 'webreport.term.temperature' | translate }}
    </ion-col>
    <ion-col>
      {{ weatherStation.temperature + ' °C' }}
    </ion-col>
  </ion-row>

  <ion-row
    class="sis-webreport-meteo-station-row"
    *ngIf="this.showSnowCondition && weatherStation.snowConditionId !== 0"
  >
    <ion-col>
      {{ 'webreport.term.snowCondition' | translate }}
    </ion-col>
    <ion-col>
      {{ this.getSnowConditionTranslationString(weatherStation.snowConditionId) | translate }}
    </ion-col>
  </ion-row>

  <ion-row
    class="sis-webreport-meteo-station-row"
    *ngIf="this.showSlopeCondition && weatherStation.slopeConditionId !== 0"
  >
    <ion-col>
      {{ 'webreport.term.slopeCondition' | translate }}
    </ion-col>
    <ion-col>
      {{ this.getSlopeConditionTranslationString(weatherStation.slopeConditionId) | translate }}
    </ion-col>
  </ion-row>

  <ion-row class="sis-webreport-meteo-station-row" *ngIf="this.showFreshSnow">
    <ion-col>
      {{ 'webreport.term.freshSnow' | translate }}
    </ion-col>
    <ion-col>
      {{ weatherStation.freshSnow + ' cm' }}
    </ion-col>
  </ion-row>

  <ion-row class="sis-webreport-meteo-station-row" *ngIf="this.showSnowDepth">
    <ion-col>
      {{ 'webreport.term.snowDepth' | translate }}
    </ion-col>
    <ion-col>
      {{ weatherStation.snowDepth + ' cm' }}
    </ion-col>
  </ion-row>

  <ion-row class="sis-webreport-meteo-station-row" *ngIf="this.showlatestSnowfall">
    <ion-col>
      {{ 'webreport.term.latestSnowfall' | translate }}
    </ion-col>
    <ion-col>
      {{ weatherStation.latestSnowfall }}
    </ion-col>
  </ion-row>

  <ion-row class="sis-webreport-meteo-station-row" *ngIf="this.showAvalancheDangerLevel">
    <ion-col>
      {{ 'webreport.term.avalancheDangerLevel' | translate }}
    </ion-col>
    <ion-col>
      {{ this.getAvalancheDangerLevelTranslationString(weatherStation.avalancheDangerLevel) | translate }}
    </ion-col>
  </ion-row>
</ion-grid>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { SisMediaItemComponent } from 'src/app/webreport/sismedia-item/sismedia-item.component';
import { WebReportPage } from 'src/app/webreport/webreport.page';
import { WebReportMeteoDesktopComponent } from 'src/app/webreport/webreport-meteo-desktop/webreport-meteo-desktop.component';
import { WebreportMeteoMobileComponent } from 'src/app/webreport/webreport-meteo-mobile/webreport-meteo-mobile.component';
import { WebreportMeteoMobileItemComponent } from 'src/app/webreport/webreport-meteo-mobile/webreport-meteo-mobile-item/webreport-meteo-mobile-item.component';
import { WebreportMobileCategoryComponent } from 'src/app/webreport/webreport-mobile/webreport-mobile-category.component';
import { WebreportMobileTenantComponent } from 'src/app/webreport/webreport-mobile/webreport-mobile-tenant/webreport-mobile-tenant.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: WebReportPage,
      },
    ]),
    CoreModule,
    MatTooltipModule,
    TranslateModule,
  ],
  declarations: [
    WebReportPage,
    SisMediaItemComponent,
    WebreportMobileCategoryComponent,
    WebreportMobileTenantComponent,
    WebReportMeteoDesktopComponent,
    WebreportMeteoMobileComponent,
    WebreportMeteoMobileItemComponent,
  ],
})
export class WebReportModule {}

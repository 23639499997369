import { Map } from 'leaflet';

export class SisZoom {
  readonly showIcons: boolean;
  readonly iconZoom: number;

  constructor(
    public zoomLevel: number,
    public showIconZoomThreshold: number,
    public map: Map,
    public readonly baseIconZoom: number
  ) {
    const width = map.getBounds().getEast() - map.getBounds().getWest();
    const height = map.getBounds().getNorth() - map.getBounds().getSouth();

    const mapSize = map.getSize();
    const wr = mapSize.x / width;
    const hr = mapSize.y / height;

    const scaling = wr > hr ? wr : hr;

    this.iconZoom = scaling;
    this.showIcons = scaling > showIconZoomThreshold;
  }
}

export class Converter {
  static toNumberOrUndefined(value: any): number | undefined {
    return isNaN(Number(value)) ? undefined : Number(value);
  }

  static toBoolean(input: string): boolean {
    try {
      return Boolean(JSON.parse(input));
    } catch (e) {
      return false;
    }
  }

  static toDateOrUndefined(value: any): Date | undefined {
    if (value == null) {
      return undefined;
    }

    const date = new Date(value);

    if (isNaN(date.getTime())) {
      return undefined;
    }

    return date;
  }

  static firstLetterUppercase(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
}

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/core/language-selector/language.model';

@Component({
  selector: 'sis-language-selector',
  templateUrl: 'language-selector.component.html',
  styleUrls: ['language-selector.component.scss'],
})
export class LanguageSelector {
  readonly languages: Language[] = [
    { name: 'DE', fullName: 'Deutsch', key: 'de' },
    { name: 'FR', fullName: 'Français', key: 'fr' },
    { name: 'IT', fullName: 'Italiano', key: 'it' },
    { name: 'EN', fullName: 'English', key: 'en' },
  ];

  get selectedLanguage(): Language {
    const current = this.translate.currentLang ?? this.translate.defaultLang;
    const key = Array.isArray(current) ? current[0] : current;

    return this.languages.find((l) => l.key === key);
  }

  constructor(private translate: TranslateService) {}

  changeLanguage(language: Language): void {
    this.translate.use(language.key);
  }

  compare(l1: Language, l2: Language): boolean {
    return l1.key === l2.key;
  }
}

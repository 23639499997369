export enum LayerName {
  Gastro,
  Lift,
  Slope,
  Trail,
  Poi,
  Place,
  CustomIcon,
  CustomPath,
  Webcam,
  Wind,
}

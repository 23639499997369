import { CurveLatLngExpression, CurvePathData, CurveSVGCommand } from '@elfalem/leaflet-curve';
export const curveParams: CurveSVGCommand[] = ['M', 'L', 'H', 'V', 'C', 'S', 'Q', 'T', 'Z']; // should be the same as CurveSVGCommand

const isNumeric = (num: unknown): boolean =>
  (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) && !isNaN(num as number);

export function parseCurveCoords(svgCoords: string | undefined): CurvePathData | null {
  if (!svgCoords || svgCoords === '') {
    return null;
  }

  const coords: Array<CurveLatLngExpression | CurveSVGCommand> = [];

  let cs = svgCoords;
  curveParams.forEach((letter) => {
    cs = cs.replace(new RegExp(letter, 'g'), ` ${letter} `);
  });
  cs = cs.replace(/[ ]{2,}/g, ' ').trim();

  const parts = cs.split(' ');

  for (const part of parts) {
    if (part.length === 1 && !isNumeric(part)) {
      if (curveParams.includes(`${part[0]}` as CurveSVGCommand)) {
        coords.push(`${part[0]}` as CurveSVGCommand);
      } else {
        // Invalid character
        return null;
      }
    } else {
      const ns = part.split(',');
      if (ns.length > 2 || ns.length === 0) {
        // Can only have one or two values
        return null;
      }
      if (!isNumeric(ns[0])) {
        // Must be number
        return null;
      }
      if (ns.length === 1 || ns[1] === '') {
        coords.push([Number(ns[0])]);
      } else {
        if (!isNumeric(ns[1])) {
          // Must be number
          return null;
        }
        coords.push([Number(ns[0]), Number(ns[1])]);
      }
    }
  }
  return coords;
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'metersToKilometers',
})
export class MetersToKilometersPipe implements PipeTransform {
  transform(meters: number): string {
    return this.convertMetersToKilometers(meters);
  }

  convertMetersToKilometers(meters: number): string {
    const kilometers = meters / 1000;
    const roundedKilometers = Math.round(kilometers * 10) / 10; // Rundet auf eine Dezimalstelle
    return `${roundedKilometers} km`;
  }
}

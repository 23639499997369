<div class="sis-sidepane-container">
  <ion-header>
    <ion-row class="sis-tab-row">
      <ion-col
        *ngFor="let tab of this.tabs; let i = index"
        (click)="this.selectedTab = i"
        class="sis-tab-col"
        [ngClass]="{ 'sis-tab-selected': this.selectedTab === i }"
      >
        {{ tab }}
      </ion-col>
    </ion-row></ion-header
  >
  <ion-content>
    <div class="sis-content-div" [ngStyle]="{ display: this.selectedTab === 0 ? 'block' : 'none' }">
      <sis-sismap-sidepane-asset-editor-tab
        (changeToAddAssetTab)="changeToTab(1)"
      ></sis-sismap-sidepane-asset-editor-tab>
    </div>
    <div class="sis-content-div" [ngStyle]="{ display: this.selectedTab === 1 ? 'block' : 'none' }">
      <sis-sismap-sidepane-add-asset-tab (changeToEditTab)="changeToTab(0)"></sis-sismap-sidepane-add-asset-tab>
    </div>
  </ion-content>

  <ion-footer>
    <ion-row>
      <ion-col offsetXl="7" sizeXl="5" offsetLg="6" sizeLg="6">
        <sis-general-button
          text="general.term.initStatus"
          expand="block"
          btnAlign="Right"
          btnType="Secondary"
          width="100%"
          (sisClick)="this.initStatus()"
        >
        </sis-general-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="sis-edit-token">
          <label>EditToken: </label>
          <input [value]="this.editToken$ | async" (input)="this.tokenInput($event)" />
          <ion-icon
            *ngIf="this.hasErrors$ | async"
            name="warning-outline"
            [matTooltip]="this.assetsWithErrorTooltip$ | async"
            matTooltipClass="sis-global-tooltip"
            class="sis-sidepane-error-icon"
          ></ion-icon>
        </div>
      </ion-col>
      <ion-col sizeXl="5" sizeLg="6">
        <sis-general-button
          text="general.term.saveAll"
          expand="block"
          btnAlign="Right"
          [disabled]="(this.canSave$ | async) === false"
          btnType="Secondary"
          (sisClick)="this.save()"
          width="100%"
        >
        </sis-general-button>
      </ion-col>
    </ion-row>
  </ion-footer>
</div>

<!-- Desktop Screen -->
<ion-grid *ngIf="this.bigScreenMode" class="ion-no-padding">
  <ion-row class="sis-ion-row vertical-align-content">
    <ion-col>
      <ion-icon
        [name]="this.item.options.iconName"
        class="sis-statusicon"
        [ngClass]="this.item.status === null ? 'sis-no-status' : this.item.options.colorClassName"
        matTooltip="{{
          (this.item.status !== null ? this.item.options.tooltipTranslationString : 'webreport.term.noStatus')
            | translate
        }}"
      ></ion-icon>
      <img class="sis-icon" [src]="this.icon" [matTooltip]="this.item.typeDescription" />
      <div
        *ngIf="this.config?.showLabel && this.longestLabelLength !== 0"
        class="sis-label"
        [style.maxWidth]="this.showingLabelLength"
        [style.minWidth]="this.showingLabelLength"
      >
        {{ this.item.label }}
      </div>
      <div class="sis-name sis-truncate">
        {{ this.item.name }}
      </div>
      <div
        *ngIf="
          this.config?.showLength &&
          this.item.lengthMeter !== 0 &&
          (this.item.assetCategory === 2 || this.item.assetCategory === 3)
        "
        class="sis-length"
      >
        {{ this.item.lengthMeter | metersToKilometers }}
      </div>
    </ion-col>
  </ion-row>
</ion-grid>

<!-- Mobile Screen -->
<ion-grid *ngIf="!this.bigScreenMode" class="ion-no-padding">
  <ion-row
    class="sis-ion-row-small vertical-align-content"
    [ngClass]="this.mobileStyleTenant ? 'sis-ion-row-small-tenant-style' : 'sis-ion-row-small-sisag-style'"
  >
    <ion-col>
      <ion-icon
        [name]="this.item.options.iconName"
        class="sis-statusicon-small"
        [ngClass]="this.item.status === null ? 'sis-no-status' : this.item.options.colorClassName"
        matTooltip="{{
          (this.item.status !== null ? this.item.options.tooltipTranslationString : 'webreport.term.noStatus')
            | translate
        }}"
      ></ion-icon>
      <img class="sis-icon-small" [class.sis-icon-invert]="!mobileStyleTenant" [src]="this.icon" />
      <div
        *ngIf="this.config?.showLabel && this.longestLabelLength !== 0"
        class="sis-label"
        [style.maxWidth]="this.showingLabelLength"
        [style.minWidth]="this.showingLabelLength"
      >
        {{ this.item.label }}
      </div>
      <div class="sis-name sis-truncate">
        {{ this.item.name }}
      </div>
    </ion-col>
  </ion-row>
</ion-grid>

import { OperatingInfoText } from 'src/app/operating-info/domain/operating-info-text.model';

export class OperatingInfoTextAdapter {
  static adapt(items: any[]): OperatingInfoText[] {
    if (!items) {
      return null;
    }

    const texts: OperatingInfoText[] = [];

    items.forEach((i: any) => {
      texts.push({
        language: i.language,
        title: i.title,
        text: i.text,
      });
    });

    return texts;
  }
}

import { BreakdownInfo } from 'src/app/breakdowninfo/breakdowninfo.model';

export class BreakdownInfoAdapter {
  static adapt(item: any): BreakdownInfo {
    if (!item) {
      return null;
    }

    return {
      titleDe: item.titleDe,
      titleEn: item.titleEn,
      titleFr: item.titleFr,
      titleIt: item.titleIt,
      textDe: item.textDe,
      textEn: item.textEn,
      textFr: item.textFr,
      textIt: item.textIt,
      name: item.name,
      iconName: item.iconName,
      color: item.color,
      logoFilename: item.logoFilename,
      websiteUrl: item.website,
      telNumber: item.telNumber,
    };
  }
}

<ion-row *ngIf="!this.meteoInfosReport" class="sis-center">
  <ion-col>
    <ion-spinner></ion-spinner>
  </ion-col>
</ion-row>

<ng-container *ngIf="this.meteoInfosReport.size > 0">
  <sis-webreport-meteo-mobile-item
    *ngFor="let tenant of this.meteoInfosReport | keyvalue; trackBy: trackByTenantKey"
    [meteoInfos$]="this.meteoInfos$"
    [mobileStyleTenant]="this.mobileStyleTenant"
    [config]="this.config"
    [tenantAlias]="tenant.key"
    [sisMediaSettingByTenantAlias]="this.sisMediaSettingByTenantAlias"
  ></sis-webreport-meteo-mobile-item>
</ng-container>

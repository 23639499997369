import { PointExpression } from 'leaflet';
import { Season } from 'src/app/core/domain/season.enum';
import { WritablePart } from 'src/app/core/utils/type-helper';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';

export class Wind implements MapElement {
  static readonly defaultIconSize: PointExpression = [60, 60];
  static readonly empty: Wind = new Wind({ guid: null });

  readonly category = SisMapAssetCategory.Wind;

  guid: string;
  name: string;
  season: Season;
  type: string;
  paneName = 'wind';

  iconPositions: MapPosition[];
  iconWidth: number;
  iconHeight: number;
  iconUseMeteoData?: boolean;

  meteoStationSisId: string;
  directionOffsetPanoMap: number;
  directionOffsetNorth: number;

  windSpeedKmh: number | null;
  windDirectionDegreeAxis: number | null;
  windDirectionDegreeNorth: number | null;

  constructor(init?: Partial<WritablePart<Wind>>) {
    Object.assign(this, init);
  }
}

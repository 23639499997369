import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { SisMediaAssetCategory } from 'src/app/webreport/domain/sismedia-asset-category.enum';
import { SisMediaItem } from 'src/app/webreport/domain/sismedia-item.model';
import { WebReportConfig } from 'src/app/webreport/domain/webreport-config.model';
import { WebReportMobile } from 'src/app/webreport/domain/webreport-mobile.model';
import pixelWidth from 'string-pixel-width';

@Component({
  selector: 'sis-webreport-mobile-category',
  templateUrl: './webreport-mobile-category.component.html',
  styleUrls: ['./webreport-mobile-category.component.scss'],
})
export class WebreportMobileCategoryComponent extends Unsubscriber implements OnInit {
  @Input() category: SisMediaAssetCategory;
  @Input() items: SisMediaItem[] = [];
  @Input() itemsUpdated$: Observable<void>;
  @Input() config: WebReportConfig;
  @Input() mobileStyleTenant: boolean;

  webReportMobileItems: WebReportMobile[];

  constructor(private changeDetection: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.itemsUpdated$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      const mobileItems = this.items.reduce((p: WebReportMobile[], c: SisMediaItem) => {
        let webReportMobile = p.find((m) => m.tenantAlias === c.tenantAlias);
        if (!webReportMobile) {
          webReportMobile = {
            tenantAlias: c.tenantAlias,
            items: [],
            longestLabelLength: 0,
          };
          p.push(webReportMobile);
        }
        webReportMobile.items.push(c);
        webReportMobile.items
          .sort((a, b) => (a.name < b.name ? -1 : 1))
          .sort((a, b) => (a.type < b.type ? -1 : 1))
          .sort((a, b) => a.order - b.order);
        return p;
      }, []);

      for (const mobileItem of mobileItems) {
        let longestLabelLengthPixel = 0;
        for (const item of mobileItem.items) {
          longestLabelLengthPixel = Math.max(
            longestLabelLengthPixel,
            pixelWidth(item.label, { font: 'arial', size: 13 })
          );
        }
        mobileItem.longestLabelLength = longestLabelLengthPixel;
      }

      this.webReportMobileItems = mobileItems;

      this.changeDetection.detectChanges();
    });
  }

  trackByTenantAlias(_index: number, item: WebReportMobile): string {
    return item.tenantAlias;
  }
}

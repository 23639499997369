export class OutdatedDataHelper {
  static isOutdated(timestamp: Date, timeoutMs: number): boolean {
    if (timestamp == null) {
      return true;
    }

    const timeNowMs: number = new Date().getTime();
    const timestampMs: number = new Date(timestamp).getTime();

    return timeNowMs - timestampMs > timeoutMs;
  }

  static dateIsToday(timestamp: Date): boolean {
    if (timestamp == null) {
      return false;
    }

    const now = new Date();
    const isToday =
      timestamp.getFullYear() === now.getFullYear() &&
      timestamp.getMonth() === now.getMonth() &&
      timestamp.getDate() === now.getDate();

    return isToday;
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment-timezone';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { Season } from 'src/app/core/domain/season.enum';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { SisMediaAssetCategory } from 'src/app/webreport/domain/sismedia-asset-category.enum';
import { SisMediaItem } from 'src/app/webreport/domain/sismedia-item.model';
import { SisMediaSetting } from 'src/app/webreport/domain/sismedia-setting.model';
import { WebReport } from 'src/app/webreport/domain/webreport.model';
import { WebReportConfig } from 'src/app/webreport/domain/webreport-config.model';
import { WebReportDesktop } from 'src/app/webreport/domain/webreport-desktop.model';
import { WebReportDesktopRow } from 'src/app/webreport/domain/webreport-desktop-row.model';
import { WebReportInfotext } from 'src/app/webreport/domain/webreport-infotext.model';
import { WebReportMeteoInfoItem } from 'src/app/webreport/domain/webreport-meteo-info-item.model';
import { WebReportMobile } from 'src/app/webreport/domain/webreport-mobile.model';
import { WebReportMobileAssetCategory } from 'src/app/webreport/domain/webreport-mobile-asset-category.enum';
import { WebReportService } from 'src/app/webreport/webreport.service';
import { environment } from 'src/environments/environment';
import pixelWidth from 'string-pixel-width';
import { findIana } from 'windows-iana';

@Component({
  templateUrl: './webreport.page.html',
  styleUrls: ['./webreport.page.scss'],
  selector: 'sis-webreport',
})
export class WebReportPage extends Unsubscriber implements OnInit {
  private static readonly titleTypesByAssetCategoryMap: Map<SisMediaAssetCategory, Map<string, string>> = new Map([
    [
      SisMediaAssetCategory.Slope,
      new Map([
        ['pisteleicht', 'Slope'],
        ['pistemittel', 'Slope'],
        ['pisteschwer', 'Slope'],
        ['langlauf', 'Crosscountry'],
        ['langlaufklassisch', 'Crosscountry'],
        ['langlaufskating', 'Crosscountry'],
        ['schlitteln', 'Sledging'],
        ['airboard', 'Airboard'],
      ]),
    ],
    [
      SisMediaAssetCategory.Trail,
      new Map([
        ['wandern', 'Hiking'],
        ['schneeschuh', 'Snowshoe'],
        ['bike', 'Bike'],
        ['downhill', 'Bike'],
        ['skitour', 'Skitour'],
      ]),
    ],
    [
      SisMediaAssetCategory.Poi,
      new Map([
        ['shop', 'ShopRental'],
        ['schlittenvermietung', 'ShopRental'],
        ['skivermietung', 'ShopRental'],
        ['trottivermietung', 'ShopRental'],
        ['veloverleih', 'ShopRental'],
        ['vermietung', 'ShopRental'],
      ]),
    ],
  ]);

  private static readonly titleOrder = [
    SisMediaAssetCategory[SisMediaAssetCategory.Slope],
    'Slope',
    'Crosscountry',
    'Sledging',
    'Airboard',
    SisMediaAssetCategory[SisMediaAssetCategory.Trail],
    'Hiking',
    'Snowshoe',
    'HikingWinter',
    'Bike',
    'ShopRental',
    SisMediaAssetCategory[SisMediaAssetCategory.Poi],
  ];

  private readonly selectedAssetCategory$ = new ReplaySubject<WebReportMobileAssetCategory>(1);

  private readonly urlParams$: Observable<[Params, Params]> = combineLatest([
    this.activatedRoute.params,
    this.activatedRoute.queryParams,
  ]);

  readonly webReport$: Observable<WebReport> = this.urlParams$.pipe(
    switchMap(([params, queryParams]) => {
      const urlName = params['urlName'] as string;
      const season: number = +queryParams['season'] as number;
      const columnsInParams: number = Number.parseInt(queryParams['columns'], 10);
      const assetFilters: string[] =
        (queryParams['hide'] as string)
          ?.trim()
          .toLowerCase()
          .split(',')
          .filter((f) => f !== '') ?? [];

      return this.webReportService.requestSisMediaAssets(urlName, columnsInParams).pipe(
        map((webReport) => {
          const assetCategories = [...webReport.sisMediaItemsByCategory.keys()].map(
            (key) => SisMediaAssetCategory[key]
          );

          assetCategories.forEach((assetCategory) => {
            if (assetFilters.includes(assetCategory.toLowerCase())) {
              webReport.sisMediaItemsByCategory.delete(SisMediaAssetCategory[assetCategory]);
            }
          });

          webReport.sisMediaItemsByCategory.forEach((items, category) => {
            const filteredItems = items.filter((item) => {
              if (
                season in Season &&
                season !== Season.SummerAndWinter &&
                item.season !== season &&
                item.season !== Season.SummerAndWinter
              ) {
                return false;
              }

              return !assetFilters.some((assetFilter) => item.type?.toLowerCase() === assetFilter);
            });

            if (filteredItems.length === 0) {
              webReport.sisMediaItemsByCategory.delete(category);
            } else {
              webReport.sisMediaItemsByCategory.set(category, filteredItems);
            }
          });

          if (assetFilters.includes('meteo')) {
            webReport.meteoInfosByTenantAlias = new Map<string, WebReportMeteoInfoItem[]>();
          }

          return webReport;
        })
      );
    }),
    shareReplay(1)
  );

  readonly webReportMeteoItems$: Observable<Map<string, WebReportMeteoInfoItem[]>> = this.webReport$.pipe(
    map((webReport) => {
      const webReportMeteoItems = new Map<string, WebReportMeteoInfoItem[]>();
      if (!webReport) {
        return webReportMeteoItems;
      }

      webReport.meteoInfosByTenantAlias.forEach((values, key) => {
        const filteredValues = values
          .filter((value) => webReport.exportConfig?.meteoStations?.includes(value.location))
          .sort((a, b) => {
            const indexA = webReport.exportConfig?.meteoStations?.indexOf(a.location);
            const indexB = webReport.exportConfig?.meteoStations?.indexOf(b.location);
            return indexA - indexB;
          });

        if (filteredValues.length > 0) {
          webReportMeteoItems.set(key, filteredValues);
        }
      });

      return webReportMeteoItems;
    }),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    })
  );

  showHeader = false;
  showFooter = false;
  showLegend = false;
  isMobileDataLoaded = false;
  isDesktopDataLoaded = false;
  allowPageBreak = false;
  showTimestamp = false;

  bigScreenMode: boolean;
  config: WebReportConfig;
  sisMediaSettingByTenantAlias: Map<string, SisMediaSetting>;

  // big screen data
  alias: string;
  headerImage: string;
  website: string;
  websiteUrl: string;
  phone: string;
  report: WebReport;
  timestamp: Date;

  webReportDesktopItems: WebReportDesktop[] = [];
  gridMinHeight = 0;

  // small screen data
  titleTranslationString: string;
  selectedAssetCategory: string;
  webReportItemsByCategory: Map<SisMediaAssetCategory, SisMediaItem[]> = new Map();
  hasLifts: boolean;
  hasSlopes: boolean;
  hasGastros: boolean;
  hasTrails: boolean;
  hasPois: boolean;
  showMeteo: boolean;
  mobileStyleTenant = false;

  @ViewChild('desktopGrid', { read: ElementRef }) desktopGrid: ElementRef;

  constructor(
    private webReportService: WebReportService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        switchMap((queryParams) => {
          if (queryParams['columns']) {
            return of(true);
          } else {
            return this.screenSizeService.bigScreenMode$;
          }
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.urlParams$.pipe(takeUntil(this.onDestroy$)).subscribe(([, queryParams]) => {
      this.showHeader = queryParams['header'] === 'true';
      this.showFooter = queryParams['footer'] === 'true';
      this.showLegend = queryParams['legend'] === 'true';
      this.allowPageBreak = queryParams['allowPageBreak'] === 'true';
      this.showTimestamp = queryParams['timestamp'] === 'true';
    });

    this.webReport$.pipe(takeUntil(this.onDestroy$)).subscribe((webReport) => {
      this.setHeaderImageUrl(webReport.exportConfig.headerImage);

      this.alias = webReport.tenant.alias;
      this.websiteUrl = webReport.tenant.website;
      this.website = webReport.tenant.website?.replace(/^https?:\/\//, '');
      this.phone = webReport.tenant.phone;
      this.config = webReport.exportConfig;
      this.sisMediaSettingByTenantAlias = webReport.sisMediaSettingByTenantAlias;

      this.hasLifts = webReport.sisMediaItemsByCategory.has(SisMediaAssetCategory.Lift);
      this.hasSlopes = webReport.sisMediaItemsByCategory.has(SisMediaAssetCategory.Slope);
      this.hasGastros = webReport.sisMediaItemsByCategory.has(SisMediaAssetCategory.Gastro);
      this.hasTrails = webReport.sisMediaItemsByCategory.has(SisMediaAssetCategory.Trail);
      this.hasPois = webReport.sisMediaItemsByCategory.has(SisMediaAssetCategory.Poi);
      this.showMeteo = webReport.meteoInfosByTenantAlias.size > 0 && webReport.exportConfig?.meteoStations?.length > 0;
      this.mobileStyleTenant = webReport.exportConfig?.mobileStyleTenant || false;

      this.webReportItemsByCategory = webReport.sisMediaItemsByCategory;
      this.isMobileDataLoaded = true;

      if (!this.selectedAssetCategory) {
        const initialCategory = this.hasLifts
          ? WebReportMobileAssetCategory.Lift
          : this.hasSlopes
          ? WebReportMobileAssetCategory.Slope
          : this.hasGastros
          ? WebReportMobileAssetCategory.Gastro
          : this.hasTrails
          ? WebReportMobileAssetCategory.Trail
          : this.hasPois
          ? WebReportMobileAssetCategory.Poi
          : WebReportMobileAssetCategory.Meteo;

        this.selectedAssetCategory$.next(initialCategory);
      }

      const timeZoneIANA = findIana(webReport.tenant.timeZoneId)[0];
      this.timestamp = moment().tz(timeZoneIANA).toDate();
    });

    combineLatest([this.webReport$, this.urlParams$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([webReport]) => {
        if (this.desktopGrid) {
          const currentHeight = this.desktopGrid.nativeElement?.offsetHeight;
          this.gridMinHeight = Math.max(this.gridMinHeight, currentHeight);
        }

        this.webReportDesktopItems = this.buildWebReportDesktopItems(webReport).sort((a, b) => a.order - b.order);

        this.isDesktopDataLoaded = true;
      });

    this.selectedAssetCategory$.pipe(takeUntil(this.onDestroy$)).subscribe((selectedAssetCategory) => {
      this.selectedAssetCategory = WebReportMobileAssetCategory[selectedAssetCategory];
      this.titleTranslationString = this.selectedAssetCategory
        ? `webreport.assetCategory${this.selectedAssetCategory}`
        : '';
    });

    window.onmessage = (event: MessageEvent): void => {
      if (event?.data?.update) {
        this.webReportService.requestUpdate();
      }
    };
  }

  assetCategorySelected(selection: string): void {
    const assetCategory = WebReportMobileAssetCategory[selection];
    this.selectedAssetCategory$.next(assetCategory);
  }

  trackByTenantAlias(_index: number, item: WebReportDesktop | WebReportMobile): string {
    return item.tenantAlias;
  }

  private sisMediaItemsByTenantAlias(items: SisMediaItem[]): Map<string, SisMediaItem[]> {
    if (!items?.length) {
      return new Map<string, SisMediaItem[]>();
    }

    return items.reduce((p, c) => {
      let list = p.get(c.tenantAlias);
      if (!list) {
        list = [];
        p.set(c.tenantAlias, list);
      }
      list.push(c);
      return p;
    }, new Map<string, SisMediaItem[]>());
  }

  private buildWebReportDesktopItems(webReport: WebReport): WebReportDesktop[] {
    const assetMap = new Map<SisMediaAssetCategory, Map<string, SisMediaItem[]>>([
      [
        SisMediaAssetCategory.Lift,
        this.sisMediaItemsByTenantAlias(webReport.sisMediaItemsByCategory.get(SisMediaAssetCategory.Lift)),
      ],
      [
        SisMediaAssetCategory.Slope,
        this.sisMediaItemsByTenantAlias(webReport.sisMediaItemsByCategory.get(SisMediaAssetCategory.Slope)),
      ],
      [
        SisMediaAssetCategory.Trail,
        this.sisMediaItemsByTenantAlias(webReport.sisMediaItemsByCategory.get(SisMediaAssetCategory.Trail)),
      ],
      [
        SisMediaAssetCategory.Gastro,
        this.sisMediaItemsByTenantAlias(webReport.sisMediaItemsByCategory.get(SisMediaAssetCategory.Gastro)),
      ],
      [
        SisMediaAssetCategory.Poi,
        this.sisMediaItemsByTenantAlias(webReport.sisMediaItemsByCategory.get(SisMediaAssetCategory.Poi)),
      ],
    ]);

    const resultMap = new Map<
      string,
      {
        infotext: string;
        asset: Map<SisMediaAssetCategory, { items: WebReportDesktopRow[]; titles: string[] }>;
      }
    >();

    for (const [assetCategory, tenantItemMap] of assetMap) {
      for (const [tenantAlias, items] of tenantItemMap) {
        if (!resultMap.has(tenantAlias)) {
          resultMap.set(tenantAlias, {
            infotext: this.infotextLang(
              this.translateService.currentLang ?? this.translateService.defaultLang,
              webReport.infotextsByTenantAlias.get(tenantAlias)
            ),
            asset: new Map<SisMediaAssetCategory, { items: WebReportDesktopRow[]; titles: string[] }>(),
          });
          for (const category of assetMap.keys()) {
            resultMap.get(tenantAlias).asset.set(category, {
              items: [],
              titles: [''],
            });
          }
        }

        const tenantResult = resultMap.get(tenantAlias).asset.get(assetCategory);
        tenantResult.titles = this.getCategoryTitles(
          items,
          SisMediaAssetCategory[assetCategory],
          WebReportPage.titleTypesByAssetCategoryMap.get(assetCategory)
        );

        const columns = webReport.exportConfig.columns;
        const rows = Math.ceil(items.length / columns);
        for (let r = 0; r < rows; r++) {
          tenantResult.items[r] = { rowItems: new Array(columns), longestLabelLengths: new Array(columns) };
        }

        for (let i = 0; i < items.length; i++) {
          tenantResult.items[i % rows].rowItems[Math.floor(i / rows)] = items[i];
        }

        const longestLabelArrayPixel = new Array(columns).fill(0);
        for (let i = 0; i < columns; i++) {
          for (const item of tenantResult.items) {
            if (item.rowItems[i]) {
              longestLabelArrayPixel[i] = Math.max(
                longestLabelArrayPixel[i],
                pixelWidth(item.rowItems[i].label, { font: 'arial', size: 15 })
              );
            }
          }
        }
        for (let r = 0; r < rows; r++) {
          tenantResult.items[r].longestLabelLengths = longestLabelArrayPixel;
        }
      }
    }

    const result: WebReportDesktop[] = [];
    for (const [tenantAlias, itemMap] of resultMap) {
      let order = webReport.tenantOrders?.indexOf(tenantAlias);
      if (order == null || order === -1) {
        order = 999;
      }
      result.push({
        tenantAlias,
        order,
        infotext: itemMap.infotext,
        lifts: itemMap.asset.get(SisMediaAssetCategory.Lift)?.items,
        liftsTitles: itemMap.asset.get(SisMediaAssetCategory.Lift)?.titles,
        slopes: itemMap.asset.get(SisMediaAssetCategory.Slope)?.items,
        slopesTitles: itemMap.asset.get(SisMediaAssetCategory.Slope)?.titles,
        gastros: itemMap.asset.get(SisMediaAssetCategory.Gastro)?.items,
        gastrosTitles: itemMap.asset.get(SisMediaAssetCategory.Gastro)?.titles,
        trails: itemMap.asset.get(SisMediaAssetCategory.Trail)?.items,
        trailsTitles: itemMap.asset.get(SisMediaAssetCategory.Trail)?.titles,
        pois: itemMap.asset.get(SisMediaAssetCategory.Poi)?.items,
        poisTitles: itemMap.asset.get(SisMediaAssetCategory.Poi)?.titles,
      });
    }

    return result;
  }

  private setHeaderImageUrl(image: string): void {
    this.headerImage = `${environment.baseUrlPublicAssets}/webreport/header/${image}`;
  }

  private infotextLang(languageKey: string, infotext: WebReportInfotext): string {
    if (!infotext) {
      return null;
    }
    switch (languageKey) {
      case 'de':
        return infotext.textDe;
      case 'en':
        return infotext.textEn;
      case 'fr':
        return infotext.textFr;
      case 'it':
        return infotext.textIt;
      default:
        return infotext.textEn;
    }
  }

  private getCategoryTitles(
    items: SisMediaItem[],
    defaultTitle: string,
    extraTitleTypes?: Map<string, string>
  ): string[] {
    const titleTypes = extraTitleTypes ?? new Map<string, string>();

    return Array.from(
      new Set(
        items
          .map((item) => {
            const titleType = titleTypes.get(item.type);

            switch (titleType) {
              case 'Hiking':
                if (item.season === Season.Winter || item.season === Season.SummerAndWinter) {
                  return `${titleType}Winter`;
                }
                if (item.season === Season.Summer || item.season === Season.SummerAndWinter) {
                  return defaultTitle;
                }
                break;
              default:
                return titleType ?? defaultTitle;
            }
          })
          .sort((a, b) => WebReportPage.titleOrder.indexOf(a) - WebReportPage.titleOrder.indexOf(b))
      )
    ).map((t) => `webreport.assetCategory${t}`);
  }
}

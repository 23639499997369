import { CurvePathData } from '@elfalem/leaflet-curve';
import { Marker } from 'leaflet';
import { IconHelper } from 'src/app/core/leaflet-helper/icon-helper';
import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';
import { CurveEndMarkers } from 'src/app/maps/map/style/curve-end-markers.model';
import { IconColor } from 'src/app/maps/map/style/icon-color.model';
import { MapStyle } from 'src/app/maps/map/style/styles/map-style.base';
import { environment } from 'src/environments/environment';

export class MapStyleSisag extends MapStyle {
  readonly name = 'sisag';

  private readonly urlBlobPath = 'icons/sismedia/styles/sisag/';

  constructor(protected iconHelper: IconHelper) {
    super(iconHelper);
  }

  async getIconSvg(iconName: string): Promise<string> {
    const url = this.urlBlobPath + iconName + '.svg';

    return this.iconHelper.getIcon(url);
  }

  getLayerIconSvgUrl(layerIconName: string, alternateStyle: boolean): string {
    return `${environment.baseUrlPublicAssets}/sismap/layer-selector-icons/${layerIconName}.svg`;
  }

  getSeasonIconSvgUrl(layerIconName: string, alternateStyle: boolean): string {
    return '';
  }

  createCurveEndMarkers(curveCoords: CurvePathData, color: string, element: MapElement): CurveEndMarkers {
    return { visible: [], highlight: [] };
  }

  updateCurveEndMarkers(
    curveCoords: CurvePathData,
    curveEndMarkers: CurveEndMarkers,
    color: string,
    element: MapElement
  ): void {}

  zoomCurveEndMarkers(curveEndMarkers: CurveEndMarkers, scaling: number): void {}

  setHighlight(curveEndMarkers: CurveEndMarkers, highlight: boolean): void {}

  getLiftColor(assetStatus: AssetStatus, element: MapElement, overrideColor = false): IconColor {
    if (element.pathColor != null && !overrideColor) {
      return { statusColor: element.pathColor, textColor: '#ffffff' };
    }

    switch (assetStatus) {
      case AssetStatus.Open:
        return { statusColor: '#1dab38', textColor: '#ffffff' };
      case AssetStatus.Closed:
        return { statusColor: '#af272f', textColor: '#ffffff' };
      case AssetStatus.Preparation:
        return { statusColor: '#e68523', textColor: '#ffffff' };
      case AssetStatus.Disabled:
        return { statusColor: '#555555', textColor: '#ffffff' };
      case AssetStatus.NoStatus:
        return { statusColor: '#555555', textColor: '#ffffff' };
      default:
        return { statusColor: '#555555', textColor: '#ffffff' };
    }
  }

  getColor(status: AssetStatus): IconColor {
    switch (status) {
      case AssetStatus.Open:
        return { statusColor: '#1dab38', textColor: '#ffffff' };
      case AssetStatus.Closed:
        return { statusColor: '#af272f', textColor: '#ffffff' };
      case AssetStatus.Preparation:
        return { statusColor: '#e68523', textColor: '#ffffff' };
      case AssetStatus.Disabled:
        return { statusColor: '#ffffff', textColor: '#000000' };
      case AssetStatus.NoStatus:
        return { statusColor: '#ffffff', textColor: '#000000' };
      default:
        return { statusColor: '#ffffff', textColor: '#000000' };
    }
  }
  setIconColor(icon: Marker, backgroundColor: string, symbolColor?: string): void {
    const e = icon.getElement()?.querySelectorAll('#background')[0];
    e?.setAttribute('fill', backgroundColor);

    if (symbolColor) {
      const s = icon.getElement()?.querySelectorAll('#symbol')[0];
      s?.setAttribute('fill', symbolColor);
    }
  }

  setSidepaneIconColor(iconSvg: string, backgroundColor: string, symbolColor?: string): string {
    iconSvg = this.setSvgColor('background', backgroundColor, iconSvg);
    if (symbolColor) {
      iconSvg = this.setSvgColor('symbol', symbolColor, iconSvg);
    }
    return iconSvg;
  }

  private setSvgColor(id: string, color: string, iconSvg: string): string {
    const regex = new RegExp(`(id="${id}"[^>]*?)fill="[^"]*"`);
    if (iconSvg.match(regex)) {
      iconSvg = iconSvg.replace(regex, `$1fill="${color}"`);
    } else {
      iconSvg = iconSvg.replace(`id="${id}"`, `id="${id}" fill="${color}"`);
    }
    return iconSvg;
  }

  setIconBorder(icon: Marker): void {
    const e = icon.getElement()?.querySelectorAll('#background')[0];

    if (e) {
      e.setAttribute('stroke', '#808080');
      e.setAttribute('stroke-width', '1');
      e.setAttribute('stroke-opacity', '0.8');
    }
  }

  async createCurvePopupContent(
    element: MapElement,
    showLabel: boolean
  ): Promise<{ popupContent: string; className: string }> {
    const colors =
      element.category === SisMapAssetCategory.Lift
        ? this.getLiftColor(element.status, element, true)
        : this.getColor(element.status);

    const svg = element.type ? await this.getIconSvg(element.type) : '';
    const parser = new DOMParser();
    const icon = parser.parseFromString(svg, 'image/svg+xml');

    const e = icon.querySelectorAll('#background')[0];
    e?.setAttribute('fill', colors.statusColor);
    const s = icon.querySelectorAll('#symbol')[0];
    s?.setAttribute('fill', colors.textColor);

    const label = showLabel && element.label ? `${element.label} ` : '';
    const popupContent = `<span>${label}${element.name}</span>${icon.documentElement.outerHTML}`;
    const className = `sis-map-tooltip-${this.name}`;

    return { popupContent, className };
  }

  async createIconPopupContent(
    element: MapElement,
    showLabel: boolean
  ): Promise<{ popupContent: string; className: string }> {
    let svg: string;
    if (element.status != null && element.status !== AssetStatus.NoStatus && element.status !== AssetStatus.Disabled) {
      const iconName = AssetStatus[element.status]?.toLowerCase();

      if (iconName) {
        svg = await this.getIconSvg(iconName);
      }
    }

    const icon = svg ? ` ${svg}` : '';
    const label = showLabel && element.label ? `${element.label} ` : '';

    const popupContent = `<span>${label}${element.name}</span>${icon}`;
    const className = `sis-map-tooltip-${this.name}`;

    return { popupContent, className };
  }

  getSignPostSvgUrl(): string {
    return `${this.urlBlobPath}signpost.svg`;
  }
}

import { Injectable } from '@angular/core';
import { shareReplay, Subject } from 'rxjs';
import { SisZoom } from 'src/app/maps/map/zoom.model';

@Injectable({
  providedIn: 'root',
})
export class ZoomService {
  private zoom$ = new Subject<SisZoom>();

  zoomChanged$ = this.zoom$.pipe(shareReplay(1));

  zoom(value: SisZoom): void {
    this.zoom$.next(value);
  }
}

import { Component, Input } from '@angular/core';
import { SisMediaItem } from 'src/app/webreport/domain/sismedia-item.model';
import { WebReportConfig } from 'src/app/webreport/domain/webreport-config.model';

@Component({
  selector: 'sis-webreport-mobile-tenant',
  templateUrl: './webreport-mobile-tenant.component.html',
  styleUrls: ['./webreport-mobile-tenant.component.scss'],
})
export class WebreportMobileTenantComponent {
  @Input() tenantAlias: string;
  @Input() items: SisMediaItem[] = [];
  @Input() config: WebReportConfig;
  @Input() mobileStyleTenant: boolean;
  @Input() longestLabelLength: number;

  trackByItemGuid(_index: number, item: SisMediaItem): string {
    return item.guid;
  }
}

import { Component } from '@angular/core';
import { NavigatorService } from 'src/app/core/navigator/navigator.service';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';

@Component({
  selector: 'sis-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage extends Unsubscriber {
  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;

  constructor(private navigator: NavigatorService, private screenSizeService: ScreenSizeService) {
    super();
  }

  redirectToCockpit(): void {
    this.navigator.redirectToCockpit();
  }

  openConditionsPage(): void {
    window.open('https://sisag.ch/impressum', 'new');
  }

  openDataProtectionPage(): void {
    window.open('https://sisag.ch/datenschutz', 'new');
  }
}

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { filter, take, takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { NavigatorService } from 'src/app/core/navigator/navigator.service';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';

@Component({
  selector: 'sis-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends Unsubscriber implements OnInit {
  backgroundColor = 'white';

  readonly routesWithoutCookieBanner = ['/sismap/', '/slideshow/', '/windmonitor/', '/report/'];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translateService: TranslateService,
    private appService: AppService,
    private ccService: NgcCookieConsentService,
    private navigatorService: NavigatorService,
    private location: Location,
    private activatedRoute: ActivatedRoute
  ) {
    super();
    this.initializeApp();

    this.ccService.popupClose$.pipe(take(1)).subscribe(() => {
      localStorage.setItem('cookieAccepted', 'true');
    });

    this.translateService.onLangChange
      .pipe(takeUntil(this.onDestroy$), takeUntil(this.ccService.popupClose$))
      .subscribe(() => {
        this.setupCookieBanner();
      });
  }

  ngOnInit(): void {
    const browserLanguage = this.translateService.getBrowserLang();
    this.translateService.setDefaultLang(browserLanguage.match(/de|en|fr|it/) ? browserLanguage : 'en');
    this.setupCookieBanner();
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.onDestroy$),
        filter((queryParams) => 'language' in queryParams)
      )
      .subscribe((queryParams) => {
        this.setLanguage(queryParams['language']);
      });
    this.appService.backgroundColor$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((backgroundColor) => (this.backgroundColor = backgroundColor));
  }

  private setLanguage(language?: 'de' | 'en' | 'fr' | 'it'): void {
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const selectedLanguage = language ?? this.translateService.getBrowserLang();
    // Calling this method multiple times in quick succession can cause a race condition where one call overtakes the other
    this.translateService.use(selectedLanguage.match(/de|en|fr|it/) ? selectedLanguage : 'en');
  }

  private setupCookieBanner(): void {
    const path = this.location.path();

    try {
      this.ccService?.destroy();
    } catch {}

    if (this.routesWithoutCookieBanner.some((r) => path.includes(r))) {
      return;
    }

    if (localStorage.getItem('cookieAccepted') !== 'true') {
      const cookieTextTranslationString = 'general.phrase.cookies';
      const cookieUnderstoodTranslationString = 'general.term.understood';

      const cookieConfig = this.ccService.getConfig();

      this.translateService
        .get([cookieTextTranslationString, cookieUnderstoodTranslationString])
        .pipe(take(1))
        .subscribe((translations) => {
          cookieConfig.content.message = translations[cookieTextTranslationString];
          cookieConfig.content.dismiss = translations[cookieUnderstoodTranslationString];
          cookieConfig.cookie.domain = this.navigatorService.baseUrl;

          this.ccService.init(cookieConfig);
        });
    }
  }

  private initializeApp(): void {
    this.platform.ready().then((readySource) => {
      if (readySource === 'cordova') {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }
    });
  }
}

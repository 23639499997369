<ion-header [ngClass]="this.className$ | async">
  <ion-toolbar>
    <ion-button color="white" slot="end" fill="clear" (click)="closeModal()">
      <ion-icon name="close"></ion-icon>
    </ion-button>
    <ion-title>{{ this.webcam.name }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <div class="sis-iframe-div">
    <ion-spinner *ngIf="this.showSpinner"></ion-spinner>
    <img
      *ngIf="!this.useIFrame"
      class="sis-image-div"
      [src]="this.webcamUrl"
      (load)="hideSpinner()"
      (error)="hideSpinner()"
    />
    <iframe
      *ngIf="this.useIFrame"
      class="frame"
      [src]="this.webcamUrl"
      (load)="hideSpinner()"
      (error)="hideSpinner()"
    ></iframe>
  </div>
</ion-content>

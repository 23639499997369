import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { Trail } from 'src/app/maps/domain/masterdata/trail.model';

export class TrailAdapter {
  static adapt(item: any): Trail {
    if (!item) {
      return Trail.empty;
    }

    const defaultColor = item.defaultColor ? item.defaultColor : Trail.defaultColor;
    const weight = item.weight ? Number(item.weight) : null;
    const defaultIconSize = item.defaultIconSize ? item.defaultIconSize : Trail.defaultIconSize;

    let positions: MapPosition[] = [];
    if (item.iconPosition && item.iconPosition !== '') {
      let iconPositions: MapPosition[] | MapPosition = JSON.parse(item.iconPosition);
      if (!Array.isArray(iconPositions)) {
        iconPositions = [iconPositions];
      }
      positions = iconPositions;
    }

    return new Trail({
      guid: item.guid,
      name: item.name,
      nameEn: item.nameEn,
      label: item.label,
      info: item.info,
      duration: item.duration,
      elevation: item.elevation,
      website: item.website,
      description: item.description,
      lengthMeter: Number(item.lengthMeter),
      type: item.type,
      season: item.season,
      path: item.path,
      pathDashArray: item.dashArray,
      pathWeight: weight,
      pathColor: item.color,
      defaultPathColor: defaultColor,
      defaultPathWeight: Trail.defaultWeight,
      status: item.status != null ? item.status : AssetStatus.NoStatus,
      iconPositions: positions,
      iconImageName: item.type,
      iconWidth: defaultIconSize[0],
      iconHeight: defaultIconSize[1],
    });
  }

  static adaptForApi(trail: Trail): any {
    const path = trail.path || null;
    const color = trail.pathColor === Trail.defaultColor ? null : trail.pathColor || null;
    const weight = trail.pathWeight === Trail.defaultWeight ? null : trail.pathWeight || null;
    const dashArray = trail.pathDashArray || null;

    return {
      guid: trail.guid,
      assetCategory: trail.category,
      path,
      iconPosition: JSON.stringify(trail.iconPositions),
      color,
      dashArray,
      weight,
    };
  }
}

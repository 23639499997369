import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { Season } from 'src/app/core/domain/season.enum';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MasterDataService } from 'src/app/maps/domain/masterdata/masterdata.service';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';
import { Webcam } from 'src/app/maps/domain/masterdata/webcam.model';
import {
  Category,
  LayerSelectorItem,
} from 'src/app/maps/map/layer-selector/layer-selector-item/layer-selector-item.model';
import { environment } from 'src/environments/environment';

// Interfaces expected from the API
interface CategoryApiCategory {
  category: string;
  types: string[];
}
interface CategoryApiResponse {
  assetCategory: SisMapAssetCategory;
  categories: CategoryApiCategory[];
}

const categoryUrl = `${environment.baseUrlApi}/api/sismap/types`;

@Injectable({
  providedIn: 'root',
})
export class LayerService {
  private readonly layerOrderConfig: { [key: string]: { order: number; imageName: string } } = {
    lift: { order: 0, imageName: 'anlagen' },
    ski: { order: 1, imageName: 'pisten' },
    crosscountry: { order: 2, imageName: 'langlauf' },
    sledging: { order: 3, imageName: 'schlitteln' },
    snowpark: { order: 4, imageName: 'snowpark' },
    hiking: { order: 5, imageName: 'wandern' },
    themedtrail: { order: 6, imageName: 'themenweg' },
    snowshoe: { order: 7, imageName: 'schneeschuh' },
    bike: { order: 8, imageName: 'bike' },
    gastro: { order: 9, imageName: 'gastro' },
    poi: { order: 10, imageName: 'poi' },
    webcam: { order: 11, imageName: 'webcam' },
  };

  readonly availableCategories$: Observable<Category[]> = this.http.get(categoryUrl, { responseType: 'json' }).pipe(
    map((response: CategoryApiResponse[]) => {
      let result: Category[] = [];
      response.forEach(
        (d) =>
          (result = result.concat(
            d.categories.map((c) => ({
              category: d.assetCategory,
              name: c.category.toLocaleLowerCase(),
              types: c.types.map((t) => t.toLocaleLowerCase()),
              showInLayerSelector: true,
            }))
          ))
      );

      // Webcam, Place, CustomIcon, CustomPath are not returned from the api
      return result.concat([
        { category: SisMapAssetCategory.Webcam, name: 'webcam', types: [...Webcam.types], showInLayerSelector: true },
        { category: SisMapAssetCategory.Place, name: 'place', types: [], showInLayerSelector: false },
        { category: SisMapAssetCategory.CustomIcon, name: 'customicon', types: [], showInLayerSelector: false },
        {
          category: SisMapAssetCategory.CustomPath,
          name: 'custompath',
          types: ['wandern', 'schneeschuh', 'bike'],
          showInLayerSelector: false,
        },
      ]);
    }),
    shareReplay(1)
  );

  readonly hideQueryParams$: Observable<string[]> = this.activatedRoute.queryParams.pipe(
    map(
      (params) =>
        (params && (params['hide'] as string)?.split(',').map((f: string) => f.toLowerCase().trim()))?.filter(
          (f) => f !== ''
        ) ?? []
    ),
    distinctUntilChanged(),
    shareReplay(1)
  );

  readonly season$ = combineLatest([this.masterdataService.masterData, this.activatedRoute.queryParams]).pipe(
    map(
      ([masterData, queryParams]) =>
        (queryParams && queryParams['season'] && Number.parseInt(queryParams['season'], 10)) || masterData.season
    ),
    distinctUntilChanged((x, y) => y === undefined || x === y),
    shareReplay(1)
  );

  readonly existingLayerSelectors$: Observable<LayerSelectorItem[]> = this.masterdataService.masterData.pipe(
    switchMap((masterData) =>
      combineLatest([this.season$, this.availableCategories$, this.hideQueryParams$]).pipe(
        map(([season, categories, hideQueryParams]) => {
          let allAssets: MapElement[] = [
            ...masterData.lifts,
            ...masterData.slopes,
            ...masterData.trails,
            ...masterData.gastros,
            ...masterData.pois,
            ...masterData.webcams,
            ...masterData.customIcons,
            ...masterData.customPaths,
          ];

          if (season) {
            allAssets = allAssets.filter(
              (asset) =>
                asset.season === undefined || asset.season === season || asset.season === Season.SummerAndWinter
            );
          }

          const res: LayerSelectorItem[] = categories
            .filter(
              (c) =>
                c.showInLayerSelector &&
                !hideQueryParams.includes(c.category.toString()) &&
                allAssets.some(
                  (asset) => c.category === asset.category && c.types.includes(asset.type?.toLocaleLowerCase())
                )
            )
            .map((c, i) => ({
              category: c,
              showLayers: true,
              order: this.layerOrderConfig[c.name]?.order ?? Object.keys(this.layerOrderConfig).length + i,
              imageName: this.layerOrderConfig[c.name]?.imageName ?? c.name,
            }));
          return res;
        })
      )
    ),
    distinctUntilChanged((a, b) => a.length === b.length && a.every((i) => b.some((j) => i.imageName === j.imageName))),
    shareReplay(1)
  );

  readonly shownAssetGuids$ = this.masterdataService.masterData.pipe(
    switchMap((masterData) =>
      combineLatest([this.hideQueryParams$, this.season$, this.availableCategories$]).pipe(
        map(([hideQueryParams, season, availableCategories]) => {
          const hiddenCategories = availableCategories.filter((c) => hideQueryParams.includes(c.name));
          return this.filterAssets(masterData.slopes, hiddenCategories, season, hideQueryParams)
            .concat(this.filterAssets(masterData.lifts, hiddenCategories, season, hideQueryParams))
            .concat(this.filterAssets(masterData.trails, hiddenCategories, season, hideQueryParams))
            .concat(this.filterAssets(masterData.gastros, hiddenCategories, season, hideQueryParams))
            .concat(this.filterAssets(masterData.pois, hiddenCategories, season, hideQueryParams))
            .concat(this.filterAssets(masterData.webcams, hiddenCategories, season, hideQueryParams))
            .concat(this.filterAssets(masterData.winds, hiddenCategories, season, hideQueryParams))
            .concat(this.filterAssets(masterData.places, hiddenCategories, season, hideQueryParams))
            .concat(this.filterAssets(masterData.customIcons, hiddenCategories, season, hideQueryParams))
            .concat(this.filterAssets(masterData.customPaths, hiddenCategories, season, hideQueryParams))
            .map((a) => a.guid);
        })
      )
    ),
    shareReplay(1)
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private masterdataService: MasterDataService,
    private router: Router,
    private http: HttpClient
  ) {}

  async addFilters(filter: Category): Promise<void> {
    const hiddenLayers = await firstValueFrom(this.hideQueryParams$);
    if (!hiddenLayers.includes(filter.name)) {
      this.updateUrlQueryParams([...hiddenLayers, filter.name]);
    }
  }

  async removeFilters(filter: Category): Promise<void> {
    const hiddenLayers = await firstValueFrom(this.hideQueryParams$);
    this.updateUrlQueryParams(hiddenLayers.filter((h) => h !== filter.name));
  }

  selectSeason(season: Season): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { season },
      queryParamsHandling: 'merge',
    });
  }

  private updateUrlQueryParams(hiddenLayers: string[]): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { hide: hiddenLayers.join(',') },
      queryParamsHandling: 'merge',
    });
  }

  private filterAssets(
    assets: MapElement[],
    hiddenCategories: Category[],
    activeSeason: Season,
    hideQueryParams: string[]
  ): MapElement[] {
    if (activeSeason) {
      assets = assets.filter(
        (a) => a.season == null || a.season === activeSeason || a.season === Season.SummerAndWinter
      );
    }

    const mapElements = assets.filter(
      (asset: MapElement) =>
        !(
          hiddenCategories.some((hiddenCategory) => hiddenCategory.types.includes(asset.type?.toLocaleLowerCase())) ||
          hideQueryParams.includes(asset.type?.toLocaleLowerCase()) ||
          hideQueryParams.includes(asset.category.toString())
        )
    );

    return mapElements;
  }
}

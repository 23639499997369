import { CustomIcon } from 'src/app/maps/domain/masterdata/custom-icon.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';

export class CustomIconAdapter {
  static adapt(item: any): CustomIcon {
    if (!item) {
      return CustomIcon.empty;
    }

    let positions: MapPosition[] = [];
    if (item.iconPosition && item.iconPosition !== '') {
      let iconPositions: MapPosition[] | MapPosition = JSON.parse(item.iconPosition);
      if (!Array.isArray(iconPositions)) {
        iconPositions = [iconPositions];
      }
      positions = iconPositions;
    }

    return new CustomIcon({
      guid: item.guid,
      url: item.url,
      name: item.title,
      season: item.season,
      iconPositions: positions,
      iconSvgBlobName: item.svgName,
      iconHeight: item.height,
      iconWidth: item.width,
      disallowHighlight: item.title == null,
      disallowPopUp: item.title == null,
    });
  }

  static adaptForApi(customIcon: CustomIcon): any {
    return {
      guid: customIcon.guid,
      assetCategory: customIcon.category,
      svgName: customIcon.iconSvgBlobName,
      title: customIcon.name,
      url: customIcon.url,
      height: customIcon.iconHeight,
      width: customIcon.iconWidth,
      iconPosition: JSON.stringify(customIcon.iconPositions),
    };
  }
}

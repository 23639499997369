<div style="display: flex; flex-direction: row">
  <div style="margin-right: 50px">
    <ion-fab *ngIf="!this.hideLayerSelector" [activated]="this.openLayerSelector" #layerFab>
      <div>
        <ion-fab-button
          (dblclick)="this.stopPropagation($event)"
          mode="md"
          data-test="layer-selector-menu"
          size="small"
          [ngClass]="this.menuButtonClass"
        >
          <ion-icon name="menu-outline"></ion-icon>
        </ion-fab-button>
        <svg
          *ngIf="this.styleName === 'andermatt'"
          xmlns="http://www.w3.org/2000/svg"
          height="40"
          width="40"
          viewbox="0 0 100 100"
          class="sis-menu-circle-andermatt"
        >
          <circle cx="50%" cy="50%" r="42%" fill-opacity="0" stroke="#000" stroke-width="1.5" />
        </svg>
      </div>

      <ion-fab-list
        side="bottom"
        (click)="this.stopPropagation($event)"
        (dblclick)="this.stopPropagation($event)"
        style="margin-top: 43px"
      >
        <ion-fab-button
          *ngFor="let layerSelector of this.layerSelectors"
          [ngClass]="
            layerSelector.showLayers
              ? this.layerSelectorActiveClass + ' ' + this.fabButtonClass
              : this.layerSelectorInactiveClass + ' ' + this.fabButtonClass
          "
          mode="md"
          [attr.data-test]="'sis-layer-selector-item-' + layerSelector.category.name"
        >
          <sis-layer-selector-item
            [layerSelector]="layerSelector"
            (clicked)="this.onClick(layerSelector)"
            [active]="layerSelector.showLayers"
          ></sis-layer-selector-item>
        </ion-fab-button>
      </ion-fab-list>
    </ion-fab>
  </div>

  <div>
    <ion-grid
      *ngIf="!this.hideSeasonFilter && (this.oppositeMapExist || this.season === 0)"
      class="sis-season-filter-grid"
    >
      <ion-row>
        <ion-col
          class="sis-season-selector-col"
          *ngIf="!this.hideSeasonFilter && (this.oppositeMapExist || this.season === 0)"
        >
          <ion-fab-button
            (click)="this.selectSeason(1)"
            [ngClass]="
              this.activeSeason !== 1
                ? this.layerSelectorInactiveClass + ' ' + this.fabButtonClass
                : this.layerSelectorActiveClass + ' ' + this.fabButtonClass
            "
            matTooltip="{{ 'general.term.summer' | translate }}"
          >
            <ion-icon *ngIf="this.styleName === 'sisag'" name="sunny-outline"></ion-icon>
            <ion-img
              *ngIf="this.styleName === 'andermatt'"
              [src]="this.summerIconSvgUrl$ | async"
              class="sis-season-icon-andermatt"
            ></ion-img>
          </ion-fab-button>
        </ion-col>
        <ion-col
          class="sis-season-selector-col"
          *ngIf="!this.hideSeasonFilter && (this.oppositeMapExist || this.season === 0)"
        >
          <ion-fab-button
            (click)="this.selectSeason(2)"
            [ngClass]="
              this.activeSeason !== 2
                ? this.layerSelectorInactiveClass + ' ' + this.fabButtonClass
                : this.layerSelectorActiveClass + ' ' + this.fabButtonClass
            "
            matTooltip="{{ 'general.term.winter' | translate }}"
          >
            <ion-icon *ngIf="this.styleName === 'sisag'" name="snow-outline"></ion-icon>
            <ion-img
              *ngIf="this.styleName === 'andermatt'"
              [src]="this.winterIconSvgUrl$ | async"
              class="sis-season-icon-andermatt"
            ></ion-img>
          </ion-fab-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <div>
    <ion-fab *ngIf="!this.hideSignPosts && this.hasSignPosts">
      <div>
        <ion-fab-button
          (dblclick)="this.stopPropagation($event)"
          mode="md"
          data-test="sign-post-menu"
          size="small"
          [ngClass]="this.menuButtonClass"
          matTooltip="{{ 'map.term.furtherMaps' | translate }}"
        >
          <ion-img
            [src]="this.signPostImageUrl$ | async"
            [ngStyle]="{ 'margin-left': this.styleName === 'andermatt' ? '1px' : '0px' }"
          ></ion-img>
        </ion-fab-button>
        <svg
          *ngIf="this.styleName === 'andermatt'"
          xmlns="http://www.w3.org/2000/svg"
          height="40"
          width="40"
          viewbox="0 0 100 100"
          class="sis-menu-circle-andermatt"
        >
          <circle cx="50%" cy="50%" r="42%" fill-opacity="0" stroke="#000" stroke-width="1.5" />
        </svg>
      </div>

      <ion-fab-list
        side="bottom"
        (click)="this.stopPropagation($event)"
        (dblclick)="this.stopPropagation($event)"
        style="margin-top: 43px; margin-left: 8px"
      >
        <ion-fab-button
          *ngFor="let signPost of this.signPosts"
          [ngClass]="this.signPostOptionClass"
          mode="md"
          (click)="signPostClicked(signPost)"
          [style.width]="this.signPostButtonWidth"
        >
          <div style="width: 100%; text-align: start; padding-left: 12px">{{ signPost.name }}</div>
        </ion-fab-button>
      </ion-fab-list>
    </ion-fab>
  </div>
</div>

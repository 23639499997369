import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IconHelper {
  private readonly iconMap = new Map<string, Observable<string>>();

  constructor(private httpClient: HttpClient) {}

  async getIcon(blobPath: string): Promise<string> {
    if (blobPath === null) {
      return '';
    }

    if (!this.iconMap.has(blobPath)) {
      const url = `${environment.baseUrlPublicAssets}/${blobPath.toLowerCase()}`;
      this.iconMap.set(
        blobPath,
        this.httpClient.get(url, { responseType: 'text' }).pipe(
          map((svg) => this.makeBackgroundVisible(svg)),
          shareReplay(1)
        )
      );
    }

    return firstValueFrom(this.iconMap.get(blobPath));
  }

  private makeBackgroundVisible(svg: string): string {
    const backgroundTags = svg.match(/<[rect|circle][\s\S]*?id="background"[\s\S]*?\/>/g);

    if (backgroundTags) {
      const backgroundTag = backgroundTags[0];

      const newBackgroundTag = backgroundTag.replace(/opacity="[\d\.]"/g, 'opacity="1"');

      return svg.replace(backgroundTag, newBackgroundTag);
    }

    return svg;
  }
}

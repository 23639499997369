import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { MeteoDataService } from 'src/app/maps/domain/livedata/meteodata.service';
import { Coords } from 'src/app/maps/domain/masterdata/coords.model';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { Wind } from 'src/app/maps/domain/masterdata/wind.model';
import { ArrowStyle, WindDisplayCalculatorService } from 'src/app/maps/map/wind-display-calculator.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WindIconService {
  private readonly windMarkerPivotPoint = new Coords(35, 35);
  private readonly cssMeteostationColorNormal = 'sis-meteostation-normal';
  private readonly cssMeteostationColorOffline = 'sis-meteostation-offline';

  readonly windIconSvgAsHtml$ = this.httpClient
    .get(`${environment.baseUrlPublicAssets}/icons/windmonitor/sis-wind-icon-windmonitor.svg`, { responseType: 'text' })
    .pipe(shareReplay(1));

  constructor(
    private httpClient: HttpClient,
    private meteoDataService: MeteoDataService,
    private windDisplayService: WindDisplayCalculatorService
  ) {}

  startLiveData(updateInterval: number, tenantGuid: string): void {
    this.meteoDataService.startLiveData({ enabled: true, updateInterval, urlParams: [tenantGuid] });
  }

  async getIconHTML(wind: MapElement): Promise<string> {
    if (!(wind instanceof Wind)) {
      return '';
    }
    const svgHtmlContent = await firstValueFrom(this.windIconSvgAsHtml$);
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgHtmlContent, 'image/svg+xml');
    doc.getElementsByClassName('wind-speed')[0].innerHTML = this.windDisplayService.getWindSpeed(wind);

    const rotationElements = doc.querySelectorAll('.wind-direction-cross,.wind-direction');
    rotationElements.forEach((rotationElement: Element) => {
      rotationElement.setAttribute(
        'transform',
        this.windDisplayService.getRotationTransform(
          wind,
          wind.directionOffsetPanoMap,
          wind.directionOffsetNorth,
          this.windMarkerPivotPoint
        )
      );
    });

    const colorElement = doc.getElementsByClassName('wind-iconcolor')[0];
    const color = wind.windSpeedKmh != null ? this.cssMeteostationColorNormal : this.cssMeteostationColorOffline;
    colorElement.classList.remove(this.cssMeteostationColorNormal, this.cssMeteostationColorOffline);
    colorElement.classList.add(color);

    const arrowStyle = this.windDisplayService.getArrowStyle(wind);
    this.applyArrowStyle(arrowStyle, doc);

    return doc.documentElement.outerHTML;
  }

  private applyArrowStyle(arrowStyle: ArrowStyle, element: Document): void {
    const elemDirCross = element.getElementsByClassName('wind-direction-cross')[0];
    const elemDir = element.getElementsByClassName('wind-direction')[0];
    const elemNone = element.getElementsByClassName('wind-direction-none')[0];
    switch (arrowStyle) {
      case ArrowStyle.ONE:
        elemDirCross.setAttribute('display', 'none');
        elemDir.setAttribute('display', 'inline');
        elemNone.setAttribute('display', 'none');
        return;
      case ArrowStyle.TWO:
        elemDirCross.setAttribute('display', 'inline');
        elemDir.setAttribute('display', 'none');
        elemNone.setAttribute('display', 'none');
        return;
      default:
        elemDirCross.setAttribute('display', 'none');
        elemDir.setAttribute('display', 'none');
        elemNone.setAttribute('display', 'inline');
        return;
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sis-number-picker',
  templateUrl: './number-picker.component.html',
  styleUrls: ['./number-picker.component.scss'],
})
export class NumberPickerComponent implements OnInit {
  @Output() value = new EventEmitter<number>();

  @Input() stepSize = 1;
  @Input() initialValue = 0;
  @Input() minimumValue = 0;
  @Input() maximumValue = 999;
  @Input() unit = '';
  @Input() color = 'transparent';
  @Input() floatRight = false;

  displayedValue: number;
  fontSize = '24pt';

  ngOnInit(): void {
    this.setDisplayedValue(this.initialValue);
    this.emit();
  }

  increment(): void {
    const newValue = Math.min(this.displayedValue + this.stepSize, this.maximumValue);
    this.setDisplayedValue(newValue);
    this.emit();
  }

  decrement(): void {
    const newValue = Math.max(this.displayedValue - this.stepSize, this.minimumValue);
    this.setDisplayedValue(newValue);
    this.emit();
  }

  private setDisplayedValue(value: number): void {
    const length = `${value}${this.unit}`.length;

    this.fontSize = `${32 - 4 * Math.max(length, 2)}pt`;

    this.displayedValue = value;
  }

  private emit(): void {
    this.value.emit(this.displayedValue);
  }
}

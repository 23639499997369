import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HoverData } from 'src/app/maps/map/hover.model';

@Injectable({
  providedIn: 'root',
})
export class HoverService {
  private onHover$ = new Subject<HoverData>();

  hoverDisabled = false;

  get onHover(): Observable<HoverData> {
    return this.onHover$.pipe(filter(() => !this.hoverDisabled));
  }

  hover(hoverData: HoverData): void {
    if (!this.hoverDisabled) {
      this.onHover$.next(hoverData);
    }
  }
}

import { AfterViewInit, Directive, ElementRef, EventEmitter, Host, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[sisEnterViewportNotifier]',
})
export class EnterTheViewportNotifierDirective implements AfterViewInit, OnDestroy {
  @Output() visibilityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private observer: IntersectionObserver;

  constructor(@Host() private _elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.0,
    };

    this.observer = new IntersectionObserver(this.callback, options);

    this.observer.observe(this._elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private callback = (entries: IntersectionObserverEntry[], _: IntersectionObserver): void => {
    entries.forEach((entry) => {
      this.visibilityChange.emit(entry.isIntersecting);
    });
  };
}

import moment from 'moment';
import { SisMediaSetting } from 'src/app/webreport/domain/sismedia-setting.model';

export class SisMediaSettingAdapter {
  static adapt(item: any): SisMediaSetting | null {
    if (!item) {
      return null;
    }

    return {
      tenantGuid: item.tenantGuid,
      winterSeasonStartDate: new Date(moment(item.winterSeasonStartDate).format('YYYY-MM-DD')),
      winterSeasonEndDate: new Date(moment(item.winterSeasonEndDate).format('YYYY-MM-DD')),
      liftsOpenFrom: item.liftsOpenFrom,
      liftsOpenTo: item.liftsOpenTo,
    };
  }
}

<ion-item lines="none" class="sis-ion-item">
  <ion-button>
    <ion-select
      [ngModel]="selectedLanguage"
      interface="popover"
      (ngModelChange)="changeLanguage($event)"
      [compareWith]="compare"
      class="sis-select"
      [selectedText]="selectedLanguage.name"
    >
      <ion-select-option *ngFor="let l of languages" [value]="l" data-test="language-select-option">{{
        l.fullName
      }}</ion-select-option>
    </ion-select>
  </ion-button>
</ion-item>

import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { IonFab } from '@ionic/angular';
import { combineLatest, from } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Season } from 'src/app/core/domain/season.enum';
import { NavigatorService } from 'src/app/core/navigator/navigator.service';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { MapRoutes } from 'src/app/maps/domain/map-routes';
import { MasterDataService } from 'src/app/maps/domain/masterdata/masterdata.service';
import { SignPost } from 'src/app/maps/domain/masterdata/sign-post.model';
import { LayerService } from 'src/app/maps/map/layer-selector/layer.service';
import { LayerSelectorItem } from 'src/app/maps/map/layer-selector/layer-selector-item/layer-selector-item.model';
import { MapStyleService } from 'src/app/maps/map/style/map-style.service';
import pixelWidth from 'string-pixel-width';

@Component({
  selector: 'sis-layer-selector',
  templateUrl: './layer-selector.component.html',
  styleUrls: ['./layer-selector.component.scss'],
})
export class LayerSelectorComponent extends Unsubscriber implements OnInit, AfterViewInit {
  private urlParams: Params;

  layerSelectors: LayerSelectorItem[] = [];
  activeSeason: Season;

  layerSelectorActiveClass: string;
  layerSelectorInactiveClass: string;
  menuButtonClass: string;
  fabButtonClass: string;
  signPostOptionClass: string;
  styleName: string;
  winterIconSvgUrl$: Promise<string>;
  summerIconSvgUrl$: Promise<string>;
  hasSignPosts: boolean;
  signPosts: SignPost[];
  signPostButtonWidth: string;

  readonly signPostImageUrl$ = from(this.mapStyleService.getSignPostUrl());

  @ViewChild('layerFab') layerFab: IonFab;
  @Input() openLayerSelector: boolean;
  @Input() season: Season;
  @Input() areaUrlName: string;
  @Input() sisId: string;
  @Input() oppositeMapExist: boolean;
  @Input() hideLayerSelector: boolean;
  @Input() hideSeasonFilter: boolean;
  @Input() hideSignPosts: boolean;

  constructor(
    private layerService: LayerService,
    private activatedRoute: ActivatedRoute,
    private navigatorService: NavigatorService,
    private mapStyleService: MapStyleService,
    private masterDataService: MasterDataService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.layerService.existingLayerSelectors$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      if (this.layerFab == null) {
        return;
      }
      const fabActivated = this.layerFab.activated;

      this.layerFab.activated = false;

      setTimeout(() => {
        this.layerFab.activated = fabActivated;
      });
    });
  }

  ngOnInit(): void {
    combineLatest([this.layerService.existingLayerSelectors$, this.layerService.hideQueryParams$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([existingLayerSelectors, hiddenLayers]) => {
        existingLayerSelectors.forEach((layerSelector) => {
          layerSelector.showLayers = !hiddenLayers.includes(layerSelector.category.name);
        });

        this.layerSelectors = existingLayerSelectors.sort((a, b) => a.order - b.order);
      });

    this.activatedRoute.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe((queryParams) => {
      this.urlParams = queryParams;
      const routeSeason = Number(queryParams[MapRoutes.Season]);
      if (this.season !== Season.SummerAndWinter && routeSeason !== undefined) {
        // Reset query argument
        this.layerService.selectSeason(undefined);
        this.setActivatedSeason(this.season);
      } else {
        if (routeSeason && !(routeSeason in Season)) {
          this.navigatorService.navigateToErrorPage();
          return;
        }

        if (!routeSeason) {
          this.selectSeason(Season.Summer);
        }
        this.setActivatedSeason(routeSeason);
      }
    });

    this.mapStyleService.getStyleName().then((name) => {
      this.layerSelectorActiveClass = `sis-layer-selector-active-${name}`;
      this.layerSelectorInactiveClass = `sis-layer-selector-inactive-${name}`;
      this.menuButtonClass = `sis-menu-button-${name}`;
      this.fabButtonClass = `sis-fab-button-${name}`;
      this.signPostOptionClass = `sis-sign-post-option-button-${name}`;
      this.styleName = name;
    });

    this.masterDataService.masterData.pipe(takeUntil(this.onDestroy$)).subscribe((masterData) => {
      this.signPosts = masterData.signPosts;
      this.hasSignPosts = masterData.signPosts?.length > 0;
      this.signPostButtonWidth =
        Math.max(...(masterData.signPosts?.map((s) => pixelWidth(s.name, { font: 'arial', size: 16 })) ?? [0])) +
        50 +
        'px';
    });
  }

  setActivatedSeason(seasonParam: Season): void {
    if (this.season === 0) {
      this.activeSeason = seasonParam;
      this.winterIconSvgUrl$ = this.mapStyleService.getSeasonIconSvgUrl('winter', this.activeSeason === Season.Winter);
      this.summerIconSvgUrl$ = this.mapStyleService.getSeasonIconSvgUrl('summer', this.activeSeason === Season.Summer);
    } else {
      this.activeSeason = this.season;
    }
  }

  stopPropagation($event: Event): void {
    $event.stopPropagation();
  }

  onClick(layerSelector: LayerSelectorItem): void {
    if (layerSelector.showLayers) {
      this.layerService.addFilters(layerSelector.category);
    } else {
      this.layerService.removeFilters(layerSelector.category);
    }
  }

  selectSeason(season: Season): void {
    if (this.season === 0) {
      this.layerService.selectSeason(season);
    } else {
      const urlParams = new HttpParams({ fromObject: this.urlParams }).toString();
      const url = this.sisId + '/sismap/' + this.areaUrlName + '/' + season + '?' + urlParams;
      this.changeUrl(url);
    }
  }

  signPostClicked(signPost: SignPost): void {
    const urlParams = new HttpParams({ fromObject: this.urlParams }).toString();
    const url = `${signPost.url}?${urlParams}`;
    this.changeUrl(url);
  }

  private changeUrl(url: string): void {
    window.location.href = url;
  }
}

import { CurvePathData } from '@elfalem/leaflet-curve';
import { Marker } from 'leaflet';
import { IconHelper } from 'src/app/core/leaflet-helper/icon-helper';
import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { CurveEndMarkers } from 'src/app/maps/map/style/curve-end-markers.model';
import { IconColor } from 'src/app/maps/map/style/icon-color.model';

export abstract class MapStyle {
  readonly name: string;

  constructor(protected iconHelper: IconHelper) {}

  abstract getIconSvg(iconName: string): Promise<string>;
  abstract getLayerIconSvgUrl(layerIconName: string, alternateStyle: boolean): string;
  abstract getSeasonIconSvgUrl(layerIconName: string, alternateStyle: boolean): string;
  abstract createCurveEndMarkers(curveCoords: CurvePathData, color: string, element: MapElement): CurveEndMarkers;
  abstract updateCurveEndMarkers(
    curveCoords: CurvePathData,
    curveEndMarkers: CurveEndMarkers,
    color: string,
    element: MapElement
  ): void;
  abstract zoomCurveEndMarkers(curveEndMarkers: CurveEndMarkers, scaling: number): void;
  abstract setHighlight(curveEndMarkers: CurveEndMarkers, highlight: boolean): void;
  abstract getLiftColor(assetStatus: AssetStatus, element: MapElement, overrideColor: boolean): IconColor;
  abstract getColor(status: AssetStatus): IconColor;
  abstract setIconColor(icon: Marker, backgroundColor: string, symbolColor?: string): void;
  abstract setSidepaneIconColor(iconSvg: string, backgroundColor: string, symbolColor?: string): string;
  abstract setIconBorder(icon: Marker): void;
  abstract createCurvePopupContent(
    element: MapElement,
    showLabel: boolean
  ): Promise<{ popupContent: string; className: string }>;
  abstract createIconPopupContent(
    element: MapElement,
    showLabel: boolean
  ): Promise<{ popupContent: string; className: string }>;
  abstract getSignPostSvgUrl(): string;
}

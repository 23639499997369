import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { Place } from 'src/app/maps/domain/masterdata/place.model';

export class PlaceAdapter {
  static adapt(item: any): Place {
    if (!item) {
      return Place.empty;
    }

    let positions: MapPosition[] = [];
    if (item.textPosition && item.textPosition !== '') {
      let iconPositions: MapPosition[] | MapPosition = JSON.parse(item.textPosition);
      if (!Array.isArray(iconPositions)) {
        iconPositions = [iconPositions];
      }
      positions = iconPositions;
    }

    return new Place({
      guid: item.guid,
      name: item.name,
      altitude: item.altitude,
      backgroundColor: item.color,
      textColor: item.fontColor,
      fontSize: item.fontSize,
      border: item.border,
      borderRadius: item.borderRadius,
      iconPositions: positions,
      textOutline: item.textOutline,
    });
  }

  static adaptForApi(place: Place): any {
    const color = place.backgroundColor || null;
    const fontColor = place.textColor === Place.defaultTextColor || !place.textColor ? null : place.textColor;
    const altitude = place.altitude || null;
    const fontSize = place.fontSize === Place.defaultFontSize || !place.fontSize ? null : place.fontSize;
    const border = place.border === Place.defaultBorder || !place.border ? null : place.border;
    const borderRadius =
      place.borderRadius === Place.defaultBorderRadius || !place.borderRadius ? null : place.borderRadius;
    const textOutline = place.textOutline || null;

    return {
      guid: place.guid,
      assetCategory: place.category,
      textPosition: JSON.stringify(place.iconPositions),
      color,
      fontColor,
      name: place.name,
      altitude,
      fontSize,
      border,
      borderRadius,
      textOutline,
    };
  }
}

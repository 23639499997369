import { OperatingInfo } from 'src/app/operating-info/domain/operating-info.model';
import { OperatingInfoEntryAdapter } from 'src/app/operating-info/domain/operating-info-entry.adapter';
import { OperatingInfoTenantAdapter } from 'src/app/operating-info/domain/operating-info-tenant.adapter';
import { OperatingInfoLinkAdapter } from 'src/app/operating-info/domain/operting-info-link.adapter';

export class OperatingInfoAdapter {
  static adapt(item: any): OperatingInfo {
    if (!item) {
      return null;
    }

    return {
      title: item.title,
      logo: item.logo,
      tenants: OperatingInfoTenantAdapter.adapt(item.tenants),
      entries: OperatingInfoEntryAdapter.adapt(item.entries),
      links: OperatingInfoLinkAdapter.adapt(item.links),
    };
  }
}

<input
  type="text"
  [ngModel]="filterValue"
  (ngModelChange)="updateSelection($event)"
  [matAutocomplete]="auto"
  autocomplete="off"
  [placeholder]="placeholder"
  [disabled]="disabled"
  (focus)="selectText($event)"
/>

<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="formatInputValue.bind(this)"
  (optionSelected)="emitSelectedOption($event)"
>
  <mat-option *ngFor="let item of filteredCandidates" [value]="item.value">{{ item.formatted }}</mat-option>
</mat-autocomplete>

<ion-header>
  <ion-toolbar color="default">
    <img
      *ngIf="this.bigScreenMode$ | async"
      src="assets\sis-logo\siscontrol-gray-optimized.svg"
      class="sis-sc-logo"
      routerLink="/home"
    />
    <img
      *ngIf="(this.bigScreenMode$ | async) === false"
      src="assets\sis-logo\sis-a.col-tr.200x200.png"
      class="sis-sc-logo"
      routerLink="/home"
    />
    <ion-buttons slot="end">
      <ion-button href="mailto:siscontrol@sisag.ch?Subject=SisControl%20Anfrage" class="sis-header-button" translate
        >general.term.contact
      </ion-button>
      <ion-button (click)="redirectToCockpit()" class="sis-header-button" translate>general.term.login</ion-button>
      <ion-button (click)="openConditionsPage()" class="sis-header-button" translate
        >general.term.conditions</ion-button
      >
      <ion-button (click)="openDataProtectionPage()" class="sis-header-button" translate
        >general.term.dataProtection</ion-button
      >
      <sis-language-selector slot="end" class="sis-language-selector"></sis-language-selector>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="sis-content">
  <ion-grid id="main-grid">
    <ion-col>
      <ion-card-title class="sis-news-title">
        <img src="assets/sis-logo/siscontrol-color.svg" class="sis-news-image" />
        <a style="color: inherit" translate>home.term.news</a>
      </ion-card-title>
    </ion-col>
    <ion-row>
      <ion-col>
        <ion-card style="height: 700px">
          <ion-card-header color="default">
            <ion-card-title translate>home.remoteAccessTitle</ion-card-title>
            <ion-card-subtitle translate>home.remoteAccessSubtitle</ion-card-subtitle>
          </ion-card-header>
          <ion-img src="assets\images\remote-access.png"></ion-img>
          <ion-card-content>
            <ion-list lines="none">
              <ion-item>
                <ion-icon slot="start" name="eye"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h2 translate>home.remoteAccessText1Title</h2>
                  <p translate>home.remoteAccessText1</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-icon slot="start" name="key-outline"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h2 translate>home.remoteAccessText2Title</h2>
                  <p translate>home.remoteAccessText2</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col>
        <ion-card style="height: 700px">
          <ion-card-header color="default">
            <ion-card-title translate>home.sisTiqTitle</ion-card-title>
            <ion-card-subtitle translate>home.sisTiqSubtitle</ion-card-subtitle>
          </ion-card-header>
          <ion-img src="assets\images\sistiq.png"></ion-img>
          <ion-card-content>
            <ion-list lines="none">
              <ion-item>
                <ion-icon slot="start" name="qr-code"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h2 translate>home.sisTiqText1Title</h2>
                  <p translate>home.sisTiqText1</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-icon slot="start" name="wallet-outline"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h2 translate>home.sisTiqText2Title</h2>
                  <p translate>home.sisTiqText2</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-icon slot="start" name="time"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h2 translate>home.sisTiqText3Title</h2>
                  <p translate>home.sisTiqText3</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-card>
          <ion-card-header color="default">
            <ion-card-title translate>home.windTitle</ion-card-title>
            <ion-card-subtitle translate>home.windSubtitle</ion-card-subtitle>
          </ion-card-header>
          <ion-img src="assets\images\public-wind.png"></ion-img>
          <ion-card-content>
            <ion-list lines="none">
              <ion-item>
                <ion-icon slot="start" name="map"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h2 translate>home.windText1Title</h2>
                  <p translate>home.windText1</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col class="sis-placeholder"> </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-card id="main-card">
          <ion-card-header>
            <ion-card-title translate>home.title</ion-card-title>
            <ion-card-subtitle translate>home.mainSubtitle</ion-card-subtitle>
            <img src="assets\images\public-devices.png" class="devices-img" />
          </ion-card-header>
          <ion-card-content>
            <ion-grid>
              <ion-card-subtitle class="ion-text-wrap" translate>home.mainTitle</ion-card-subtitle>
              <ion-row>
                <ion-col>
                  <ion-item lines="none">
                    <ion-icon slot="start" name="information-circle-outline" class="main-card-icon"></ion-icon>
                    <ion-label class="ion-text-wrap">
                      <h2 translate>home.information</h2>
                      <p translate>home.informationText</p>
                    </ion-label>
                  </ion-item>
                </ion-col>
                <ion-col>
                  <ion-item lines="none">
                    <ion-icon slot="start" name="search" class="main-card-icon"></ion-icon>
                    <ion-label class="ion-text-wrap">
                      <h2 translate>home.analyseTitle</h2>
                      <p translate>home.analyseText</p>
                    </ion-label>
                  </ion-item>
                </ion-col>
                <ion-col>
                  <ion-item lines="none">
                    <ion-icon slot="start" name="log-in" class="main-card-icon"></ion-icon>
                    <ion-label class="ion-text-wrap">
                      <h2 translate>home.interveneTitle</h2>
                      <p translate>home.interveneText</p>
                    </ion-label>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-card>
          <ion-card-header color="default">
            <ion-card-title translate>home.overviewTitle</ion-card-title>
            <ion-card-subtitle translate>home.overviewSubtitle</ion-card-subtitle>
          </ion-card-header>
          <ion-img src="assets\images\public-map.png"></ion-img>
          <ion-card-content>
            <ion-list lines="none">
              <ion-item>
                <ion-icon slot="start" name="analytics"></ion-icon>
                <ion-label class="ion-text-wrap" translate>
                  <h2 translate>home.ovText1Title</h2>
                  <p translate>home.ovText1</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-icon slot="start" name="cog"></ion-icon>
                <ion-label class="ion-text-wrap" translate>
                  <h2 translate>home.ovText2Title</h2>
                  <p translate>home.ovText2</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-icon slot="start" name="partly-sunny"></ion-icon>
                <ion-label class="ion-text-wrap" translate>
                  <h2 translate>home.ovText3Title</h2>
                  <p translate>home.ovText3</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col>
        <ion-card>
          <ion-card-header color="default">
            <ion-card-title translate>home.ropewayTitle</ion-card-title>
            <ion-card-subtitle translate>home.ropewaySubtitle</ion-card-subtitle>
          </ion-card-header>
          <ion-img src="assets\images\public-widgets.png"></ion-img>
          <ion-card-content>
            <ion-list lines="none">
              <ion-item>
                <ion-icon slot="start" name="speedometer"></ion-icon>
                <ion-label class="ion-text-wrap" translate>
                  <h2 translate>home.roText1Title</h2>
                  <p translate>home.roText1</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-icon slot="start" name="trending-up"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h2 translate>home.roText2Title</h2>
                  <p translate>home.roText2</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-card>
          <ion-card-header color="default">
            <ion-card-title translate>home.alertTitle</ion-card-title>
            <ion-card-subtitle translate>home.alertSubtitle</ion-card-subtitle>
          </ion-card-header>
          <ion-img src="assets\images\public-alerts.png"></ion-img>
          <ion-card-content>
            <ion-list lines="none">
              <ion-item>
                <ion-icon slot="start" name="warning"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h2 translate>home.notificationTitle</h2>
                  <p translate>home.notificationText</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col>
        <ion-card>
          <ion-card-header color="default">
            <ion-card-title translate>home.giTitle</ion-card-title>
            <ion-card-subtitle translate>home.giSubtitle</ion-card-subtitle>
          </ion-card-header>
          <ion-img src="assets\images\public-guestinfo.png"></ion-img>
          <ion-card-content>
            <ion-list lines="none">
              <ion-item>
                <ion-icon slot="start" name="information-circle"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h2 translate>home.giText1Title</h2>
                  <p translate>home.giText1</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-card>
          <ion-card-header color="default">
            <ion-card-title translate>home.itTitle</ion-card-title>
            <ion-card-subtitle translate>home.itSubtitle</ion-card-subtitle>
          </ion-card-header>
          <ion-img src="assets\images\public-it.png"></ion-img>
          <ion-card-content>
            <ion-list lines="none">
              <ion-item>
                <ion-icon slot="start" name="list"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h2 translate>home.itText1Title</h2>
                  <p translate>home.itText1</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col>
        <ion-card>
          <ion-card-header color="default">
            <ion-card-title translate>home.breakdownInfoTitle</ion-card-title>
            <ion-card-subtitle translate>home.breakdownInfoSubtitle</ion-card-subtitle>
          </ion-card-header>
          <ion-img src="assets\images\qr-picture.png"></ion-img>
          <ion-card-content>
            <ion-list lines="none">
              <ion-item>
                <ion-icon slot="start" name="information-circle"></ion-icon>
                <ion-label class="ion-text-wrap" translate>
                  <h2 translate>home.breakdownText1Title</h2>
                  <p translate class="p">home.breakdownText1</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-card>
          <ion-card-header color="default">
            <ion-card-title translate>home.cockpitTitle</ion-card-title>
            <ion-card-subtitle translate>home.cockpitSubtitle</ion-card-subtitle>
          </ion-card-header>
          <ion-img src="assets\images\public-cockpit.png"></ion-img>
          <ion-card-content>
            <ion-list lines="none">
              <ion-item>
                <ion-icon slot="start" name="contract"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h2 translate>home.cockpitText1Title</h2>
                  <p translate>home.cockpitText1</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-icon slot="start" name="videocam"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h2 translate>home.cockpitText2Title</h2>
                  <p translate>home.cockpitText2</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-icon slot="start" name="eye"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h2 translate>home.cockpitText3Title</h2>
                  <p translate>home.cockpitText3</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col>
        <ion-card>
          <ion-card-header color="default">
            <ion-card-title translate>home.videoTitle</ion-card-title>
            <ion-card-subtitle translate>home.videoSubtitle</ion-card-subtitle>
          </ion-card-header>
          <ion-img src="assets\images\public-video.png"></ion-img>
          <ion-card-content>
            <ion-list lines="none">
              <ion-item>
                <ion-icon slot="start" name="images"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h2 translate>home.videoText1Title</h2>
                  <p translate>home.videoText1</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-icon slot="start" name="tv"></ion-icon>
                <ion-label class="ion-text-wrap">
                  <h2 translate>home.videoText2Title</h2>
                  <p translate>home.videoText2</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-card style="width: 100%">
        <ion-card-header color="default">
          <ion-card-title translate>home.serviceTitle</ion-card-title>
        </ion-card-header>
        <ion-img src="assets\images\public-services.png" class="offer-img"></ion-img>
      </ion-card>
    </ion-row>
  </ion-grid>
</ion-content>

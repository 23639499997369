import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NavigatorService {
  constructor(private router: Router) {}

  get baseUrl(): string {
    let baseUrl = window.location.origin;

    if (baseUrl.includes('://localhost')) {
      baseUrl = 'http://localhost:5005';
    }

    return baseUrl;
  }

  redirectToCockpit(): void {
    window.location.href = environment.cockpitUrl;
  }

  navigateToErrorPage(): void {
    this.router.navigate(['error']);
  }
}

<ion-content *ngIf="this.breakdownInfo" scroll-y="false">
  <ion-grid>
    <ion-row class="sis-header">
      <ion-col>
        <ion-img [src]="this.logoUrl" class="sis-tenant-logo"></ion-img>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="ion-text-center">
        <div>
          <h3><span translate>breakdownInfo.cablewayServices</span></h3>
        </div>
      </ion-col>
    </ion-row>

    <ion-row class="sis-breakdowninfo-name">
      <ion-col>
        <ion-img [src]="this.breakdownInfoIconUrl" class="sis-breakdowninfo-icon"></ion-img><span>{{ this.breakdownInfo.name }}</span>
      </ion-col>
    </ion-row>

    <ion-row class="sis-title" [ngStyle]="{ 'background-color': this.breakdownInfo.color }">
      <ion-col>
        {{ this.title }}
      </ion-col>
    </ion-row>

    <ion-row class="sis-text" [ngStyle]="{ 'background-color': this.breakdownInfo.color + '66' }">
      <ion-col>
        {{ this.text }}
      </ion-col>
    </ion-row>

    <ion-row style="flex-grow: 1;"></ion-row>

    <ion-row class="sis-footer">
      <ion-col>
        <span translate>breakdownInfo.information</span><br />
        <a href="{{ this.breakdownInfo.homepageUrl }}" target="_blank">{{ this.homepage }}</a><br />
        <a href="tel:{{ this.breakdownInfo.telNumber }}">Tel. {{ this.breakdownInfo.telNumber }}</a>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<sis-map
  class="sis-map"
  *ngIf="masterData"
  [masterData]="masterData"
  [hoverDisabled]="true"
  [showStatus]="false"
  [editMode]="editMode"
  [enableAssetSidepane]="false"
  [allowElementSelection]="false"
></sis-map>

import { Season } from 'src/app/core/domain/season.enum';
import { WritablePart } from 'src/app/core/utils/type-helper';
import { SisMediaAssetCategory } from 'src/app/webreport/domain/sismedia-asset-category.enum';
import { SisMediaAssetStatus } from 'src/app/webreport/domain/sismedia-asset-status.enum';
import { SisMediaItemIconOptions } from 'src/app/webreport/domain/sismedia-item-icon-options.model';

export class SisMediaItem {
  static readonly iconOptions = new Map<SisMediaAssetStatus, SisMediaItemIconOptions>([
    [
      SisMediaAssetStatus.Disabled,
      {
        iconName: 'sis-closed-inactive',
        colorClassName: 'sis-black',
        tooltipTranslationString: 'webreport.term.inactive',
      },
    ],
    [
      SisMediaAssetStatus.Closed,
      {
        iconName: 'sis-closed',
        colorClassName: 'sis-red',
        tooltipTranslationString: 'webreport.term.closed',
      },
    ],
    [
      SisMediaAssetStatus.Preparation,
      {
        iconName: 'sis-preparation',
        colorClassName: 'sis-yellow',
        tooltipTranslationString: 'webreport.term.preparation',
      },
    ],
    [
      SisMediaAssetStatus.Open,
      {
        iconName: 'sis-open',
        colorClassName: 'sis-green',
        tooltipTranslationString: 'webreport.term.open',
      },
    ],
    [
      SisMediaAssetStatus.Full,
      {
        iconName: 'sis-full',
        colorClassName: 'sis-red',
        tooltipTranslationString: 'webreport.term.full',
      },
    ],
    [
      SisMediaAssetStatus.Free,
      {
        iconName: 'sis-open',
        colorClassName: 'sis-green',
        tooltipTranslationString: 'webreport.term.free',
      },
    ],
  ]);

  guid: string;
  name: string;
  type: string;
  typeDescription: string;
  status: SisMediaAssetStatus;
  order: number;
  assetCategory: SisMediaAssetCategory;
  tenantAlias: string;
  season: Season;
  options: SisMediaItemIconOptions;
  label: string;
  lengthMeter: number;

  constructor(init?: Partial<WritablePart<SisMediaItem>>) {
    Object.assign(this, init);

    this.options = SisMediaItem.iconOptions.get(init?.status) ?? { colorClassName: 'sis-none' };
  }
}

import { PointExpression } from 'leaflet';
import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { Slope } from 'src/app/maps/domain/masterdata/slope.model';

export class SlopeAdapter {
  static adapt(item: any): Slope {
    if (!item) {
      return Slope.empty;
    }

    const defaultColor = item.defaultColor ? item.defaultColor : Slope.defaultColor;
    const weight = item.weight ? Number(item.weight) : null;
    const defaultIconSize: PointExpression = item.defaultIconSize ? item.defaultIconSize : Slope.defaultIconSize;
    const isPiste = item.type?.toLowerCase().includes('piste');
    const iconSize = isPiste ? Slope.defaultStatusIconSize : defaultIconSize;

    let positions: MapPosition[] = [];
    if (item.iconPosition && item.iconPosition !== '') {
      let iconPositions: MapPosition[] | MapPosition = JSON.parse(item.iconPosition);
      if (!Array.isArray(iconPositions)) {
        iconPositions = [iconPositions];
      }
      positions = iconPositions;
    }

    return new Slope({
      guid: item.guid,
      name: item.name,
      nameEn: item.nameEn,
      label: item.label,
      info: item.info,
      website: item.website,
      description: item.description,
      lengthMeter: Number(item.lengthMeter),
      type: item.type,
      season: item.season,
      path: item.path,
      pathDashArray: item.dashArray,
      pathWeight: weight,
      pathColor: item.color,
      defaultPathColor: defaultColor,
      defaultPathWeight: Slope.defaultWeight,
      status: item.status != null ? item.status : AssetStatus.NoStatus,
      iconImageName: item.type,
      iconUseStatus: isPiste,
      iconWidth: iconSize[0],
      iconHeight: iconSize[1],
      iconPositions: positions,
    });
  }

  static adaptForApi(slope: Slope): any {
    const path = slope.path || null;
    const color = slope.pathColor === Slope.defaultColor ? null : slope.pathColor || null;
    const weight = slope.pathWeight === Slope.defaultWeight ? null : slope.pathWeight || null;
    const dashArray = slope.pathDashArray || null;

    return {
      guid: slope.guid,
      assetCategory: slope.category,
      path,
      iconPosition: JSON.stringify(slope.iconPositions),
      color,
      dashArray,
      weight,
    };
  }
}

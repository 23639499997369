import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';

@Injectable({
  providedIn: 'root',
})
export class MapStateService {
  // element is null when elements get deselected
  // iconIndex may be null if no specific icon is selected (for example by clicking on a path)
  private readonly _selectedElement$ = new BehaviorSubject<{
    element: MapElement | null;
    iconIndex: number | null;
    showFindMarkerAnimation?: boolean;
  }>({
    element: null,
    iconIndex: null,
  });
  private readonly _elementUpdated$ = new Subject<MapElement>();
  private readonly _elementMoved$ = new Subject<{
    element: MapElement;
    iconIndex: number | null;
    newPosition: MapPosition;
  }>();
  private readonly _elementClicked$ = new Subject<{ element: MapElement; iconIndex: number | null }>();
  private readonly _centeredElement$ = new Subject<{ element: MapElement; iconIndex: number }>();
  private readonly _highlightedElement$ = new Subject<{ element: MapElement; iconIndex: number | null }>();

  readonly selectedElement$ = this._selectedElement$.asObservable();
  readonly elementUpdated$ = this._elementUpdated$.asObservable();
  readonly elementMoved$ = this._elementMoved$.asObservable();
  readonly elementClicked$ = this._elementClicked$.asObservable();
  readonly centerElement$ = this._centeredElement$.asObservable();
  readonly highlightedElement = this._highlightedElement$.asObservable();

  selectElement(element: MapElement | null, iconIndex: number | null, showFindMarkerAnimation?: boolean): void {
    this._selectedElement$.next({ element, iconIndex, showFindMarkerAnimation });
  }

  updateMapElement(element: MapElement): void {
    this._elementUpdated$.next(element);
  }

  moveMapElement(element: MapElement, iconIndex: number, newPosition: MapPosition): void {
    this._elementMoved$.next({ element, iconIndex, newPosition });
  }

  clickElement(element: MapElement, iconIndex: number): void {
    this._elementClicked$.next({ element, iconIndex });
  }

  centerElementIcon(element: MapElement, iconIndex: number): void {
    this._centeredElement$.next({ element, iconIndex });
  }

  highlightElement(element: MapElement, iconIndex: number | null): void {
    this._highlightedElement$.next({ element, iconIndex });
  }
}

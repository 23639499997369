import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  catchError,
  combineLatest,
  firstValueFrom,
  map,
  merge,
  Observable,
  of,
  scan,
  shareReplay,
  startWith,
  Subject,
  tap,
  withLatestFrom,
} from 'rxjs';
import { AssetEditorPostAsset } from 'src/app/maps/domain/asset-editor/asset-editor-post-asset.model';
import { AssetEditorPostBody } from 'src/app/maps/domain/asset-editor/asset-editor-post-body.model';
import { AssetEditorPostInitializeAssetStatus } from 'src/app/maps/domain/asset-editor/asset-editor-post-initialize-asset-status.model';
import { CustomIconAdapter } from 'src/app/maps/domain/masterdata/custom-icon.adapter';
import { CustomPathAdapter } from 'src/app/maps/domain/masterdata/custom-path.adapter';
import { GastroAdapter } from 'src/app/maps/domain/masterdata/gastro.adapter';
import { LiftAdapter } from 'src/app/maps/domain/masterdata/lift.adapter';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { MasterDataService } from 'src/app/maps/domain/masterdata/masterdata.service';
import { PlaceAdapter } from 'src/app/maps/domain/masterdata/place.adapter';
import { POIAdapter } from 'src/app/maps/domain/masterdata/poi.adapter';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';
import { SlopeAdapter } from 'src/app/maps/domain/masterdata/slope.adapter';
import { TrailAdapter } from 'src/app/maps/domain/masterdata/trail.adapter';
import { WebcamAdapter } from 'src/app/maps/domain/masterdata/webcam.adapter';
import { Webcam } from 'src/app/maps/domain/masterdata/webcam.model';
import { WindAdapter } from 'src/app/maps/domain/masterdata/wind.adapter';
import { MapStateService } from 'src/app/maps/map/map-state.service';
import { AssetEditorConfig } from 'src/app/maps/map/sidepane/asset-editor-tab/asset-editor-config.model';
import { environment } from 'src/environments/environment';
import { v4 as v4guid } from 'uuid';

interface RemoveMapItem {
  element: MapElement;
  wasChanged: boolean;
  hadErrors: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AssetEditorService {
  static readonly path = 'path';

  readonly configs = new Map<SisMapAssetCategory, AssetEditorConfig>([
    [
      SisMapAssetCategory.Lift,
      {
        editableProperties: [
          { key: AssetEditorService.path },
          { key: 'pathColor', isNullable: true },
          { key: 'pathBackgroundColor', isNullable: true },
          { key: 'pathDashArray', isNullable: true },
          { key: 'pathWeight', isNumber: true, isNullable: true },
        ],
        adaptForApi: LiftAdapter.adaptForApi,
      },
    ],
    [
      SisMapAssetCategory.Slope,
      {
        editableProperties: [
          { key: AssetEditorService.path },
          { key: 'pathColor', isNullable: true },
          { key: 'pathDashArray', isNullable: true },
          { key: 'pathWeight', isNumber: true, isNullable: true },
        ],
        adaptForApi: SlopeAdapter.adaptForApi,
      },
    ],
    [
      SisMapAssetCategory.Trail,
      {
        editableProperties: [
          { key: AssetEditorService.path },
          { key: 'pathColor', isNullable: true },
          { key: 'pathDashArray', isNullable: true },
          { key: 'pathWeight', isNumber: true, isNullable: true },
        ],
        adaptForApi: TrailAdapter.adaptForApi,
      },
    ],
    [
      SisMapAssetCategory.Gastro,
      {
        maxIconCount: 1,
        editableProperties: [],
        adaptForApi: GastroAdapter.adaptForApi,
      },
    ],
    [
      SisMapAssetCategory.Poi,
      {
        editableProperties: [
          { key: AssetEditorService.path },
          { key: 'pathColor', isNullable: true },
          { key: 'pathDashArray', isNullable: true },
          { key: 'pathWeight', isNumber: true, isNullable: true },
        ],
        adaptForApi: POIAdapter.adaptForApi,
      },
    ],
    [
      SisMapAssetCategory.Place,
      {
        maxIconCount: 1,
        editableProperties: [
          { key: 'name' },
          { key: 'altitude', isNullable: true },
          { key: 'textColor', isNullable: true },
          { key: 'backgroundColor', isNullable: true },
          { key: 'fontSize', isNumber: true, isNullable: true },
          { key: 'border', isNullable: true },
          { key: 'borderRadius', isNumber: true, isNullable: true },
          { key: 'textOutline', isNullable: true },
        ],
        adaptForApi: PlaceAdapter.adaptForApi,
        canBeDuplicated: true,
      },
    ],
    [
      SisMapAssetCategory.CustomIcon,
      {
        maxIconCount: 1,
        editableProperties: [
          { key: 'iconSvgBlobName' },
          { key: 'name', isNullable: true },
          { key: 'url', isNullable: true },
          { key: 'iconHeight', isNumber: true },
          { key: 'iconWidth', isNumber: true },
        ],
        adaptForApi: CustomIconAdapter.adaptForApi,
      },
    ],
    [
      SisMapAssetCategory.CustomPath,
      {
        maxIconCount: 0,
        editableProperties: [
          { key: 'path' },
          { key: 'name' },
          { key: 'type', selectableValues: ['wandern', 'bike', 'schneeschuh'] },
          { key: 'pathColor', isNullable: true },
          { key: 'pathDashArray', isNullable: true },
          { key: 'pathWeight', isNumber: true, isNullable: true },
        ],
        adaptForApi: CustomPathAdapter.adaptForApi,
      },
    ],
    [
      SisMapAssetCategory.Webcam,
      {
        maxIconCount: 1,
        editableProperties: [
          { key: 'name' },
          { key: 'url' },
          { key: 'type', selectableValues: [Webcam.page, Webcam.iframe, Webcam.external] },
          {
            key: 'aspectRatio',
            selectableValues: [Webcam.ratio_16_9, Webcam.ratio_4_3, Webcam.ratio_3_2, Webcam.ratio_other],
          },
        ],
        adaptForApi: WebcamAdapter.adaptForApi,
      },
    ],
    [
      SisMapAssetCategory.Wind,
      {
        maxIconCount: 1,
        editableProperties: [{ key: 'name' }],
        adaptForApi: WindAdapter.adaptForApi,
      },
    ],
  ]);

  private readonly assetUpdatePostUrl = '/api/sismap/assetupdate/';
  private readonly assetInitStatusUrl = '/api/sismap/initassetstatus';

  private readonly addUnsavedAsset$ = new Subject<MapElement>();
  private readonly removeUnsavedAsset$ = new Subject<MapElement>();
  private readonly clearUnsavedAssets$ = new Subject<void>();

  private readonly addAssetWithError$ = new Subject<MapElement>();
  private readonly removeAssetWithError$ = new Subject<MapElement>();
  private readonly clearAssetsWithError$ = new Subject<void>();

  private readonly removeAsset$ = new Subject<MapElement>();
  private readonly restoreAsset$ = new Subject<MapElement>();
  private readonly clearRemovedAssets$ = new Subject<void>();

  private readonly addAsset$ = new Subject<MapElement>();

  readonly unsavedAssets$: Observable<Map<string, MapElement>> = merge(
    this.addUnsavedAsset$.pipe(
      map(
        (asset) =>
          (assetMap: Map<string, MapElement>): Map<string, MapElement> =>
            assetMap.set(asset.guid, asset)
      )
    ),
    this.removeUnsavedAsset$.pipe(
      map((asset) => (assetMap: Map<string, MapElement>): Map<string, MapElement> => {
        assetMap.delete(asset.guid);
        return assetMap;
      })
    ),
    this.clearUnsavedAssets$.pipe(map(() => (): Map<string, MapElement> => new Map<string, MapElement>()))
  ).pipe(
    scan((assetMap, fn) => fn(assetMap), new Map<string, MapElement>()),
    startWith(new Map<string, MapElement>()),
    shareReplay(1)
  );

  readonly assetsWithError$: Observable<Map<string, MapElement>> = merge(
    this.addAssetWithError$.pipe(
      map(
        (asset) =>
          (assetMap: Map<string, MapElement>): Map<string, MapElement> =>
            assetMap.set(asset.guid, asset)
      )
    ),
    this.removeAssetWithError$.pipe(
      map((asset) => (assetMap: Map<string, MapElement>): Map<string, MapElement> => {
        assetMap.delete(asset.guid);
        return assetMap;
      })
    ),
    this.clearAssetsWithError$.pipe(map(() => (): Map<string, MapElement> => new Map<string, MapElement>()))
  ).pipe(
    scan((assetMap, fn) => fn(assetMap), new Map<string, MapElement>()),
    startWith(new Map<string, MapElement>()),
    shareReplay(1)
  );

  readonly removedAssets$: Observable<Map<string, RemoveMapItem>> = merge(
    this.removeAsset$.pipe(
      withLatestFrom(this.unsavedAssets$, this.assetsWithError$),
      map(
        ([asset, unsavedAssets, errorAssets]) =>
          (assetMap: Map<string, RemoveMapItem>): Map<string, RemoveMapItem> => {
            const wasChanged = unsavedAssets.has(asset.guid);
            const hadErrors = errorAssets.has(asset.guid);
            this.removeUnsavedAsset(asset);
            this.removeAssetWithError(asset);
            if (!asset.newlyAdded) {
              return assetMap.set(asset.guid, {
                element: asset,
                wasChanged,
                hadErrors,
              });
            } else {
              this.editedAssetOriginals.delete(asset.guid);
              return assetMap;
            }
          }
      )
    ),
    this.restoreAsset$.pipe(
      map((asset) => (assetMap: Map<string, RemoveMapItem>): Map<string, RemoveMapItem> => {
        const deletedAsset = assetMap.get(asset.guid);
        assetMap.delete(asset.guid);
        if (deletedAsset.wasChanged) {
          this.setUnsavedAsset(asset);
        }
        if (deletedAsset.hadErrors) {
          this.setAssetWithError(asset);
        }
        return assetMap;
      })
    ),
    this.clearRemovedAssets$.pipe(map(() => (): Map<string, RemoveMapItem> => new Map<string, RemoveMapItem>()))
  ).pipe(
    scan((assetMap, fn) => fn(assetMap), new Map<string, RemoveMapItem>()),
    startWith(new Map<string, RemoveMapItem>()),
    shareReplay(1)
  );

  readonly hasChanges$: Observable<boolean> = combineLatest([this.unsavedAssets$, this.removedAssets$]).pipe(
    map(([unsavedAssets, removedAssets]) => unsavedAssets.size > 0 || removedAssets.size > 0),
    startWith(false),
    shareReplay(1)
  );

  readonly addedAsset$: Observable<MapElement> = this.addAsset$.asObservable();

  editedAssetOriginals = new Map<string, MapElement>();

  constructor(
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    private masterDataService: MasterDataService,
    private mapStateService: MapStateService
  ) {}

  async saveAll(): Promise<boolean> {
    const queryParams = await firstValueFrom(this.activatedRoute.queryParams);
    const editToken = queryParams['edit'];

    const assetToSaveMap = await firstValueFrom(this.unsavedAssets$);
    const assetsToRemoveMap = await firstValueFrom(this.removedAssets$);
    const assetsToSave = [...assetToSaveMap.values()];
    const assetsToRemove = [...assetsToRemoveMap.values()].filter(
      (e) => !e.element.newlyAdded && !assetToSaveMap.has(e.element.guid)
    );

    const mapGuid = await firstValueFrom(
      this.masterDataService.masterData.pipe(map((masterData) => masterData?.mapGuid))
    );

    const updatedAssets: AssetEditorPostAsset[] = assetsToSave.map<AssetEditorPostAsset>((asset) => {
      const config = this.configs.get(asset.category);

      return config.adaptForApi(asset);
    });

    const removedAssets: AssetEditorPostAsset[] = assetsToRemove.map<AssetEditorPostAsset>((asset) => {
      const config = this.configs.get(asset.element.category);

      return config.adaptForApi(asset.element);
    });

    const postData: AssetEditorPostBody = {
      mapGuid,
      editToken,
      removedAssets,
      updatedAssets,
    };

    const postRequest$ = this.httpClient.post(`${environment.baseUrlApi}${this.assetUpdatePostUrl}`, postData).pipe(
      map(() => true),
      catchError(() => of(false)),
      tap((success) => {
        if (success) {
          assetsToSave.forEach((asset) => (asset.newlyAdded = false));
          this.editedAssetOriginals.clear();
          this.clearRemovedAssets();
          this.clearUnsavedAssets();
        }
      })
    );

    return firstValueFrom(postRequest$);
  }

  async initializeAssetStatus(): Promise<void> {
    const queryParams = await firstValueFrom(this.activatedRoute.queryParams);
    const editToken = queryParams['edit'];
    const mapGuid = await firstValueFrom(
      this.masterDataService.masterData.pipe(map((masterData) => masterData?.mapGuid))
    );

    const data: AssetEditorPostInitializeAssetStatus = {
      mapGuid,
      editToken,
    };
    await firstValueFrom(this.httpClient.post(`${environment.baseUrlApi}${this.assetInitStatusUrl}`, data));
  }

  async addAsset(element: MapElement, position?: MapPosition): Promise<void> {
    element.guid ??= v4guid();
    element.name ??= '';
    element.newlyAdded = true;

    if (position) {
      position.x = Math.round(position.x);
      position.y = Math.round(position.y);
      element.iconPositions = [position];
    } else {
      element.iconPositions = [];
    }

    this.addUnsavedAsset$.next(element);

    await this.masterDataService.addMapElement(element);

    this.mapStateService.selectElement(element, 0);

    this.addAsset$.next(element);
  }

  async removeAsset(element: MapElement): Promise<void> {
    this.removeAsset$.next(element);
    this.removeAssetWithError(element);
    await this.masterDataService.removeMapElement(element);
  }

  async restoreAsset(element: MapElement): Promise<void> {
    this.restoreAsset$.next(element);
    await this.masterDataService.addMapElement(element);
    this.mapStateService.selectElement(element, 0);
  }

  clearRemovedAssets(): void {
    this.clearRemovedAssets$.next();
  }

  setUnsavedAsset(asset: MapElement): void {
    this.addUnsavedAsset$.next(asset);
  }

  removeUnsavedAsset(asset: MapElement): void {
    this.removeUnsavedAsset$.next(asset);
  }

  clearUnsavedAssets(): void {
    this.clearUnsavedAssets$.next();
  }

  setAssetWithError(asset: MapElement): void {
    this.addAssetWithError$.next(asset);
  }

  removeAssetWithError(asset: MapElement): void {
    this.removeAssetWithError$.next(asset);
  }

  clearAssetsWithError(): void {
    this.clearAssetsWithError$.next();
  }
}

export enum SisMapAssetCategory {
  Lift = 1,
  Slope = 2,
  Trail = 3,
  Gastro = 4,
  Poi = 5,
  Place = 6,
  CustomIcon = 100,
  Webcam = 101,
  Wind = 102,
  CustomPath = 103,
}

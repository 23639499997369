import '@elfalem/leaflet-curve';

import { Component, Input, OnInit } from '@angular/core';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';

@Component({
  selector: 'sis-map-element-container',
  templateUrl: './map-element-container.component.html',
})
export class MapElementContainerComponent implements OnInit {
  @Input() layerGroup: L.LayerGroup;
  @Input() element: MapElement;
  @Input() showStatus: boolean;
  @Input() showStatusIcons = true;
  @Input() editMode: boolean;

  curveTestClass = 'sis-NONE-curve';
  iconTestClass = 'sis-NONE-icon';

  ngOnInit(): void {
    this.curveTestClass = `sis-${SisMapAssetCategory[this.element.category].toLowerCase()}-curve`;
    this.iconTestClass = `sis-${SisMapAssetCategory[this.element.category].toLowerCase()}-icon`;
  }
}

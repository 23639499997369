import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { Season } from 'src/app/core/domain/season.enum';
import { ComponentCanDeactivate } from 'src/app/core/guards/pending-changes.guard';
import { NavigatorService } from 'src/app/core/navigator/navigator.service';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { Status } from 'src/app/maps/domain/livedata/status.model';
import { StatusService } from 'src/app/maps/domain/livedata/status.service';
import { MapRoutes } from 'src/app/maps/domain/map-routes';
import { LayerName } from 'src/app/maps/domain/masterdata/layername.enum';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MasterData } from 'src/app/maps/domain/masterdata/masterdata.model';
import { MasterDataService } from 'src/app/maps/domain/masterdata/masterdata.service';
import { MapStateService } from 'src/app/maps/map/map-state.service';
import { AssetEditorService } from 'src/app/maps/map/sidepane/asset-editor-tab/asset-editor.service';

@Component({
  selector: 'sis-maps',
  templateUrl: './sismap.page.html',
  styleUrls: ['./sismap.page.scss'],
})
export class SisMapPage extends Unsubscriber implements OnInit, ComponentCanDeactivate {
  private editorHasChanges = false;

  masterData: MasterData;
  backgroundColor = 'white';
  editMode = false;

  hideLayerSelector = false;
  hideSeasonFilter = false;
  hideSignPosts = false;
  showStatusIcons = false;
  openLayerSelector = false;

  constructor(
    private masterDataService: MasterDataService,
    private activatedRoute: ActivatedRoute,
    private navigator: NavigatorService,
    private appService: AppService,
    private statusService: StatusService,
    private mapStateService: MapStateService,
    private assetEditorService: AssetEditorService
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
      .pipe(take(1))
      .subscribe(([params, queryParams]) => {
        const routeSisId = params[MapRoutes.SisId];
        const routeAlias = params[MapRoutes.Alias];
        const routeSeason = params[MapRoutes.Season];
        const seasonParam = queryParams['season'];
        this.editMode = queryParams['edit'] != null;
        const parsedRouteSeason = Number.parseInt(routeSeason, 10);
        if (!(parsedRouteSeason in Season)) {
          this.navigator.navigateToErrorPage();
          return;
        }
        const parsedSeasonParam = Number.parseInt(seasonParam, 10);
        if (seasonParam != null && parsedSeasonParam !== Season.Summer && parsedSeasonParam !== Season.Winter) {
          this.navigator.navigateToErrorPage();
          return;
        }

        this.hideLayerSelector = queryParams && queryParams['layerSelector'] === 'hide';
        this.hideSeasonFilter = queryParams && queryParams['seasonFilter'] === 'hide';
        this.hideSignPosts = queryParams && queryParams['signPost'] === 'hide';
        this.openLayerSelector = queryParams && queryParams['layerSelector'] === 'open';
        this.showStatusIcons = queryParams && queryParams['showAssetIcon'] === 'true';

        const layers = [
          LayerName.Gastro,
          LayerName.Lift,
          LayerName.Place,
          LayerName.Poi,
          LayerName.Slope,
          LayerName.Trail,
          LayerName.Webcam,
          LayerName.CustomIcon,
          LayerName.CustomPath,
        ];

        this.masterDataService.requestMasterData(routeSisId, routeAlias, routeSeason, layers);
      });

    this.masterDataService.masterData.pipe(takeUntil(this.onDestroy$)).subscribe({
      next: (masterData) => {
        if (
          masterData == null ||
          (!this.editMode &&
            !(
              masterData?.gastros?.length ||
              masterData?.pois?.length ||
              masterData?.slopes?.length ||
              masterData?.trails?.length ||
              masterData?.webcams?.length
            ))
        ) {
          this.navigator.navigateToErrorPage();
          return;
        }
        this.masterData = masterData;

        this.statusService.startLiveData({ enabled: true, updateInterval: 20000, urlParams: [masterData.mapGuid] });
        this.statusService.status$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
          masterData.lifts.forEach((c) => this.updateStatusForElement(c, data));
          masterData.slopes.forEach((c) => this.updateStatusForElement(c, data));
          masterData.trails.forEach((c) => this.updateStatusForElement(c, data));
          masterData.pois.forEach((c) => this.updateStatusForElement(c, data));
          masterData.gastros.forEach((c) => this.updateStatusForElement(c, data));
        });
      },
      error: () => {
        this.navigator.navigateToErrorPage();
      },
    });

    this.assetEditorService.hasChanges$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((hasChanges) => (this.editorHasChanges = hasChanges));

    this.appService.backgroundColor$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((backgroundColor) => (this.backgroundColor = backgroundColor));
  }

  @HostListener('window:beforeunload')
  nativeCanDeactivate(): boolean {
    return !this.editorHasChanges;
  }

  canDeactivate(): Observable<boolean> {
    return this.assetEditorService.hasChanges$.pipe(map((hasChanges) => !hasChanges));
  }

  private updateStatusForElement(element: MapElement, data: Status[]): void {
    const newStatus = this.statusService.findStatus(element.guid, data);
    if (newStatus !== element.status) {
      element.status = newStatus;
      this.mapStateService.updateMapElement(element);
    }
  }
}

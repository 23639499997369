import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, of, timer } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  exhaustMap,
  map,
  shareReplay,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { LiveDataServiceOptions } from 'src/app/core/livedata/livedata-service-options.model';
import { environment } from 'src/environments/environment';

export abstract class LivedataServiceBase<S> {
  private liveDataEnabled$ = new BehaviorSubject<LiveDataServiceOptions>({
    enabled: false,
    updateInterval: 0,
  });

  protected liveData$ = this.liveDataEnabled$.pipe(
    distinctUntilKeyChanged('enabled'),
    distinctUntilKeyChanged('urlParams'),
    switchMap((options) => {
      if (options.enabled) {
        return this.publishLiveDataRepeatedly(options.updateInterval, options.urlParams);
      } else {
        return of<S[]>([]);
      }
    }),
    shareReplay(1)
  );

  constructor(private http: HttpClient, private endpointUri: string, private adapt: (item: any) => S[]) {}

  startLiveData(options: LiveDataServiceOptions): void {
    this.liveDataEnabled$.next(options);
  }

  private publishLiveDataRepeatedly(updateInterval: number, urlParams?: string[]): Observable<S[]> {
    return timer(0, 1000).pipe(
      exhaustMap(() =>
        this.requestSasToken(urlParams).pipe(
          map((sasToken) => {
            let expiryDuration: number;
            if (!sasToken) {
              expiryDuration = 0;
            } else {
              const expiryParam = new URLSearchParams(sasToken).get('se');
              const expiryDate = new Date(expiryParam).valueOf();
              expiryDuration = expiryDate - Date.now() - 10000;
            }

            return { sasToken, expiryDuration };
          }),
          switchMap((data) => {
            const killTimer = timer(data.expiryDuration);

            return this.getLiveDataRepeatedly(data.sasToken, updateInterval).pipe(takeUntil(killTimer));
          })
        )
      )
    );
  }

  private requestSasToken(urlParams?: string[]): Observable<string> {
    const urlAppend = urlParams ? `/${urlParams.join('/')}` : '';
    const url = `${environment.baseUrlApi}/${this.endpointUri}${urlAppend}`;
    return this.http.get(url).pipe(
      catchError(() => EMPTY),
      map((sas) => sas.toString())
    );
  }

  private getLiveDataRepeatedly(sasToken: string, updateInterval: number): Observable<S[]> {
    if (!sasToken || updateInterval == null) {
      return of([]);
    }

    return timer(0, updateInterval).pipe(
      switchMap(() => this.http.get(sasToken).pipe(catchError(() => EMPTY))),
      map((data: any) => (data == null ? [] : this.adapt(data.value)))
    );
  }
}

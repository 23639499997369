import { Converter } from 'src/app/core/livedata/converter';
import { OutdatedDataHelper } from 'src/app/core/livedata/outdated-data-helper';
import { MeteoData } from 'src/app/maps/domain/livedata/meteodata.model';

export class MeteoDataAdapter {
  static adapt(item: any): MeteoData[] {
    const livedata: MeteoData[] = [];

    item.forEach((e: any) => {
      livedata.push({
        meteoDeviceSisId: e.MeteoDeviceSisId,
        deviceTimestamp: e.DeviceTimestamp,
        gatewayTimestamp: e.GatewayTimestamp,
        windDirectionDegreeAxis: Converter.toNumberOrUndefined(e.WindDirectionDegreeAxis),
        windDirectionDegreeNorth: Converter.toNumberOrUndefined(e.WindDirectionDegreeNorth),
        windSpeedKmh: Converter.toNumberOrUndefined(e.WindSpeedKmh),
        temperatureCelsius: Converter.toNumberOrUndefined(e.TemperatureCelsius),
        outdated: OutdatedDataHelper.isOutdated(e.DeviceTimestamp, 60000),
      });
    });

    return livedata;
  }
}

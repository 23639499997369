import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { PendingChangesGuard } from 'src/app/core/guards/pending-changes.guard';
import { MapModule } from 'src/app/maps/map/map.module';
import { SisMapPage } from 'src/app/maps/sismap/sismap.page';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    IonicModule,
    MapModule,
    RouterModule.forChild([
      {
        path: '',
        component: SisMapPage,
        canDeactivate: [PendingChangesGuard],
      },
    ]),
    TranslateModule,
  ],
  declarations: [SisMapPage],
})
export class SisMapPageModule {}

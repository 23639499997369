import { Injectable } from '@angular/core';
import { DivIcon, divIcon, DomUtil, latLng, Marker, marker, PointExpression } from 'leaflet';
import { IconHelper } from 'src/app/core/leaflet-helper/icon-helper';
import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { Lift } from 'src/app/maps/domain/masterdata/lift.model';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';
import { MapStyleService } from 'src/app/maps/map/style/map-style.service';
import { WindIconService } from 'src/app/maps/map/wind-icon.service';
import { SisZoom } from 'src/app/maps/map/zoom.model';

Marker.include({
  scale: undefined as number | undefined,
  setScale(scale: number) {
    this.scale = scale;
    this.update();
  },
  _setPos(pos) {
    if (this._icon) {
      // Need to call setPosition so the internal map gets updated
      DomUtil.setPosition(this._icon, pos);
      if (this._icon.classList.contains('marker-size-undefined')) {
        this._icon.style.transform = `translate(-50%,-50%) translate(${pos.x}px,${pos.y}px)${
          this.scale ? ` scale(${this.scale})` : ''
        }`;
      } else {
        // use translate instead of translate3d so svgs are not blurry on chrome
        this._icon.style.transform = `translate(${pos.x}px,${pos.y}px)${this.scale ? ` scale(${this.scale})` : ''}`;
      }
    }

    if (this._shadow) {
      // Need to call setPosition so the internal map gets updated
      DomUtil.setPosition(this._shadow, pos);
      if (this._shadow.classList.contains('marker-size-undefined')) {
        this._shadow.style.transform = `translate(-50%,-50%) translate(${pos.x}px,${pos.y}px)${
          this.scale ? ` scale(${this.scale})` : ''
        }`;
      } else {
        this._icon.style.transform = `translate(${pos.x}px,${pos.y}px)${this.scale ? ` scale(${this.scale})` : ''}`;
      }
    }

    this._zIndex = pos.y + this.options.zIndexOffset;

    this._resetZIndex();
  },
});

@Injectable({
  providedIn: 'root',
})
export class IconService {
  private readonly customIconBlobPath = 'sismap/custom-icons/';

  private readonly errorIcon =
    '<div style="background: var(--ion-color-danger); color: var(--ion-color-danger-contrast); font-size: 14px; height: 20px; width: 20px; text-align: center">?</div>';

  constructor(
    private iconHelper: IconHelper,
    private windIconService: WindIconService,
    private styleService: MapStyleService
  ) {}

  async getIcon(iconName: string | AssetStatus): Promise<string> {
    if (typeof iconName === 'number') {
      switch (iconName) {
        case AssetStatus.Closed:
          iconName = 'closed';
          break;
        case AssetStatus.Disabled:
          iconName = 'disabled';
          break;
        case AssetStatus.Preparation:
          iconName = 'preparation';
          break;
        case AssetStatus.Open:
          iconName = 'open';
          break;
        default:
          iconName = iconName.toString();
      }
    }

    return this.styleService.getIconSvg(iconName);
  }

  async getMarker(position: MapPosition, element: MapElement): Promise<Marker> {
    const icon = await this.createIconElement(element);
    return marker(latLng(position.x, position.y), { icon, zIndexOffset: element.iconAlwaysOnTop ? 1000 : undefined });
  }

  async getIconHtml(element: MapElement): Promise<string> {
    let html = this.errorIcon;
    try {
      if (element.iconUseStatus && element.status != null && element.status !== AssetStatus.NoStatus) {
        html = await this.getIcon(element.status);
      } else if (element.iconImageName != null) {
        html = await this.getIcon(element.iconImageName);
      } else if (element.iconSvgBlobName != null) {
        html = await this.iconHelper.getIcon(this.customIconBlobPath + element.iconSvgBlobName + '.svg');
      } else if (element.iconHtml != null) {
        html = element.iconHtml;
      } else if (element.iconUseMeteoData === true) {
        html = await this.windIconService.getIconHTML(element);
      }
    } catch (e) {}
    return html;
  }

  async getAssetTypeIconHtml(element: MapElement): Promise<string> {
    let html = this.errorIcon;
    try {
      if (element.iconImageName != null) {
        if (element.status != null) {
          const colors =
            element instanceof Lift
              ? await this.styleService.getLiftColor(element.status, element, true)
              : await this.styleService.getColor(element.status);
          const sidepaneIcon = await this.getIcon(element.iconImageName);
          html = await this.styleService.setSidepaneIconColor(sidepaneIcon, colors.statusColor, colors.textColor);
        } else {
          html = await this.getIcon(element.iconImageName);
        }
      }
    } catch (e) {}
    return html;
  }

  async createIconElement(element: MapElement): Promise<DivIcon> {
    const html = await this.getIconHtml(element);

    const iconHeight = element.iconHeight !== undefined ? element.iconHeight : 20;
    const iconWidth = element.iconWidth !== undefined ? element.iconWidth : 20;

    const iconSize: PointExpression | undefined =
      iconHeight === null || iconWidth === null ? undefined : [iconWidth, iconHeight];

    const icon = divIcon({
      html,
      iconSize,
      popupAnchor: [0, -7],
      className: `data-test-${SisMapAssetCategory[element.category].toLowerCase()}  ${
        iconSize === undefined ? 'marker-size-undefined' : ''
      }`,
    });
    return icon;
  }

  scaleIcon(m: Marker, zoom: SisZoom, element: MapElement, highlight = false, hideOnScaling = true): void {
    const hideIcons = hideOnScaling && !zoom.showIcons;

    if (hideIcons) {
      m.setOpacity(0);
    } else {
      m.setOpacity(1);
      const scaling =
        (highlight ? zoom.iconZoom * 1.25 : zoom.iconZoom) * (element.iconDisableBaseZoom ? 1 : zoom.baseIconZoom);
      (m as Marker & { setScale(scale: number): void }).setScale(scaling);
    }
  }
}

<ng-container *ngIf="masterData">
  <sis-map
    class="sis-map"
    [masterData]="masterData"
    [backgroundColor]="this.backgroundColor"
    [showStatusIcons]="this.showStatusIcons"
    [editMode]="editMode"
  ></sis-map>
  <sis-layer-selector
    [hideLayerSelector]="this.hideLayerSelector"
    [hideSeasonFilter]="this.hideSeasonFilter"
    [hideSignPosts]="this.hideSignPosts"
    [openLayerSelector]="this.openLayerSelector"
    [season]="this.masterData.season"
    [sisId]="this.masterData.sisId"
    [areaUrlName]="this.masterData.areaUrlName"
    [oppositeMapExist]="this.masterData.oppositeMapExist"
    data-test="sis-layer-selector"
  ></sis-layer-selector>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { OperatingInfoAdapter } from 'src/app/operating-info/domain/operating-info.adapter';
import { OperatingInfoEntryAdapter } from 'src/app/operating-info/domain/operating-info-entry.adapter';
import { OperatingInfoTenantAdapter } from 'src/app/operating-info/domain/operating-info-tenant.adapter';
import { OperatingInfoTextAdapter } from 'src/app/operating-info/domain/operating-info-text.adapter';
import { OperatingInfoPage } from 'src/app/operating-info/operating-info.page';
import { OperatingInfoService } from 'src/app/operating-info/operating-info.service';
import { OperatingInfoItemComponent } from 'src/app/operating-info/operating-info-item/operating-info-item.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: OperatingInfoPage
      }
    ]),
    CoreModule,
    TranslateModule
  ],
  declarations: [OperatingInfoPage, OperatingInfoItemComponent],
  providers: [
    OperatingInfoService,
    OperatingInfoAdapter,
    OperatingInfoTenantAdapter,
    OperatingInfoEntryAdapter,
    OperatingInfoTextAdapter
  ]
})
export class OperatingInfoPageModule {}

<ion-grid class="sis-numberpicker-grid" [class]="this.floatRight ? 'sis-float-right' : ''">
  <ion-row>
    <ion-col
      class="sis-btn-col"
      [ngStyle]="{ 'background-color': this.color }"
      size="3.5"
      (click)="this.decrement()"
      data-test="number-picker-decrement"
      >-</ion-col
    >
    <ion-col
      size="5"
      [ngStyle]="{ fontSize: this.fontSize }"
      style="border-top: 1px solid gray; border-bottom: 1px solid gray;"
      >{{ this.displayedValue }}{{ this.unit }}</ion-col
    >
    <ion-col
      class="sis-btn-col"
      [ngStyle]="{ 'background-color': this.color }"
      size="3.5"
      (click)="this.increment()"
      data-test="number-picker-increment"
      >+</ion-col
    >
  </ion-row>
</ion-grid>

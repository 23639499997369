<ion-grid *ngIf="this.operatingInfo" [ngClass]="this.isMultiTenant ? 'sis-multitenant-grid' : 'sis-grid'">
  <ion-row>
    <ion-col>
      <img [src]="this.logoUrl" class="sis-tenant-logo" />
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="ion-text-center" data-test="sis-operating-info-title">
      <h3>{{ 'operatinginfo.title' | translate }}</h3>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <sis-operating-info-item
        *ngFor="let item of this.operatingInfo.entries;"
        class="sis-operating-info-item"
        [entry]="item"
        data-test="sis-operating-info-item"
      ></sis-operating-info-item>
    </ion-col>
  </ion-row>

  <ion-row
    *ngFor="let link of this.operatingInfo.links"
    class="sis-link-row vertical-align-content"
    data-test="sis-operating-info-links"
  >
    <ion-col>
      <a href="{{ link.url }}" target="_blank">
        <ion-icon [name]="link.iconName"></ion-icon>
      </a>
      <a href="{{ link.url }}" target="_blank">
        <span>{{ link.description}}</span>
      </a>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-grid
  *ngIf="this.operatingInfo && !this.isMultiTenant"
  class="sis-footer-grid"
  data-test="sis-operating-info-tenant-footer"
>
  <ion-row>
    <ion-col>
      <span>{{ 'operatinginfo.information' | translate }}</span><br />
      <a href="{{ this.operatingInfo.tenants[0].website }}" target="_blank"
        >{{ this.operatingInfo.tenants[0].website }}</a
      ><br />
      <a href="tel:{{ this.operatingInfo.tenants[0].phone }}">Tel. {{ this.operatingInfo.tenants[0].phone }}</a>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-grid
  *ngIf="this.operatingInfo && this.isMultiTenant"
  class="sis-tab-grid"
  data-test="sis-operating-info-tenant-tab"
>
  <ion-row>
    <ion-col *ngFor="let tenant of this.operatingInfo.tenants; trackBy: trackByGuid">
      <a href="{{ tenant.website }}" target="_blank">
        <ion-img
          [src]="this.publicAssetLogoBaseUrl + tenant.logo"
          class="sis-tab-logo"
          data-test="sis-operating-info-tenant-logo"
        ></ion-img>
      </a>
      <a href="{{ tenant.website }}" target="_blank">
        <span class="sis-tab-span">{{ tenant.alias }}</span>
      </a>
    </ion-col>
  </ion-row>
</ion-grid>

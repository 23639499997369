import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LivedataServiceBase } from 'src/app/core/livedata/livedatabase.service';
import { MeteoDataAdapter } from 'src/app/maps/domain/livedata/meteodata.adapter';
import { MeteoData } from 'src/app/maps/domain/livedata/meteodata.model';

@Injectable({
  providedIn: 'root',
})
export class MeteoDataService extends LivedataServiceBase<MeteoData> {
  meteoData$: Observable<MeteoData[]> = this.liveData$;

  constructor(http: HttpClient) {
    super(http, 'api/livedata/windmonitor', MeteoDataAdapter.adapt);
  }
}

<ion-row *ngIf="!this.webReportMobileItems" class="sis-center">
  <ion-col>
    <ion-spinner></ion-spinner>
  </ion-col>
</ion-row>

<ion-row *ngIf="!this.webReportMobileItems?.length" class="sis-center">
  <ion-col>
    <span style="color: white"
      >{{ 'webreport.term.no' | translate }} {{ this.titleTranslationString | translate }}
      {{ 'webreport.term.available' | translate }}</span
    >
  </ion-col>
</ion-row>

<sis-webreport-mobile-tenant
  *ngFor="let item of this.webReportMobileItems; trackBy: trackByTenantAlias"
  [tenantAlias]="item.tenantAlias"
  [items]="item.items"
  [config]="this.config"
  [mobileStyleTenant]="this.mobileStyleTenant"
  [longestLabelLength]="item.longestLabelLength"
></sis-webreport-mobile-tenant>

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  readonly backgroundColor$ = this.activatedRoute.queryParams.pipe(
    map(params => params['background']),
    filter(b => b != null),
    map(b => '#' + b)
  );

  constructor(private activatedRoute: ActivatedRoute) {}
}

import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { from, map, of, switchMap } from 'rxjs';
import { IconService } from 'src/app/maps/map/icon-service';
import { MapStateService } from 'src/app/maps/map/map-state.service';
import { MapStyleService } from 'src/app/maps/map/style/map-style.service';

@Component({
  selector: 'sis-asset-sidepane-content',
  templateUrl: './asset-sidepane-content.component.html',
  styleUrls: ['./asset-sidepane-content.component.scss'],
})
export class AssetSidepaneContentComponent {
  readonly element$ = this.mapStateService.selectedElement$.pipe(map(({ element }) => element));

  readonly className$ = from(this.mapStyleService.getStyleName()).pipe(map((name) => `sis-menu-${name}`));

  readonly assetTypeIcon$ = this.element$.pipe(
    switchMap((element) => {
      if (element?.iconImageName) {
        return from(this.iconService.getAssetTypeIconHtml(element)).pipe(
          map((iconString) => this.sanitizer.bypassSecurityTrustHtml(iconString))
        );
      }
      return of(null);
    })
  );

  constructor(
    private mapStyleService: MapStyleService,
    private mapStateService: MapStateService,
    private iconService: IconService,
    private sanitizer: DomSanitizer
  ) {}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, map, Observable, switchMap, take } from 'rxjs';
import { GastroAdapter } from 'src/app/maps/domain/masterdata/gastro.adapter';
import { LiftAdapter } from 'src/app/maps/domain/masterdata/lift.adapter';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MasterDataService } from 'src/app/maps/domain/masterdata/masterdata.service';
import { POIAdapter } from 'src/app/maps/domain/masterdata/poi.adapter';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';
import { SlopeAdapter } from 'src/app/maps/domain/masterdata/slope.adapter';
import { Tenant } from 'src/app/maps/domain/masterdata/tenant.model';
import { TrailAdapter } from 'src/app/maps/domain/masterdata/trail.adapter';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  tenants$: Observable<Tenant[]>;

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private masterDataService: MasterDataService
  ) {}

  getTenants(): Observable<Tenant[]> {
    const url = `${environment.baseUrlApi}/api/sismap/tenants`;
    return combineLatest([this.activatedRoute.queryParams, this.masterDataService.masterData]).pipe(
      take(1),
      switchMap(([queryParams, masterData]) => {
        const editToken = queryParams['edit'];
        return this.http.get(`${url}?edit=${editToken}&map=${masterData.mapGuid}`, { responseType: 'json' });
      }),
      map((response) => {
        if (!Array.isArray(response)) {
          throw new Error('Tenant-API response was not an array');
        }
        const tenants = response as Tenant[];
        tenants.forEach((t) => {
          if (t.alias == null || t.guid == null || t.sisId == null) {
            throw new Error('Tenant-API did return tenants that did not contain all necessary fields');
          }
        });
        return tenants;
      })
    );
  }

  getAssetForTenant(tenant: Tenant): Observable<MapElement[]> {
    const url = `${environment.baseUrlApi}/api/sismap/assets/${tenant.guid}`;
    return combineLatest([this.activatedRoute.queryParams, this.masterDataService.masterData]).pipe(
      take(1),
      switchMap(([queryParams, masterData]) => {
        const editToken = queryParams['edit'];
        return this.http.get(`${url}?edit=${editToken}&map=${masterData.mapGuid}`, { responseType: 'json' });
      }),
      map((response: any[]) => {
        let result: MapElement[] = [];
        response.forEach((data: any) => {
          const assetCategory = data.assetCategory;
          const assets: any[] = data.assets;
          switch (assetCategory) {
            case SisMapAssetCategory.Slope:
              result = result.concat(assets.map((v: any) => SlopeAdapter.adapt(v)));
              break;
            case SisMapAssetCategory.Lift:
              result = result.concat(assets.map((v: any) => LiftAdapter.adapt(v)));
              break;
            case SisMapAssetCategory.Trail:
              result = result.concat(assets.map((v: any) => TrailAdapter.adapt(v)));
              break;
            case SisMapAssetCategory.Poi:
              result = result.concat(assets.map((v: any) => POIAdapter.adapt(v)));
              break;
            case SisMapAssetCategory.Gastro:
              result = result.concat(assets.map((v: any) => GastroAdapter.adapt(v)));
              break;
            default:
              console.error(`Unexpected AssetCategory found: ${assetCategory}`);
              break;
          }
        });
        return result;
      })
    );
  }
}

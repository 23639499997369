<ion-grid
  *ngIf="this.bigScreenMode && this.isDesktopDataLoaded"
  class="sis-grid"
  [style.min-height]="this.gridMinHeight + 'px'"
  #desktopGrid
>
  <ion-row *ngIf="this.showHeader" class="sis-header" [style]="{ 'background': this.config?.headerColor }">
    <ion-col>
      <ion-img [src]="this.headerImage" class="sis-header-image"></ion-img>
      <span
        class="sis-header-timestamp"
        *ngIf="this.showTimestamp"
        [ngStyle]="{ 'color': this.config?.tenantTitleColor
}"
        >{{this.timestamp | date : 'dd.MM.yyyy HH:mm'}}</span
      >
    </ion-col>
  </ion-row>

  <div
    [ngClass]="this.allowPageBreak ? 'sis-tenant-allow-page-break' : 'sis-tenant-avoid-page-break'"
    *ngFor="let tenantMapping of this.webReportDesktopItems; trackBy: trackByTenantAlias"
  >
    <ion-row>
      <ion-col
        class="sis-tenant-title"
        [ngStyle]="{ 'font-size': this.config?.tenantTitleFontSize,
          'color': this.config?.tenantTitleColor,
          'background': this.config?.tenantColor ? this.config?.tenantColor : this.config?.headerColor }"
      >
        {{ tenantMapping.tenantAlias }}
      </ion-col>
    </ion-row>

    <ion-row *ngIf="tenantMapping.infotext" class="sis-infotext">
      <ion-col class="ion-no-padding">
        <div [innerHTML]="tenantMapping.infotext"></div>
      </ion-col>
    </ion-row>

    <sis-webreport-meteo-desktop
      [tenantAlias]="tenantMapping.tenantAlias"
      [meteoInfos$]="webReportMeteoItems$"
      [config]="this.config"
      [sisMediaSettingByTenantAlias]="this.sisMediaSettingByTenantAlias"
    ></sis-webreport-meteo-desktop>

    <ion-row *ngIf="tenantMapping.lifts.length > 0" class="sis-category-title">
      <ion-col [ngStyle]="{ 'font-size': this.config?.assetTitleFontSize, 'color': this.config?.assetTitleColor }">
        <span *ngFor="let title of tenantMapping.liftsTitles; last as isLast">
          {{ title | translate }} <span *ngIf="!isLast"> / </span></span
        >
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let row of tenantMapping.lifts" class="sis-data-row">
      <ion-col *ngFor="let item of row.rowItems, let index = index">
        <sis-sismedia-item
          *ngIf="item"
          [item]="item"
          [bigScreenMode]="true"
          [config]="this.config"
          [longestLabelLength]="row.longestLabelLengths[index]"
        >
        </sis-sismedia-item>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="tenantMapping.slopes.length > 0" class="sis-category-title">
      <ion-col [ngStyle]="{ 'font-size': this.config?.assetTitleFontSize, 'color': this.config?.assetTitleColor }">
        <span *ngFor="let title of tenantMapping.slopesTitles; last as isLast">
          {{ title | translate }} <span *ngIf="!isLast"> / </span></span
        >
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let row of tenantMapping.slopes" class="sis-data-row">
      <ion-col *ngFor="let item of row.rowItems, let index = index">
        <sis-sismedia-item
          *ngIf="item"
          [item]="item"
          [bigScreenMode]="true"
          [config]="this.config"
          [longestLabelLength]="row.longestLabelLengths[index]"
        >
        </sis-sismedia-item>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="tenantMapping.trails.length > 0" class="sis-category-title">
      <ion-col [ngStyle]="{ 'font-size': this.config?.assetTitleFontSize, 'color': this.config?.assetTitleColor }">
        <span *ngFor="let title of tenantMapping.trailsTitles; last as isLast">
          {{ title | translate }} <span *ngIf="!isLast"> / </span></span
        >
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let row of tenantMapping.trails" class="sis-data-row">
      <ion-col *ngFor="let item of row.rowItems, let index = index">
        <sis-sismedia-item
          *ngIf="item"
          [item]="item"
          [bigScreenMode]="true"
          [config]="this.config"
          [longestLabelLength]="row.longestLabelLengths[index]"
        >
        </sis-sismedia-item>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="tenantMapping.gastros.length > 0" class="sis-category-title">
      <ion-col [ngStyle]="{ 'font-size': this.config?.assetTitleFontSize, 'color': this.config?.assetTitleColor }">
        <span *ngFor="let title of tenantMapping.gastrosTitles; last as isLast">
          {{ title | translate }} <span *ngIf="!isLast"> / </span></span
        >
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let row of tenantMapping.gastros" class="sis-data-row">
      <ion-col *ngFor="let item of row.rowItems">
        <sis-sismedia-item *ngIf="item" [item]="item" [bigScreenMode]="true"> </sis-sismedia-item>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="tenantMapping.pois.length > 0" class="sis-category-title">
      <ion-col [ngStyle]="{ 'font-size': this.config?.assetTitleFontSize, 'color': this.config?.assetTitleColor }">
        <span *ngFor="let title of tenantMapping.poisTitles; last as isLast">
          {{ title | translate }} <span *ngIf="!isLast"> / </span></span
        >
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let row of tenantMapping.pois" class="sis-data-row">
      <ion-col *ngFor="let item of row.rowItems">
        <sis-sismedia-item *ngIf="item" [item]="item" [bigScreenMode]="true"> </sis-sismedia-item>
      </ion-col>
    </ion-row>

    <ion-row style="height: 50px">
      <ion-col></ion-col>
    </ion-row>
  </div>

  <ion-row *ngIf="this.showLegend" class="vertical-align-content sis-legend">
    <ion-col size="auto">
      <ion-icon name="sis-open" class="sis-statusicon sis-green"></ion-icon>
      {{ 'webreport.term.open' | translate }}
    </ion-col>
    <ion-col size="auto">
      <ion-icon name="sis-closed" class="sis-statusicon sis-red"></ion-icon>
      {{ 'webreport.term.closed' | translate }}
    </ion-col>
    <ion-col size="auto">
      <ion-icon name="sis-preparation" class="sis-statusicon sis-yellow"></ion-icon>
      {{ 'webreport.term.preparation' | translate }}
    </ion-col>
  </ion-row>

  <ion-row class="sis-footer vertical-align-content">
    <ion-col>
      <ng-container *ngIf="this.showFooter">
        <span> {{ this.alias }} </span>
        <span *ngIf="this.websiteUrl || this.phone" class="sis-padding"> | </span>
        <span *ngIf="this.websiteUrl">
          <a href="{{ this.websiteUrl }}" target="_blank">{{ this.website }}</a>
        </span>
        <span class="sis-padding" *ngIf="this.websiteUrl"> | </span>
        <span *ngIf="this.phone">
          <a href="tel:{{ this.phone }}">Tel. {{ this.phone }}</a>
        </span>
      </ng-container>
    </ion-col>
    <ion-col size="auto" class="sis-siscontrol">
      <img class="sis-siscontrol-image" src="assets/sis-logo/siscontrol-color.svg" /><span class="sis-padding">|</span
      ><a href="https://sisag.ch/produkte/siscontrol" target="_blank">www.sisag.ch</a
      ><span class="sis-padding">|</span>© 2024 SISAG AG
    </ion-col>
  </ion-row>
</ion-grid>

<!-- small screen -->
<ion-grid
  *ngIf="!this.bigScreenMode && this.isMobileDataLoaded"
  [ngClass]="this.mobileStyleTenant ? 'sis-grid-small-tenant-style' : 'sis-grid-small-sisag-style'"
>
  <ion-row
    class="sis-category-title-small"
    [ngStyle]="this.mobileStyleTenant ? {
      'color': this.config?.tenantTitleColor,
      'background': this.config?.tenantColor,
      'border-bottom-color': this.config?.tenantTitleColor } : {}"
  >
    <ion-col>
      <a href="https://sisag.ch/produkte/siscontrol" class="sis-logo" target="_blank">
        <img src="assets/sis-logo/sis-a.col-tr.48x48.png" />
      </a>
      <span>{{ this.titleTranslationString | translate }}</span>
    </ion-col>
  </ion-row>

  <sis-webreport-mobile-category
    *ngFor="let kvp of this.webReportItemsByCategory | keyvalue"
    [items]="kvp.value"
    [category]="kvp.key"
    [itemsUpdated$]="this.webReport$"
    [config]="this.config"
    [mobileStyleTenant]="this.mobileStyleTenant"
    [ngStyle]="{'display': kvp.key === (this.selectedAssetCategory$ | async) ? 'block' : 'none' }"
  ></sis-webreport-mobile-category>

  <sis-webreport-meteo-mobile
    [meteoInfos$]="webReportMeteoItems$"
    [config]="this.config"
    [mobileStyleTenant]="this.mobileStyleTenant"
    [sisMediaSettingByTenantAlias]="this.sisMediaSettingByTenantAlias"
    [ngStyle]="{'display': (this.selectedAssetCategory$ | async) === 6 ? 'block' : 'none' }"
  ></sis-webreport-meteo-mobile>
</ion-grid>

<!-- tab bar -->
<ion-toolbar
  *ngIf="!this.bigScreenMode && this.isMobileDataLoaded"
  [ngClass]="this.mobileStyleTenant ? 'sis-webreport-toolbar-tenant-style' : 'sis-webreport-toolbar-sisag-style'"
>
  <ion-tab-bar
    [ngClass]="this.mobileStyleTenant ? 'sis-webreport-tab-bar-tenant-style' : 'sis-webreport-tab-bar-sisag-style'"
  >
    <ion-col *ngIf="this.hasLifts" (click)="this.assetCategorySelected('Lift')" class="sis-tab-col">
      <ion-icon
        [name]="this.selectedAssetCategory === 'Lift' ? 'sis-icon-ropeway-dark' :'sis-icon-ropeway'"
        class="sis-category-icon"
      ></ion-icon>
      <span class="sis-tab-span" [ngClass]="this.selectedAssetCategory === 'Lift' ? 'sis-selected': 'sis-not-selected'"
        >{{ 'webreport.assetCategoryLift' | translate }}</span
      >
    </ion-col>
    <ion-col *ngIf="this.hasSlopes" (click)="this.assetCategorySelected('Slope')" class="sis-tab-col">
      <ion-icon
        [name]="this.selectedAssetCategory === 'Slope' ? 'sis-icon-slope-dark' :'sis-icon-slope'"
        class="sis-category-icon"
      ></ion-icon>
      <ion-label
        class="sis-tab-span"
        [ngClass]="this.selectedAssetCategory === 'Slope' ? 'sis-selected': 'sis-not-selected'"
        >{{ 'webreport.assetCategorySlope' | translate }}</ion-label
      >
    </ion-col>
    <ion-col *ngIf="this.hasTrails" (click)="this.assetCategorySelected('Trail')" class="sis-tab-col">
      <ion-icon
        [name]="this.selectedAssetCategory === 'Trail' ? 'sis-icon-trail-dark' :'sis-icon-trail'"
        class="sis-category-icon"
      ></ion-icon>
      <ion-label
        class="sis-tab-span"
        [ngClass]="this.selectedAssetCategory === 'Trail' ? 'sis-selected': 'sis-not-selected'"
        >{{ 'webreport.assetCategoryTrail' | translate }}</ion-label
      >
    </ion-col>
    <ion-col *ngIf="this.hasGastros" (click)="this.assetCategorySelected('Gastro')" class="sis-tab-col">
      <ion-icon
        [name]="this.selectedAssetCategory === 'Gastro' ? 'sis-icon-gastro-dark' :'sis-icon-gastro'"
        class="sis-category-icon"
      ></ion-icon>
      <ion-label
        class="sis-tab-span"
        [ngClass]="this.selectedAssetCategory === 'Gastro' ? 'sis-selected': 'sis-not-selected'"
        >{{ 'webreport.assetCategoryGastro' | translate }}</ion-label
      >
    </ion-col>
    <ion-col *ngIf="this.hasPois" (click)="this.assetCategorySelected('Poi')" class="sis-tab-col">
      <ion-icon
        [name]="this.selectedAssetCategory === 'Poi' ? 'sis-icon-poi-dark' :'sis-icon-poi'"
        class="sis-category-icon"
      ></ion-icon>
      <ion-label
        class="sis-tab-span"
        [ngClass]="this.selectedAssetCategory === 'Poi' ? 'sis-selected': 'sis-not-selected'"
        >{{ 'webreport.assetCategoryPoi' | translate }}</ion-label
      >
    </ion-col>
    <ion-col *ngIf="this.showMeteo" (click)="this.assetCategorySelected('Meteo')" class="sis-tab-col">
      <ion-icon
        [name]="this.selectedAssetCategory === 'Meteo' ? 'sis-icon-meteo-dark' :'sis-icon-meteo'"
        class="sis-category-icon"
      ></ion-icon>
      <ion-label
        class="sis-tab-span"
        [ngClass]="this.selectedAssetCategory === 'Meteo' ? 'sis-selected': 'sis-not-selected'"
        >{{ 'webreport.assetCategoryMeteo' | translate }}</ion-label
      >
    </ion-col>
  </ion-tab-bar>
</ion-toolbar>

import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 *  Unsubscribe from any Observable using takeUntil and this.destroy$
 *      someObservable.pipe( // lettable operators in rxjs ^5.5.0
 *          ...
 *          takeUntil(destroy$)
 *      )
 *      .subscribe(....
 **/

@Directive()
export class Unsubscriber implements OnDestroy {
  onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    // Unsubscribe from whatever used takeUntil(destroy$)
    this.onDestroy$.next();
    // Now let's also unsubscribe from the subject itself:
    this.onDestroy$.unsubscribe();
  }
}

import { CustomPath } from 'src/app/maps/domain/masterdata/custom-path.model';

export class CustomPathAdapter {
  static adapt(item: any): CustomPath {
    if (!item) {
      return CustomPath.empty;
    }

    return new CustomPath({
      guid: item.guid,
      season: item.season,
      path: item.path,
      type: item.type,
      name: item.description ?? '',
      pathColor: item.color,
      pathDashArray: item.dashArray,
      pathWeight: item.weight,
      iconSvgBlobName: 'customPath',
      disallowHighlight: true,
      disallowPopUp: true,
      iconPositions: [],
    });
  }

  static adaptForApi(customPath: CustomPath): any {
    return {
      guid: customPath.guid,
      assetCategory: customPath.category,
      path: customPath.path,
      type: customPath.type,
      name: customPath.name,
      color: customPath.pathColor,
      dashArray: customPath.pathDashArray,
      weight: customPath.pathWeight,
    };
  }
}

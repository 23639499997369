<div sisEnterViewportNotifier (visibilityChange)="setMapVisibility($event)" *ngIf="!this.wasVisibleAtLeastOnce"></div>
<ion-grid id="asset-content">
  <ion-row>
    <ion-col [size]="this.openSidepane && this.bigScreenMode ? 9 : 12">
      <div
        *ngIf="layers && this.wasVisibleAtLeastOnce"
        leaflet
        #leafletContainer
        cdkDropList
        (cdkDropListDropped)="drop($event)"
        id="mapList"
        class="sis-pano-map"
        [style.background-color]="this.backgroundColor"
        [leafletOptions]="options"
        [leafletLayers]="layers"
        (leafletMapReady)="onMapReady($event)"
        [(leafletCenter)]="center"
        [cdkContextMenuTriggerFor]="context_menu"
        [cdkContextMenuDisabled]="!editMode"
      >
        <sis-map-element-container
          *ngFor="let slope of slopes; trackBy: trackByGuid"
          [layerGroup]="slopeLayerGroup"
          [element]="slope"
          [showStatus]="this.showStatus"
          [showStatusIcons]="this.showStatusIcons"
          [editMode]="this.editMode"
          data-test="sis-slope-layer-group"
        ></sis-map-element-container>
        <sis-map-element-container
          *ngFor="let trail of trails; trackBy: trackByGuid"
          [layerGroup]="trailLayerGroup"
          [element]="trail"
          [showStatus]="this.showStatus"
          [editMode]="this.editMode"
          data-test="sis-trail-layer-group"
        ></sis-map-element-container>
        <sis-map-element-container
          *ngFor="let lift of lifts; trackBy: trackByGuid"
          [layerGroup]="liftLayerGroup"
          [element]="lift"
          [showStatus]="this.showStatus"
          [editMode]="this.editMode"
          data-test="sis-lift-layer-group"
        ></sis-map-element-container>
        <sis-map-element-container
          *ngFor="let poi of pois; trackBy: trackByGuid"
          [layerGroup]="poiLayerGroup"
          [element]="poi"
          [showStatus]="this.showStatus"
          [editMode]="this.editMode"
          data-test="sis-poi-layer-group"
        ></sis-map-element-container>
        <sis-map-element-container
          *ngFor="let place of places; trackBy: trackByGuid"
          [layerGroup]="placeLayerGroup"
          [element]="place"
          [editMode]="this.editMode"
          data-test="sis-place-layer-group"
        ></sis-map-element-container>
        <sis-map-element-container
          *ngFor="let customIcon of customIcons; trackBy: trackByGuid"
          [layerGroup]="customIconLayerGroup"
          [element]="customIcon"
          [editMode]="this.editMode"
          data-test="sis-custom-icon-layer-group"
        ></sis-map-element-container>
        <sis-map-element-container
          *ngFor="let customPath of customPaths; trackBy: trackByGuid"
          [layerGroup]="customPathLayerGroup"
          [showStatus]="true"
          [element]="customPath"
          [editMode]="this.editMode"
          data-test="sis-custom-path-layer-group"
        ></sis-map-element-container>
        <sis-map-element-container
          *ngFor="let gastro of gastros; trackBy: trackByGuid"
          [layerGroup]="gastroLayerGroup"
          [element]="gastro"
          [editMode]="this.editMode"
          data-test="sis-gastro-layer-group"
        >
        </sis-map-element-container>
        <sis-map-element-container
          *ngFor="let webcam of webcams; trackBy: trackByGuid"
          [layerGroup]="webcamLayerGroup"
          [element]="webcam"
          [editMode]="this.editMode"
          data-test="sis-webcam-layer-group"
        >
        </sis-map-element-container>
        <sis-map-element-container
          *ngFor="let wind of winds; trackBy: trackByGuid"
          [layerGroup]="windLayerGroup"
          [element]="wind"
          [masterData]="masterData"
          [editMode]="this.editMode"
          data-test="sis-wind-layer-group"
        >
        </sis-map-element-container>
      </div>
    </ion-col>
    <ion-col *ngIf="this.editMode && this.bigScreenMode" [size]="this.openSidepane ? 3 : 0">
      <div class="sis-open-sidepane-button" (click)="this.onOpenSidepaneClick()">
        <span *ngIf="this.openSidepane">⟫</span>
        <span *ngIf="!this.openSidepane">⟪</span>
      </div>
      <sis-sismap-sidepane [class.hide-sidepane]="!this.openSidepane"></sis-sismap-sidepane>
    </ion-col>
  </ion-row>
</ion-grid>
<div *ngIf="this.editMode && this.bigScreenMode" class="edit-mode-banner">
  <span>Edit Mode</span>
</div>

<sis-asset-sidepane
  *ngIf="this.enableAssetSidepane"
  [editMode]="this.editMode"
  [bigScreenMode]="this.bigScreenMode"
></sis-asset-sidepane>

<ng-template #context_menu>
  <div class="context-menu" cdkMenu>
    <button class="context-menu-item" cdkMenuItem (click)="addWebcam()">Neue Webcam</button>
    <button class="context-menu-item" cdkMenuItem (click)="addPlace()">Neuer Place</button>
    <button class="context-menu-item" cdkMenuItem (click)="addCustomIcon()">Neues CustomIcon</button>
    <button class="context-menu-item" cdkMenuItem (click)="addCustomPath()">Neuer CustomPath</button>
  </div>
</ng-template>

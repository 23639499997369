import { Converter } from 'src/app/core/livedata/converter';
import { SisMediaAssetCategory } from 'src/app/webreport/domain/sismedia-asset-category.enum';
import { SisMediaAssetStatus } from 'src/app/webreport/domain/sismedia-asset-status.enum';
import { SisMediaItemAdapter } from 'src/app/webreport/domain/sismedia-item.adapter';
import { SisMediaItem } from 'src/app/webreport/domain/sismedia-item.model';
import { SisMediaSettingAdapter } from 'src/app/webreport/domain/sismedia-setting.adapter';
import { SisMediaSetting } from 'src/app/webreport/domain/sismedia-setting.model';
import { WebReport } from 'src/app/webreport/domain/webreport.model';
import { WebReportInfotext } from 'src/app/webreport/domain/webreport-infotext.model';
import { WebReportInfotextItemAdapter } from 'src/app/webreport/domain/webreport-infotext-item.adapter';
import { WebReportMeteoInfoItemAdapter } from 'src/app/webreport/domain/webreport-meteo-info-item.adapter';
import { WebReportMeteoInfoItem } from 'src/app/webreport/domain/webreport-meteo-info-item.model';
import { WebReportTenant } from 'src/app/webreport/domain/webreport-tenant.model';

export class WebReportAdapter {
  static adapt(item: any, columns: number): WebReport {
    if (!item) {
      return null;
    }

    const tenant: WebReportTenant = {
      alias: item.tenant.alias,
      name: item.tenant.name,
      guid: item.tenant.guid,
      logo: item.tenant.logoFilename,
      website: item.tenant.website,
      phone: item.tenant.phone,
      timeZoneId: item.tenant.timeZoneId,
    };

    const exportConfig = JSON.parse(item.exportConfig) || {};
    exportConfig.columns = !isNaN(columns) && columns > 0 ? columns : exportConfig.columns ?? 1;

    const sisMediaItemsByCategory = Object.entries(item.webReportAssetsByCategory).reduce(
      (p, c) =>
        p.set(
          SisMediaAssetCategory[Converter.firstLetterUppercase(c[0])],
          SisMediaItemAdapter.adapt(c[1])
            .filter((d) => d.status !== SisMediaAssetStatus.Disabled && d.status !== SisMediaAssetStatus.NoStatus)
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .sort((a, b) => (a.type < b.type ? -1 : 1))
            .sort((a, b) => a.order - b.order)
        ),
      new Map<SisMediaAssetCategory, SisMediaItem[]>()
    );

    const infotextsByTenantAlias = Object.entries(item.infotextsByTenantAlias).reduce(
      (k, v) => k.set(Converter.firstLetterUppercase(v[0]), WebReportInfotextItemAdapter.adapt(v[1])),
      new Map<string, WebReportInfotext>()
    );

    const meteoInfosByTenantAlias = Object.entries(item.meteoInfosByTenantAlias).reduce(
      (k, v) => k.set(Converter.firstLetterUppercase(v[0]), WebReportMeteoInfoItemAdapter.adapt(v[1] as any[])),
      new Map<string, WebReportMeteoInfoItem[]>()
    );

    const sisMediaSettingByTenantAlias = Object.entries(item.sisMediaSettingByTenantAlias).reduce(
      (k, v) => k.set(Converter.firstLetterUppercase(v[0]), SisMediaSettingAdapter.adapt(v[1] as any)),
      new Map<string, SisMediaSetting>()
    );

    return {
      tenant,
      tenantOrders: item.tenantOrders,
      exportConfig,
      sisMediaItemsByCategory,
      infotextsByTenantAlias,
      meteoInfosByTenantAlias,
      sisMediaSettingByTenantAlias,
    };
  }
}

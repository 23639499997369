import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { Status } from 'src/app/maps/domain/livedata/status.model';

export class StatusAdapter {
  static adapt(item: any[]): Status[] {
    if (!item?.length) {
      return [];
    }

    return item.map((e) => ({
      assetGuid: e.RowKey,
      assetStatus: e.Status ?? AssetStatus.NoStatus,
    }));
  }
}

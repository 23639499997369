import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { MapModule } from 'src/app/maps/map/map.module';
import { WindmonitorPage } from 'src/app/maps/windmonitor/windmonitor.page';

const routes: Routes = [
  {
    path: '',
    component: WindmonitorPage,
  },
];

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    IonicModule,
    MapModule,
    RouterModule.forChild(routes),
    TranslateModule,
  ],
  declarations: [WindmonitorPage],
})
export class WindmonitorModule {}

import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {
  constructor(private translateService: TranslateService, private alertCtrl: AlertController) {}

  presentAlert(confirmationText: string, confirmationButton: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      this.alertCtrl
        .create({
          header: await this.getTranslatedString(confirmationText),
          buttons: [
            {
              text: await this.getTranslatedString(confirmationButton),
              handler: (): void => {
                resolve(true);
              },
            },
            {
              text: await this.getTranslatedString('general.term.cancel'),
              role: 'cancel',
            },
          ],
          cssClass: 'sis-ion-alert',
          backdropDismiss: false,
        })
        .then((alert) => {
          alert.present();
        });
    });
  }

  private getTranslatedString(translateKey: string): Promise<string> {
    return firstValueFrom(this.translateService.get(translateKey));
  }
}

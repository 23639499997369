import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hoursMinutes',
})
export class HoursMinutesPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    const totalMinutes = Number.parseInt(value, 10);

    if (!totalMinutes) {
      return value;
    }

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours} h ${minutes} min`;
  }
}

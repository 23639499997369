import { OperatingInfoTenant } from 'src/app/operating-info/domain/operating-info-tenant.model';

export class OperatingInfoTenantAdapter {
  static adapt(items: any[]): OperatingInfoTenant[] {
    if (!items) {
      return null;
    }

    const tenants: OperatingInfoTenant[] = [];

    items.forEach((i: any) => {
      tenants.push({
        guid: i.guid,
        alias: i.alias,
        logo: i.logo,
        website: i.website,
        phone: i.phone,
        order: i.order,
      });
    });

    return tenants;
  }
}

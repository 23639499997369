import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

export const RedirectGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  const params = route.params;

  const tenantSisId = params['tenantSisId'] as string;
  const contentType = params['contentType'] as string;
  const contentLabel = params['contentLabel'] as string;

  location.href = `${environment.slideshowPlayerWebApp}/slideshow/${tenantSisId}/${contentType}/${contentLabel}`;

  return of(false);
};

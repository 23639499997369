import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BreakdownInfoPageModule } from 'src/app/breakdowninfo/breakdowninfo.module';
import { RedirectGuard } from 'src/app/core/guards/redirect.guard';
import { ErrorPageModule } from 'src/app/error/error.module';
import { HomePageModule } from 'src/app/home/home.module';
import { SisMapPageModule } from 'src/app/maps/sismap/sismap.module';
import { WindmonitorModule } from 'src/app/maps/windmonitor/windmonitor.module';
import { OperatingInfoPageModule } from 'src/app/operating-info/operating-info.module';
import { QuickstartPageModule } from 'src/app/quickstart/quickstart.module';
import { WebReportModule } from 'src/app/webreport/webreport.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => HomePageModule,
  },
  {
    path: ':sisId/sismap/:alias/:season',
    loadChildren: () => SisMapPageModule,
  },
  {
    path: ':sisId/windmonitor/:alias/:season',
    loadChildren: () => WindmonitorModule,
  },
  {
    path: 'quickstart',
    loadChildren: () => QuickstartPageModule,
  },
  {
    path: 'breakdowninfo/:breakdownInfoGuid',
    loadChildren: () => BreakdownInfoPageModule,
  },
  {
    path: 'slideshow/:tenantSisId/:contentLabel',
    redirectTo: 'slideshow/:tenantSisId/screen/:contentLabel',
    pathMatch: 'full',
  },
  {
    path: 'slideshow/:tenantSisId/:contentType/:contentLabel',
    canActivate: [RedirectGuard],
    loadChildren: () => HomePageModule,
  },
  {
    path: 'report/:urlName',
    loadChildren: () => WebReportModule,
  },
  {
    path: 'operatinginfo/:operatinginfoUrl',
    loadChildren: () => OperatingInfoPageModule,
  },
  {
    path: '**',
    loadChildren: () => ErrorPageModule,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

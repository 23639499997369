<div cdkDropList cdkDropListConnectedTo="mapList" class="element-container">
  <ion-row
    class="element-row"
    [matTooltip]="elementTooltip"
    [class.selected]="selected"
    [class.disabled]="item.disabled"
    [class.deleted]="item.deleted"
    [class.error]="item.hasError"
    [cdkDragData]="item.element"
    [cdkDragDisabled]="item.disabled || !draggable"
    (cdkDragDropped)="dragEnd($event)"
    cdkDrag
  >
    <ion-col class="element-icon" size="1"><div [innerHTML]="elementIcon"></div></ion-col>
    <ion-col class="element-hidden" size="1" *ngIf="item.hidden" matTooltip="Durch Layer-Filter versteckt">
      <ion-icon name="eye-off-outline"> </ion-icon>
    </ion-col>
    <ion-col class="element-label" size="1">
      <span
        #label
        [matTooltip]="elementLabel"
        [matTooltipDisabled]="elementLabelTooltipDisabled"
        [matTooltipShowDelay]="200"
        (mouseenter)="updateLabelTooltipDisabled(label)"
      >
        {{ elementLabel }}
      </span>
    </ion-col>
    <ion-col class="element-name"> <span *ngIf="item.changed">*</span> {{ elementName }} </ion-col>
    <ng-container *ngFor="let button of actionButtons">
      <ion-col *ngIf="button.isVisible?.(item)" class="element-actions" size="1">
        <button
          mat-icon-button
          [matTooltip]="button.tooltip"
          [matTooltipDisabled]="!button.tooltip"
          [matTooltipShowDelay]="200"
          (click)="emitButtonAction($event, button)"
        >
          <ion-icon [name]="button.icon"> </ion-icon>
        </button>
      </ion-col>
    </ng-container>
    <div *cdkDragPreview class="drag-preview" [innerHTML]="elementIcon"></div>
  </ion-row>
</div>

<ion-menu
  menuId="asset-sidepane-menu"
  contentId="asset-content"
  [ngClass]="this.className$ | async"
  type="overlay"
  (ionDidClose)="this.resetMenu()"
  *ngIf="this.bigScreenMode"
>
  <ion-spinner *ngIf="this.showSpinner"></ion-spinner>
  <sis-asset-sidepane-content *ngIf="!this.showSpinner"></sis-asset-sidepane-content>
</ion-menu>

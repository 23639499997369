import '@elfalem/leaflet-curve';

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Circle, Curve, curve, latLng, LayerGroup, LeafletMouseEvent } from 'leaflet';
import { filter, firstValueFrom, takeUntil } from 'rxjs';
import { Season } from 'src/app/core/domain/season.enum';
import { parseCurveCoords } from 'src/app/core/leaflet-helper/curve-coords';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { Lift } from 'src/app/maps/domain/masterdata/lift.model';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { MasterDataService } from 'src/app/maps/domain/masterdata/masterdata.service';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';
import { HoverService } from 'src/app/maps/map/hover.service';
import { LayerService } from 'src/app/maps/map/layer-selector/layer.service';
import { MapStateService } from 'src/app/maps/map/map-state.service';
import { CurveEndMarkers } from 'src/app/maps/map/style/curve-end-markers.model';
import { MapStyleService } from 'src/app/maps/map/style/map-style.service';
import { SisZoom } from 'src/app/maps/map/zoom.model';
import { ZoomService } from 'src/app/maps/map/zoom.service';

@Component({
  selector: 'sis-map-element-curve',
  template: '',
  styleUrls: ['./map-element-curve.component.scss'],
})
export class MapElementCurveComponent extends Unsubscriber implements OnInit {
  @Input() element: MapElement;
  @Input() layerGroup: LayerGroup;
  @Input() showStatus: boolean;
  @Input() editMode: boolean;

  private readonly clickable = '-clickable';
  private readonly highlight = '-highlight';

  private visibleCurve: Curve;
  private highlightCurve: Curve;
  private clickableCurve: Curve;
  private backgroundCurve: Curve;
  private isMarked = false;
  private bigScreenMode = false;

  private curveEndMarkers: CurveEndMarkers;

  constructor(
    private zoomService: ZoomService,
    private hoverService: HoverService,
    private layerService: LayerService,
    private mapStateService: MapStateService,
    private mapStyleService: MapStyleService,
    private changeDetector: ChangeDetectorRef,
    private masterDataService: MasterDataService,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.element?.path) {
      this.initCurveLayer();
      this.layerService.season$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.updateCurve();
      });
      this.mapStateService.elementUpdated$
        .pipe(
          filter((e) => e.guid === this.element.guid),
          takeUntil(this.onDestroy$)
        )
        .subscribe(() => this.updateCurve());
    }

    this.mapStateService.selectedElement$.pipe(takeUntil(this.onDestroy$)).subscribe(({ element }) => {
      if (element?.guid === this.element.guid) {
        this.isMarked = true;
      } else if (this.isMarked) {
        this.isMarked = false;
      }
      this.updateCurve();
    });

    this.screenSizeService.bigScreenMode$.pipe(takeUntil(this.onDestroy$)).subscribe((bigScreenMode) => {
      this.bigScreenMode = bigScreenMode;
    });
  }

  private async initCurveLayer(): Promise<void> {
    const coords = parseCurveCoords(this.element.path) ?? [];

    const color = await this.getPathColor(this.element.status);
    const className = `sis-${SisMapAssetCategory[this.element.category].toLowerCase()}-curve`;
    const pathWeight = this.element.pathWeight ?? this.element.defaultPathWeight;
    const dashArray = this.element.pathDashArray?.split(' ').map((x) => +x);
    const lineCap = this.element.pathBackgroundColor ? 'square' : 'round';

    this.visibleCurve = curve(coords, {
      interactive: true,
      color,
      weight: pathWeight,
      dashArray,
      className,
      pane: this.element.paneName,
      lineCap,
    });

    if (this.element.category === SisMapAssetCategory.Lift) {
      this.backgroundCurve = curve(coords, {
        color: this.element.pathBackgroundColor,
        weight: pathWeight,
        opacity: this.element.pathBackgroundColor ? 1 : 0,
        className,
        pane: this.element.paneName,
        lineCap: 'round',
      });
    }

    this.highlightCurve = curve(coords, {
      color,
      weight: pathWeight * 2.5,
      className: className + this.highlight,
      pane: this.element.paneName,
      lineCap: 'round',
    });

    this.clickableCurve = curve(coords, {
      interactive: true,
      bubblingMouseEvents: false,
      fill: false,
      weight: pathWeight * 2.5,
      opacity: 0,
      className: className + this.clickable,
      pane: this.element.paneName,
    });

    this.curveEndMarkers = await this.mapStyleService.createCurveEndMarkers(coords, color, this.element);

    this.addEventListeners();

    this.layerService.shownAssetGuids$.pipe(takeUntil(this.onDestroy$)).subscribe((shownAssetGuids) => {
      if (shownAssetGuids.includes(this.element.guid)) {
        this.showLayer();
      } else {
        this.hideLayer();
      }
    });

    this.zoomService.zoomChanged$.pipe(takeUntil(this.onDestroy$)).subscribe((zoom) => {
      this.zoom(zoom);
    });

    this.hoverService.onHover.pipe(takeUntil(this.onDestroy$)).subscribe((hoverData) => {
      if (this.layerGroup.hasLayer(this.visibleCurve)) {
        if (hoverData.guid === this.element.guid && hoverData.hover) {
          if (!this.element.disallowHighlight) {
            this.setStrokeOpacity(this.highlightCurve, 0.5);
            this.mapStyleService.setHighlight(this.curveEndMarkers, true);
          }
          if (!hoverData.iconHovered && !this.element.disallowPopUp && this.bigScreenMode) {
            this.createAndShowPopup(hoverData.hoverPosition);
          }
        } else {
          if (!this.isMarked) {
            this.setStrokeOpacity(this.highlightCurve, 0);
            this.mapStyleService.setHighlight(this.curveEndMarkers, false);
          }

          if (this.bigScreenMode) {
            this.visibleCurve.closePopup();
          }
        }
      }
    });
  }

  private async createAndShowPopup(position: MapPosition): Promise<void> {
    if (this.visibleCurve == null) {
      return;
    }

    const masterData = await firstValueFrom(this.masterDataService.masterData);
    const { popupContent, className } = await this.mapStyleService.createCurvePopupContent(
      this.element,
      masterData.labelInTooltip
    );

    this.visibleCurve.bindPopup(popupContent, {
      closeButton: false,
      offset: [0, 0],
      className: `sis-map-tooltip ${className}`,
      autoPan: false,
      maxWidth: 1000,
    });
    this.visibleCurve.openPopup(position != null ? latLng(position.x, position.y) : undefined);
  }

  private async updateCurve(): Promise<void> {
    if (this.visibleCurve) {
      const pathWeight = this.element.pathWeight ?? this.element.defaultPathWeight;
      const dashArray = this.element.pathDashArray?.split(' ').map((x) => +x);
      const lineCap = this.element.pathBackgroundColor ? 'square' : 'round';
      const color = await this.getPathColor(this.element.status);
      const coords = parseCurveCoords(this.element.path);
      const className = `sis-${SisMapAssetCategory[this.element.category].toLowerCase()}-curve`;

      if (coords == null) {
        return;
      }

      this.visibleCurve.setPath(coords);
      this.visibleCurve.setStyle({
        color,
        weight: pathWeight,
        dashArray,
        className,
        lineCap,
      });

      if (this.element.category === SisMapAssetCategory.Lift) {
        this.backgroundCurve.setPath(coords);
        this.backgroundCurve.setStyle({
          color: this.element.pathBackgroundColor,
          weight: pathWeight,
          opacity: this.element.pathBackgroundColor ? 1 : 0,
          className,
          lineCap,
        });
      }

      this.highlightCurve.setPath(coords);
      this.highlightCurve.setStyle({
        color,
        weight: pathWeight * 2.5,
        opacity: (!this.element.disallowHighlight || this.editMode) && this.isMarked ? 0.5 : 0,
        className: className + this.highlight,
        lineCap: 'round',
      });

      this.clickableCurve.setPath(coords);
      this.clickableCurve.setStyle({
        weight: pathWeight * 2.5,
        className: className + this.clickable,
      });

      await this.mapStyleService.updateCurveEndMarkers(coords, this.curveEndMarkers, color, this.element);
      await this.mapStyleService.setHighlight(
        this.curveEndMarkers,
        (!this.element.disallowHighlight || this.editMode) && this.isMarked
      );

      const zoom = await firstValueFrom(this.zoomService.zoomChanged$);
      await this.zoom(zoom);

      if (this.element.status === AssetStatus.Open) {
        this.backgroundCurve?.bringToFront();
        this.highlightCurve.bringToFront();
        this.visibleCurve.bringToFront();
        this.clickableCurve.bringToFront();
        (this.curveEndMarkers.visible[0] as Circle)?.bringToFront();
        (this.curveEndMarkers.visible[1] as Circle)?.bringToFront();
      }

      if (
        this.element.status === AssetStatus.NoStatus ||
        this.element.status === AssetStatus.Closed ||
        this.element.status === AssetStatus.Disabled
      ) {
        this.clickableCurve.bringToBack();
        this.visibleCurve.bringToBack();
        this.highlightCurve.bringToBack();
        this.backgroundCurve?.bringToBack();
        (this.curveEndMarkers.visible[0] as Circle)?.bringToBack();
        (this.curveEndMarkers.visible[1] as Circle)?.bringToBack();
      }
    }
  }

  private async getPathColor(status: AssetStatus): Promise<string> {
    if (this.element instanceof Lift) {
      const liftColor = await this.mapStyleService.getLiftColor(
        this.showStatus ? this.element.status : AssetStatus.Disabled,
        this.element
      );
      return liftColor.statusColor;
    }

    let pathColor: any = this.element.pathColor ?? this.element.defaultPathColor;
    const colorArr = pathColor.split(',');
    if (colorArr.length > 1) {
      pathColor = { summer: colorArr[0], winter: colorArr[1] };
    }

    if (typeof pathColor === 'object') {
      const season = await firstValueFrom(this.layerService.season$);
      switch (season) {
        case Season.Summer:
          pathColor = pathColor.summer;
          break;
        case Season.Winter:
          pathColor = pathColor.winter;
          break;
        default:
          pathColor = pathColor.summer;
      }
    }

    return status === AssetStatus.Open || status === AssetStatus.NoStatus || this.isMarked ? pathColor : '#808080cc';
  }

  private addEventListeners(): void {
    this.clickableCurve.on('click', (event: LeafletMouseEvent) => {
      this.hoverService.hover({
        guid: this.element.guid,
        hover: false,
        iconHovered: false,
        hoverPosition: { x: event.latlng.lat, y: event.latlng.lng },
      });
      this.mapStateService.clickElement(this.element, null);
    });

    this.clickableCurve.on('mouseover', (event: LeafletMouseEvent) => {
      this.hoverService.hover({
        guid: this.element.guid,
        hover: true,
        iconHovered: false,
        hoverPosition: { x: event.latlng.lat, y: event.latlng.lng },
      });
    });

    this.clickableCurve.on('mouseout', () => {
      this.hoverService.hover({ guid: this.element.guid, hover: false, iconHovered: false });
    });
  }

  private async zoom(zoom: SisZoom): Promise<void> {
    if (!zoom) {
      return;
    }

    const scaling = Math.max(zoom.iconZoom, 1);
    const pathWeight = this.element.pathWeight ?? this.element.defaultPathWeight;
    const width = pathWeight * scaling;
    const dashArray = this.element.pathDashArray?.split(' ').map((x) => +x * scaling);

    if (this.curveExists(this.visibleCurve)) {
      this.setStrokeWidth(this.visibleCurve, width);
      this.setStrokeDashArray(this.visibleCurve, dashArray);
      this.displayCurve(this.visibleCurve);
      await this.mapStyleService.zoomCurveEndMarkers(this.curveEndMarkers, scaling);
    }
    if (this.curveExists(this.backgroundCurve)) {
      this.setStrokeWidth(this.backgroundCurve, width);
      this.displayCurve(this.visibleCurve);
    }
    if (this.curveExists(this.highlightCurve)) {
      this.setStrokeWidth(this.highlightCurve, width + 5 * scaling);
      this.displayCurve(this.highlightCurve);
    }
    if (this.curveExists(this.clickableCurve)) {
      this.setStrokeWidth(this.clickableCurve, width + 16);
      this.displayCurve(this.clickableCurve);
    }

    this.changeDetector.detectChanges();
  }

  private curveExists(curveElement: Curve): boolean {
    return curveElement && curveElement.getElement() != null;
  }

  private setStrokeWidth(curveElement: Curve, width: number): void {
    curveElement.getElement().setAttribute('stroke-width', width.toString());
  }

  private setStrokeOpacity(curveElement: Curve, opacity: number): void {
    curveElement.getElement().setAttribute('stroke-opacity', opacity.toString());
  }

  private setStrokeDashArray(curveElement: Curve, dashArray: number[]): void {
    curveElement.getElement().setAttribute('stroke-dasharray', dashArray?.join(' '));
  }

  private displayCurve(curveElement: any): void {
    curveElement.getElement().style.cursor =
      this.editMode ||
      (!this.hoverService.hoverDisabled && !(this.element.disallowHighlight && this.element.disallowPopUp))
        ? 'pointer'
        : 'grab';
  }

  private showLayer(): void {
    if (!this.layerGroup.hasLayer(this.visibleCurve)) {
      this.curveEndMarkers.highlight.forEach((o) => this.layerGroup.addLayer(o));
      this.curveEndMarkers.visible.forEach((o) => this.layerGroup.addLayer(o));
      if (this.backgroundCurve) {
        this.layerGroup.addLayer(this.backgroundCurve);
      }
      this.layerGroup.addLayer(this.visibleCurve);
      this.layerGroup.addLayer(this.highlightCurve);
      this.layerGroup.addLayer(this.clickableCurve);
      this.updateCurve();
    }
  }

  private hideLayer(): void {
    if (this.layerGroup.hasLayer(this.visibleCurve)) {
      this.layerGroup.removeLayer(this.highlightCurve);
      this.layerGroup.removeLayer(this.visibleCurve);
      if (this.backgroundCurve) {
        this.layerGroup.removeLayer(this.backgroundCurve);
      }
      this.layerGroup.removeLayer(this.clickableCurve);
      this.curveEndMarkers.highlight.forEach((o) => this.layerGroup.removeLayer(o));
      this.curveEndMarkers.visible.forEach((o) => this.layerGroup.removeLayer(o));
    }
  }
}

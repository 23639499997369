import { WritablePart } from 'src/app/core/utils/type-helper';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';

export class Place implements MapElement {
  static readonly defaultFontSize = 10;
  static readonly defaultFontFamily = 'Helvetica, Arial, sans-serif';
  static readonly defaultBorderRadius = null;
  static readonly defaultTextColor = '#000000';
  static readonly defaultBorder = null;
  static readonly defaultBackgroundColor = null;
  static readonly defaultTextOutline = null;
  static readonly defaultPadding = '2px 6px 2px 6px';
  static readonly empty: Place = new Place({ guid: null });

  readonly category = SisMapAssetCategory.Place;

  guid: string;
  name: string;
  type: string;
  fontFamily: string;
  disallowPopUp = true;
  disallowHighlight = true;
  iconAlwaysOnTop = true;
  iconPositions: MapPosition[];
  iconDisableBaseZoom = true;
  iconWidth = null;
  iconHeight = null;
  padding: string;
  paneName = 'place';

  get iconHtml(): string {
    const content =
      this.altitude && this.altitude !== ''
        ? this.name + '<br/><span style="font-size: 0.9em">' + this.altitude + '</span>'
        : this.name;
    const backgroundColor = this.backgroundColor ?? Place.defaultBackgroundColor;
    const textColor = this.textColor ?? Place.defaultTextColor;
    const fontSize = this.fontSize ?? Place.defaultFontSize;
    const fontFamily = this.fontFamily ?? Place.defaultFontFamily;
    const border = this.border ?? Place.defaultBorder;
    const borderRadius = this.borderRadius ?? Place.defaultBorderRadius;
    const padding = this.padding ?? Place.defaultPadding;

    // -webkit-text-stroke is supported by all browsers we support and allows to draw an outline of a text. However the outline is drawn
    // after the browser has drawn the fill, so it looks ugly. In Firefox and Safari you can use 'paint-order: stroke fill;' to fix this behavior
    // but chrome only supports this property on svg text elements, not on normal HTML text. So in order to draw an outline we have to
    // resort to a hack and draw the outline explicitly behind the text. This, however, causes Firefox to behave weird with text positioning with
    // some fonts but this can be mitigated by also applying a text-stroke to the front text but with transparent color.
    const textOutline = this.textOutline != null ? `-webkit-text-stroke: ${this.textOutline};` : '';

    return `<div style="text-align:center;padding:${padding};line-height:1.2;white-space:nowrap;cursor:grab;display:grid;color:${textColor};font-family:${fontFamily};font-size:${fontSize}pt;${
      border != null ? `border:${border};` : ''
    }${borderRadius != null ? `border-radius:${borderRadius}px;` : ''}${
      backgroundColor != null ? `background-color:${backgroundColor};` : ''
    }">
      <div style="grid-area: 1 / 1;color:transparent;filter:blur(1px);${textOutline}">${content}</div>
      <div style="grid-area: 1 / 1;-webkit-text-stroke: transparent 1px;filter:blur(0px);">${content}</div>
    </div>`;
  }

  altitude: string | null;
  backgroundColor: string | null;
  textColor: string | null;
  fontSize: number | null;
  border: string | null;
  borderRadius: number | null;
  textOutline: string | null;

  constructor(init?: WritablePart<Place> | { guid: null }) {
    Object.assign(this, init);
  }
}

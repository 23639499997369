import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { from, map } from 'rxjs';
import { Webcam } from 'src/app/maps/domain/masterdata/webcam.model';
import { MapStyleService } from 'src/app/maps/map/style/map-style.service';

@Component({
  selector: 'sis-webcam-modal',
  templateUrl: './webcam-modal.component.html',
  styleUrls: ['./webcam-modal.component.scss'],
})
export class WebcamModalComponent implements OnInit {
  @Input() readonly webcam: Webcam;

  webcamUrl: SafeResourceUrl;
  useIFrame: boolean;
  showSpinner = true;

  readonly className$ = from(this.mapStyleService.getStyleName()).pipe(map((name) => `sis-webcam-modal-${name}`));

  constructor(
    private modalCtrl: ModalController,
    private sanitizer: DomSanitizer,
    private mapStyleService: MapStyleService
  ) {}

  ngOnInit(): void {
    this.useIFrame = this.webcam.type === Webcam.iframe;
    this.webcamUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${this.webcam.url}?${Math.floor(Math.random() * 10000).toString()}`
    );
  }

  closeModal(): void {
    this.modalCtrl.dismiss();
  }

  hideSpinner(): void {
    this.showSpinner = false;
  }
}

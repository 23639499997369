import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { Observable, takeUntil } from 'rxjs';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { SisMediaSetting } from 'src/app/webreport/domain/sismedia-setting.model';
import { WebReportConfig } from 'src/app/webreport/domain/webreport-config.model';
import { WebReportMeteoInfoItem } from 'src/app/webreport/domain/webreport-meteo-info-item.model';

@Component({
  selector: 'sis-webreport-meteo-mobile-item',
  templateUrl: './webreport-meteo-mobile-item.component.html',
  styleUrls: ['./webreport-meteo-mobile-item.component.scss'],
})
export class WebreportMeteoMobileItemComponent extends Unsubscriber implements OnInit {
  readonly slopeCondition: Array<{ value: number; translationString: string }> = [
    { value: 800, translationString: 'webreport.phrase.condition.noInfo' },
    { value: 801, translationString: 'webreport.phrase.condition.good' },
    { value: 802, translationString: 'webreport.phrase.condition.goodFair' },
    { value: 803, translationString: 'webreport.phrase.condition.fairGood' },
    { value: 804, translationString: 'webreport.phrase.condition.fair' },
    { value: 805, translationString: 'webreport.phrase.condition.closed' },
    { value: 806, translationString: 'webreport.phrase.condition.eos' },
  ];

  readonly snowCondition: Array<{ value: number; translationString: string }> = [
    { value: 900, translationString: 'webreport.phrase.condition.noInfo' },
    { value: 901, translationString: 'webreport.phrase.condition.powder' },
    { value: 905, translationString: 'webreport.phrase.condition.hard' },
    { value: 909, translationString: 'webreport.phrase.condition.sulz' },
    { value: 913, translationString: 'webreport.phrase.condition.wet' },
  ];

  readonly avalancheLevel: Array<{ value: number; translationString: string }> = [
    { value: 0, translationString: 'webreport.term.dangerlevel.noInfo' },
    { value: 1, translationString: 'webreport.term.dangerlevel.low' },
    { value: 2, translationString: 'webreport.term.dangerlevel.moderate' },
    { value: 3, translationString: 'webreport.term.dangerlevel.considerable' },
    { value: 4, translationString: 'webreport.term.dangerlevel.high' },
    { value: 5, translationString: 'webreport.term.dangerlevel.veryHigh' },
  ];

  @Input() meteoInfos$: Observable<Map<string, WebReportMeteoInfoItem[]>>;
  @Input() config: WebReportConfig;
  @Input() mobileStyleTenant: boolean;
  @Input() tenantAlias: string;
  @Input() sisMediaSettingByTenantAlias: Map<string, SisMediaSetting>;

  weatherStations: WebReportMeteoInfoItem[];

  showWeather: boolean;
  showTemperature: boolean;
  showFreshSnow: boolean;
  showSnowDepth: boolean;
  showAvalancheDangerLevel: boolean;
  showSnowCondition: boolean;
  showSlopeCondition: boolean;
  showWindSpeed: boolean;
  showLatestSnowfall: boolean;

  ngOnInit(): void {
    this.meteoInfos$.pipe(takeUntil(this.onDestroy$)).subscribe((meteoInfos) => {
      this.weatherStations = meteoInfos.get(this.tenantAlias);
      this.updateMeteoReportSettings();
    });
  }

  private updateMeteoReportSettings(): void {
    const sisMediaSettings = this.sisMediaSettingByTenantAlias.get(this.tenantAlias);
    const isWinterSeason =
      sisMediaSettings == null ||
      moment().isBetween(
        moment(sisMediaSettings.winterSeasonStartDate),
        moment(sisMediaSettings.winterSeasonEndDate),
        null,
        '[]'
      );

    this.showWeather = this.config?.meteoDatas?.includes('weather');
    this.showTemperature = this.config?.meteoDatas?.includes('temperature');
    this.showFreshSnow = isWinterSeason && this.config?.meteoDatas?.includes('freshSnow');
    this.showSnowDepth = isWinterSeason && this.config?.meteoDatas?.includes('snowDepth');
    this.showAvalancheDangerLevel = isWinterSeason && this.config?.meteoDatas?.includes('avalancheDangerLevel');
    this.showSnowCondition = isWinterSeason && this.config?.meteoDatas?.includes('snowCondition');
    this.showSlopeCondition = isWinterSeason && this.config?.meteoDatas?.includes('slopeCondition');
    this.showWindSpeed = this.config?.meteoDatas?.includes('windSpeed');
    this.showLatestSnowfall = isWinterSeason && this.config?.meteoDatas?.includes('latestSnowfall');
  }

  public getAvalancheDangerLevelTranslationString(dangerLevel: number): string {
    return this.avalancheLevel.find((al) => al.value === dangerLevel).translationString;
  }

  public getSnowConditionTranslationString(snowConditionId: number): string {
    return this.snowCondition.find((al) => al.value === snowConditionId).translationString;
  }

  public getSlopeConditionTranslationString(slopeConditionId: number): string {
    return this.slopeCondition.find((al) => al.value === slopeConditionId).translationString;
  }

  trackByGuid(_index: number, item: WebReportMeteoInfoItem): string {
    return item.guid;
  }
}

import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';

@Component({
  selector: 'sis-sismap-sidepane-autocomplete-selector',
  templateUrl: './autocomplete-selector.component.html',
  styleUrls: ['./autocomplete-selector.component.scss'],
})
export class AutocompleteSelectorComponent<T> extends Unsubscriber implements OnChanges, AfterViewInit {
  private _canditates: Array<{ value: T; formatted: string }>;
  @ViewChild(MatAutocompleteTrigger) private autocompleteTrigger: MatAutocompleteTrigger;

  @Input() candidates: T[];
  @Input() placeholder = '';
  @Input() disabled = false;

  @Input() compareFn?: (a: T, b: T) => number;
  @Input() formatFn: (element: T) => string;
  @Input() filterFn: (filterText: string, element: T) => boolean;

  @Output() selectedElement = new EventEmitter<T | null>();

  filterValue = '';

  filteredCandidates: Array<{ value: T; formatted: string }> = [];

  ngAfterViewInit(): void {
    if (this.formatFn == null || this.filterFn == null) {
      throw new Error('The input fields formatFn and filterFn are required');
    }
  }

  ngOnChanges(): void {
    const sortedCandidates = (this.compareFn != null ? this.candidates?.sort(this.compareFn) : this.candidates) ?? [];
    this._canditates = sortedCandidates.map((c) => ({ formatted: this.formatFn(c), value: c }));
    this.filterElements(this.filterValue);
  }

  updateSelection(value: string | T): void {
    if (typeof value === 'string') {
      this.autocompleteTrigger.autocomplete.options.forEach((o) => o.deselect());
      this.filterElements(value);
      this.filterValue = value;
      this.selectedElement.emit(null);
    }
  }

  selectText(event: FocusEvent): void {
    setTimeout(() => {
      if (event.target instanceof HTMLInputElement) {
        event.target.select();
      }
    });
  }

  formatInputValue(value: T): string {
    if (value == null) {
      return '';
    }
    if (typeof value === 'string') {
      return value;
    }
    return this.formatFn(value);
  }

  emitSelectedOption(event: { option: MatOption; source: MatOption }): void {
    if (event.option.selected) {
      this.selectedElement.emit(event.option.value);
    }
  }

  private filterElements(filterText: string): void {
    filterText = filterText.toLocaleLowerCase();
    this.filteredCandidates = this._canditates.filter((candidate) => this.filterFn(filterText, candidate.value));
  }
}

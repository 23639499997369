import { OperatingInfoLink } from 'src/app/operating-info/domain/operting-info-link.model';

export class OperatingInfoLinkAdapter {
  static adapt(items: any[]): OperatingInfoLink[] {
    if (!items) {
      return null;
    }

    const links: OperatingInfoLink[] = [];

    items.forEach((i: any) => {
      links.push({
        url: i.url,
        iconName: i.iconName,
        descriptionDe: i.descriptionDe,
        descriptionEn: i.descriptionEn,
        descriptionFr: i.descriptionFr,
        descriptionIt: i.descriptionFr,
        displayOrder: i.displayOrder,
      });
    });

    return links;
  }
}

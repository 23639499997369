import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, timer } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { NavigatorService } from 'src/app/core/navigator/navigator.service';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { OperatingInfoAdapter } from 'src/app/operating-info/domain/operating-info.adapter';
import { OperatingInfo } from 'src/app/operating-info/domain/operating-info.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OperatingInfoService extends Unsubscriber {
  static readonly urlPath = '/api/operatingInfo/';

  constructor(private http: HttpClient, private navigator: NavigatorService) {
    super();
  }

  periodicallyRequestOperatingInfoData(period: number, operatingInfoGuid: string): Observable<OperatingInfo> {
    if (!operatingInfoGuid || operatingInfoGuid.length === 0) {
      this.navigator.navigateToErrorPage();
      return;
    }

    const url = `${environment.baseUrlApi}${OperatingInfoService.urlPath}${operatingInfoGuid}?`;

    return timer(0, period).pipe(
      switchMap(() =>
        this.http.get(`${url}?${Math.random() * 1000}`).pipe(
          catchError(() => {
            this.navigator.navigateToErrorPage();
            return EMPTY;
          })
        )
      ),
      map((data: any) => OperatingInfoAdapter.adapt(data)),
      tap((operatingInfo) => {
        if (!operatingInfo) {
          this.navigator.navigateToErrorPage();
        }
      })
    );
  }
}

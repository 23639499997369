<ion-row
  class="sis-tenant-title-small"
  [ngStyle]="
    this.mobileStyleTenant
      ? {
          color: this.config?.tenantTitleColor,
          background: this.config?.tenantColor
        }
      : {}
  "
>
  <ion-col>
    {{ this.tenantAlias }}
  </ion-col>
</ion-row>
<sis-sismedia-item
  *ngFor="let item of this.items; trackBy: trackByItemGuid"
  [item]="item"
  [bigScreenMode]="false"
  [config]="this.config"
  [mobileStyleTenant]="this.mobileStyleTenant"
  [longestLabelLength]="this.longestLabelLength"
></sis-sismedia-item>

import { WebReportInfotext } from 'src/app/webreport/domain/webreport-infotext.model';

export class WebReportInfotextItemAdapter {
  static adapt(items: any): WebReportInfotext {
    const result: WebReportInfotext = {
      textDe: items.textDe,
      textEn: items.textEn,
      textFr: items.textFr,
      textIt: items.textIt,
      timestamp: items.timestamp,
    };
    return result;
  }
}

import { Season } from 'src/app/core/domain/season.enum';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { Wind } from 'src/app/maps/domain/masterdata/wind.model';

export class WindAdapter {
  static adapt(item: any): Wind {
    if (!item) {
      return Wind.empty;
    }

    const defaultIconSize = item.defaultIconSize ? item.defaultIconSize : Wind.defaultIconSize;

    let positions: MapPosition[] = [];
    if (item.iconPosition && item.iconPosition !== '') {
      let iconPositions: MapPosition[] | MapPosition = JSON.parse(item.iconPosition);
      if (!Array.isArray(iconPositions)) {
        iconPositions = [iconPositions];
      }
      positions = iconPositions;
    }

    return new Wind({
      guid: item.guid,
      name: item.alias,
      meteoStationSisId: item.meteoStationSisId,
      type: item.type,
      season: Season.SummerAndWinter,
      directionOffsetPanoMap: item.directionOffsetPanoMap,
      directionOffsetNorth: item.directionOffsetNorth,
      iconPositions: positions,
      iconWidth: defaultIconSize[0],
      iconHeight: defaultIconSize[1],
      iconUseMeteoData: true,
    });
  }

  static adaptForApi(wind: Wind): any {
    return {
      guid: wind.guid,
      assetCategory: wind.category,
      iconPosition: JSON.stringify(wind.iconPositions),
      alias: wind.name,
      // directionOffsetPanoMap: wind.directionOffsetPanoMap,
      // directionOffsetNorth: wind.directionOffsetNorth,
    };
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable } from 'rxjs';
import { Season } from 'src/app/core/domain/season.enum';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { UserMessage } from 'src/app/core/user-message/user-message.model';
import { UserMessageService } from 'src/app/core/user-message/user-message.service';
import { UserMessageColor } from 'src/app/core/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/core/user-message/user-message-icon';
import { CustomIcon } from 'src/app/maps/domain/masterdata/custom-icon.model';
import { CustomPath } from 'src/app/maps/domain/masterdata/custom-path.model';
import { Gastro } from 'src/app/maps/domain/masterdata/gastro.model';
import { Lift } from 'src/app/maps/domain/masterdata/lift.model';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MasterDataService } from 'src/app/maps/domain/masterdata/masterdata.service';
import { Place } from 'src/app/maps/domain/masterdata/place.model';
import { POI } from 'src/app/maps/domain/masterdata/poi.model';
import { Slope } from 'src/app/maps/domain/masterdata/slope.model';
import { Tenant } from 'src/app/maps/domain/masterdata/tenant.model';
import { Trail } from 'src/app/maps/domain/masterdata/trail.model';
import { Webcam } from 'src/app/maps/domain/masterdata/webcam.model';
import { Wind } from 'src/app/maps/domain/masterdata/wind.model';
import { LayerService } from 'src/app/maps/map/layer-selector/layer.service';
import { Category } from 'src/app/maps/map/layer-selector/layer-selector-item/layer-selector-item.model';
import { MapStateService } from 'src/app/maps/map/map-state.service';
import { AssetListItem } from 'src/app/maps/map/sidepane/add-asset-tab/asset-list/asset-list-item/asset-list-item.model';
import { AssetListItemButton } from 'src/app/maps/map/sidepane/add-asset-tab/asset-list/asset-list-item/asset-list-item-button.model';
import { TenantService } from 'src/app/maps/map/sidepane/add-asset-tab/tenant.service';
import { AssetEditorService } from 'src/app/maps/map/sidepane/asset-editor-tab/asset-editor.service';

@Component({
  selector: 'sis-sismap-sidepane-add-asset-tab',
  templateUrl: './add-asset-tab.component.html',
  styleUrls: ['./add-asset-tab.component.scss'],
})
export class AddAssetTabComponent extends Unsubscriber implements OnInit {
  private readonly masterDataElements$: Observable<MapElement[]> = this.masterDataService.masterData.pipe(
    map((masterData) =>
      this.sortElementsByName(masterData.lifts)
        .concat(this.sortElementsByName(masterData.trails))
        .concat(this.sortElementsByName(masterData.slopes))
        .concat(this.sortElementsByName(masterData.gastros))
        .concat(this.sortElementsByName(masterData.pois))
        .concat(this.sortElementsByName(masterData.webcams))
        .concat(this.sortElementsByName(masterData.customIcons))
        .concat(this.sortElementsByName(masterData.customPaths))
        .concat(this.sortElementsByName(masterData.places))
        .concat(this.sortElementsByName(masterData.winds))
    )
  );

  private selectedTenant: Tenant | null = null;

  tenants: Tenant[] = [];
  readonly categoryFilter$ = new BehaviorSubject<Category | null>(null);
  readonly allTenantAssets$ = new BehaviorSubject<MapElement[]>([]);
  tenantAssetsLoading = false;
  readonly tenantAssets$: Observable<AssetListItem[]> = combineLatest([
    this.allTenantAssets$,
    this.layerService.season$,
    this.categoryFilter$,
    this.masterDataElements$,
  ]).pipe(
    map(([assets, season, category, mapElements]) =>
      assets
        .filter(
          (el) =>
            (el.season === season || el.season === Season.SummerAndWinter || el.season == null) &&
            (category == null || category.types.includes(el.type))
        )
        .sort((a1, a2) => (a1.category === a2.category ? a1.name.localeCompare(a2.name) : a1.category - a2.category))
        .map((asset) => ({
          element: asset,
          disabled: mapElements.some((el) => el.guid === asset.guid),
        }))
    )
  );

  readonly selectedElement$ = this.mapStateService.selectedElement$.pipe(map(({ element }) => element));

  readonly mapElements$: Observable<AssetListItem[]> = combineLatest([
    this.masterDataElements$,
    this.layerService.shownAssetGuids$,
    this.layerService.season$,
    this.assetEditorService.unsavedAssets$,
    this.assetEditorService.removedAssets$,
    this.assetEditorService.assetsWithError$,
  ]).pipe(
    map(([mapElements, shownAssetGuids, season, unsavedElements, removedElements, assetsWithError]) => {
      const unsavedElementsOfThisSeason = [...unsavedElements.values()];
      const assetsWithErrorOfThisSeason = [...assetsWithError.values()];
      const removedElementsOfThisSeason = [...removedElements.values()].filter(
        (el) =>
          el.element.season === season || el.element.season === Season.SummerAndWinter || el.element.season == null
      );
      return mapElements
        .filter((el) => el.season === season || el.season === Season.SummerAndWinter || el.season == null)
        .map<AssetListItem>((asset) => ({
          element: asset,
          changed: unsavedElementsOfThisSeason.some((el) => el.guid === asset.guid),
          hasError: assetsWithErrorOfThisSeason.some((el) => el.guid === asset.guid),
          hidden: !shownAssetGuids.includes(asset.guid),
        }))
        .concat(removedElementsOfThisSeason.map((deleted) => ({ element: deleted.element, deleted: true })));
    })
  );

  readonly changedElements$ = this.assetEditorService.unsavedAssets$.pipe(map((e) => [...e.values()]));

  readonly currentElementListButtons: AssetListItemButton[] = [
    {
      name: 'reset',
      icon: 'return-up-back-outline',
      tooltip: 'Asset zurücksetzen',
      isVisible: (item): boolean => !item?.deleted && item?.changed,
    },
    {
      name: 'restore',
      icon: 'return-up-back-outline',
      tooltip: 'Asset wiederherstellen',
      isVisible: (item): boolean => item?.deleted === true,
    },
    {
      name: 'locate',
      icon: 'contract-outline',
      tooltip: 'Asset auf der Karte zeigen',
      isVisible: (item): boolean => !item?.deleted,
    },
    {
      name: 'edit',
      icon: 'create-outline',
      tooltip: 'Asset bearbeiten',
      isVisible: (item): boolean => !item?.deleted,
    },
  ];

  readonly newElementListButtons: AssetListItemButton[] = [
    {
      name: 'add',
      icon: 'add-outline',
      tooltip: 'Asset hinzufügen',
      isVisible: (item): boolean => !item?.disabled,
    },
  ];

  @Output() changeToEditTab = new EventEmitter<void>();

  constructor(
    private tenantService: TenantService,
    public layerService: LayerService,
    private masterDataService: MasterDataService,
    private mapStateService: MapStateService,
    private userMessageService: UserMessageService,
    private assetEditorService: AssetEditorService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadAvailableTenants();
  }

  handleCurrentAssetActionButtonClicked({ item, button }: { item: AssetListItem; button: AssetListItemButton }): void {
    const { element } = item;

    switch (button.name) {
      case 'restore':
        this.assetEditorService.restoreAsset(element);
        break;
      case 'locate':
        this.mapStateService.centerElementIcon(element, 0);
        this.mapStateService.highlightElement(element, null);
        break;
      case 'edit':
        this.mapStateService.centerElementIcon(element, 0);
        this.mapStateService.selectElement(element, null, true);
        this.changeToEditTab.emit();
        break;
      case 'reset':
        const originalAsset = JSON.parse(
          JSON.stringify(this.assetEditorService.editedAssetOriginals.get(element.guid))
        );
        Object.keys(element).forEach((key) => {
          element[key] = originalAsset[key];
        });
        this.mapStateService.updateMapElement(element);
        this.assetEditorService.removeUnsavedAsset(element);
        break;
      default:
        break;
    }
  }

  handleNewAssetActionButtonClicked({ item, button }: { item: AssetListItem; button: AssetListItemButton }): void {
    if (button.name === 'add') {
      const { element } = item;
      this.assetEditorService.addAsset(element, { x: 100, y: 100 });
      this.mapStateService.centerElementIcon(element, 0);
      this.changeToEditTab.emit();
    }
  }

  itemDraggedToMap(item: MapElement): void {}

  loadAvailableTenants(): void {
    this.tenantService.getTenants().subscribe({
      next: (tenants) => (this.tenants = tenants),
      error: async () => {
        console.error('Failed loading tenants');
        this.tenants = [];
        const userMessage = new UserMessage({
          color: UserMessageColor.red,
          durationMs: 4000,
          icon: UserMessageIcon.failed,
          message: 'user.message.tenantLoadingFailed',
          position: 'top',
        });

        const toast = await this.userMessageService.showSingleToast(userMessage);
        await toast.present();
      },
    });
  }

  setSelectedTenant(tenant: Tenant | null): void {
    this.selectedTenant = tenant;
    this.getAssetsForTenant(tenant);
  }

  getAssetsForTenant(tenant: Tenant | null): void {
    if (tenant != null) {
      this.tenantAssetsLoading = true;
      this.tenantService.getAssetForTenant(tenant).subscribe({
        next: (elements) => {
          this.allTenantAssets$.next(elements);
          this.tenantAssetsLoading = false;
        },
        error: () => {
          this.allTenantAssets$.next([]);
          this.tenantAssetsLoading = false;
        },
      });
    }
  }

  refreshTenantAssets(): void {
    this.getAssetsForTenant(this.selectedTenant);
  }

  addElement(element: MapElement): void {
    element.iconPositions.push({ x: 200, y: 200 });
    this.masterDataService.addMapElement(element);
  }

  setCategoryFilter(category: Category): void {
    this.categoryFilter$.next(category);
  }

  selectExistingActiveElement(item: AssetListItem): void {
    if (!item.disabled && !item.deleted) {
      this.selectMapElement(item.element);
    }
  }

  async selectDisabledElement(item: AssetListItem): Promise<void> {
    if (item.disabled) {
      // The element we get here is not the same instance as already in masterData so we get it
      const masterData = await firstValueFrom(this.masterDataService.masterData);
      let elementList: MapElement[] = [];
      if (item.element instanceof Lift) {
        elementList = masterData.lifts;
      } else if (item.element instanceof CustomIcon) {
        elementList = masterData.customIcons;
      } else if (item.element instanceof CustomPath) {
        elementList = masterData.customPaths;
      } else if (item.element instanceof Gastro) {
        elementList = masterData.gastros;
      } else if (item.element instanceof Place) {
        elementList = masterData.places;
      } else if (item.element instanceof POI) {
        elementList = masterData.pois;
      } else if (item.element instanceof Slope) {
        elementList = masterData.slopes;
      } else if (item.element instanceof Trail) {
        elementList = masterData.trails;
      } else if (item.element instanceof Webcam) {
        elementList = masterData.webcams;
      } else if (item.element instanceof Wind) {
        elementList = masterData.winds;
      }
      const element = elementList.find((e) => e.guid === item.element.guid) ?? item.element;
      this.selectMapElement(element);
    }
  }

  compareTenants(a: Tenant, b: Tenant): number {
    return a.alias.localeCompare(b.alias);
  }

  filterTenants(filterText: string, tenant: Tenant): boolean {
    return (
      tenant.alias.toLocaleLowerCase().includes(filterText) || tenant.sisId.toLocaleLowerCase().includes(filterText)
    );
  }

  formatTenants(tenant: Tenant): string {
    return `${tenant.alias} (${tenant.sisId})`;
  }

  compareCategories(a: Category, b: Category): number {
    return a.name.localeCompare(b.name);
  }

  filterCategories(filterText: string, category: Category): boolean {
    return category.name.toLocaleLowerCase().includes(filterText);
  }

  formatCategory(category: Category): string {
    // TODO: Maybe translate name so they are easier and more intuitive to find?
    return `${category.name}`;
  }

  private selectMapElement(element: MapElement): void {
    this.mapStateService.centerElementIcon(element, 0);
    this.mapStateService.selectElement(element, null, true);
  }

  private sortElementsByName(elements: MapElement[]): MapElement[] {
    if (elements == null) {
      return [];
    }
    return [...elements].sort((e1, e2) => (e1.name == null ? -1 : e1.name.localeCompare(e2.name)));
  }
}

import { Injectable } from '@angular/core';
import { CurvePathData } from '@elfalem/leaflet-curve';
import { Marker } from 'leaflet';
import { firstValueFrom, map, Observable, shareReplay } from 'rxjs';
import { IconHelper } from 'src/app/core/leaflet-helper/icon-helper';
import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MasterDataService } from 'src/app/maps/domain/masterdata/masterdata.service';
import { CurveEndMarkers } from 'src/app/maps/map/style/curve-end-markers.model';
import { IconColor } from 'src/app/maps/map/style/icon-color.model';
import { MapStyleAndermatt } from 'src/app/maps/map/style/styles/andermatt.style';
import { MapStyle } from 'src/app/maps/map/style/styles/map-style.base';
import { MapStyleSisag } from 'src/app/maps/map/style/styles/sisag.style';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MapStyleService {
  private readonly styleMap = new Map<string, MapStyle>([
    ['andermatt', new MapStyleAndermatt(this.iconHelper)],
    ['sisag', new MapStyleSisag(this.iconHelper)],
  ]);

  private currentStyle$: Observable<MapStyle> = this.masterDataService.masterData.pipe(
    map((masterData) => this.styleMap.get(masterData.style)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(private masterDataService: MasterDataService, private iconHelper: IconHelper) {}

  async getStyleName(): Promise<string> {
    const style = await this.getStyle();
    return style.name;
  }

  async getIconSvg(iconName: string): Promise<string> {
    const style = await this.getStyle();
    return style.getIconSvg(iconName);
  }
  async getLayerIconSvgUrl(layerIconName: string, alternateStyle = false): Promise<string> {
    const style = await this.getStyle();
    return style.getLayerIconSvgUrl(layerIconName, alternateStyle);
  }
  async getSeasonIconSvgUrl(seasonIconName: string, alternateStyle = false): Promise<string> {
    const style = await this.getStyle();
    return style.getSeasonIconSvgUrl(seasonIconName, alternateStyle);
  }

  async createCurveEndMarkers(
    curveCoords: CurvePathData,
    color: string,
    element: MapElement
  ): Promise<CurveEndMarkers> {
    const style = await this.getStyle();
    return style.createCurveEndMarkers(curveCoords, color, element);
  }
  async updateCurveEndMarkers(
    curveCoords: CurvePathData,
    curveEndMarkers: CurveEndMarkers,
    color: string,
    element: MapElement
  ): Promise<void> {
    const style = await this.getStyle();
    style.updateCurveEndMarkers(curveCoords, curveEndMarkers, color, element);
  }
  async zoomCurveEndMarkers(curveEndMarkers: CurveEndMarkers, scaling: number): Promise<void> {
    const style = await this.getStyle();
    style.zoomCurveEndMarkers(curveEndMarkers, scaling);
  }

  async setHighlight(curveEndMarkers: CurveEndMarkers, highlight: boolean): Promise<void> {
    const style = await this.getStyle();
    style.setHighlight(curveEndMarkers, highlight);
  }

  async getLiftColor(assetStatus: AssetStatus, element: MapElement, overrideColor = false): Promise<IconColor> {
    const style = await this.getStyle();
    return style.getLiftColor(assetStatus, element, overrideColor);
  }

  async getColor(status: AssetStatus): Promise<IconColor> {
    const style = await this.getStyle();
    return style.getColor(status);
  }

  async setIconColor(icon: Marker, backgroundColor: string, symbolColor?: string): Promise<void> {
    const style = await this.getStyle();
    style.setIconColor(icon, backgroundColor, symbolColor);
  }
  async setIconBorder(icon: Marker): Promise<void> {
    const style = await this.getStyle();
    style.setIconBorder(icon);
  }

  async setSidepaneIconColor(icon: string, backgroundColor: string, symbolColor?: string): Promise<string> {
    const style = await this.getStyle();
    return style.setSidepaneIconColor(icon, backgroundColor, symbolColor);
  }

  async createCurvePopupContent(
    element: MapElement,
    showLabel: boolean
  ): Promise<{ popupContent: string; className: string }> {
    const style = await this.getStyle();
    return style.createCurvePopupContent(element, showLabel);
  }

  async createIconPopupContent(
    element: MapElement,
    showLabel: boolean
  ): Promise<{ popupContent: string; className: string }> {
    const style = await this.getStyle();
    return style.createIconPopupContent(element, showLabel);
  }

  async getSignPostUrl(): Promise<string> {
    const style = await this.getStyle();
    const blob = style.getSignPostSvgUrl();
    return `${environment.baseUrlPublicAssets}/${blob}`;
  }

  private async getStyle(): Promise<MapStyle> {
    return firstValueFrom(this.currentStyle$);
  }
}

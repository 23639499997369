import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, timer } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BreakdownInfoAdapter } from 'src/app/breakdowninfo/breakdowninfo.adapter';
import { BreakdownInfo } from 'src/app/breakdowninfo/breakdowninfo.model';
import { NavigatorService } from 'src/app/core/navigator/navigator.service';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BreakdownInfoService extends Unsubscriber {
  static readonly urlPath = '/api/breakdowninfo/';

  constructor(private http: HttpClient, private navigator: NavigatorService) {
    super();
  }

  periodicallyRequestBreakdownInfoData(period: number, breakdownInfoGuid: string): Observable<BreakdownInfo> {
    if (!breakdownInfoGuid || breakdownInfoGuid.length === 0) {
      this.navigator.navigateToErrorPage();
      return;
    }

    return timer(0, period).pipe(
      switchMap(() =>
        this.http
          .get(
            `${environment.baseUrlApi}${BreakdownInfoService.urlPath}${breakdownInfoGuid}?${Math.floor(
              Math.random() * 10000
            ).toString()}`
          )
          .pipe(
            catchError(() => {
              this.navigator.navigateToErrorPage();
              return EMPTY;
            })
          )
      ),
      map((data: any) => BreakdownInfoAdapter.adapt(data)),
      tap((breakdownInfo) => {
        if (!breakdownInfo) {
          this.navigator.navigateToErrorPage();
        }
      })
    );
  }
}

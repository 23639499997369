import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, merge, Observable, of, Subject, timer } from 'rxjs';
import { catchError, map, scan, switchMap } from 'rxjs/operators';
import { NavigatorService } from 'src/app/core/navigator/navigator.service';
import { SisMediaAssetCategory } from 'src/app/webreport/domain/sismedia-asset-category.enum';
import { SisMediaItem } from 'src/app/webreport/domain/sismedia-item.model';
import { SisMediaSetting } from 'src/app/webreport/domain/sismedia-setting.model';
import { WebReportAdapter } from 'src/app/webreport/domain/webreport.adapter';
import { WebReport } from 'src/app/webreport/domain/webreport.model';
import { WebReportInfotext } from 'src/app/webreport/domain/webreport-infotext.model';
import { WebReportMeteoInfoItem } from 'src/app/webreport/domain/webreport-meteo-info-item.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebReportService {
  private readonly updateRequestsIntervalMs = 30_000;
  private readonly requestUpdate$ = new Subject<void>();

  constructor(private httpClient: HttpClient, private navigatorService: NavigatorService) {}

  requestSisMediaAssets(exportUrlName: string, columnsInParams: number): Observable<WebReport> {
    if (!exportUrlName) {
      return of<WebReport>({
        tenant: null,
        tenantOrders: [],
        sisMediaItemsByCategory: new Map<SisMediaAssetCategory, SisMediaItem[]>(),
        infotextsByTenantAlias: new Map<string, WebReportInfotext>(),
        meteoInfosByTenantAlias: new Map<string, WebReportMeteoInfoItem[]>(),
        sisMediaSettingByTenantAlias: new Map<string, SisMediaSetting>(),
      });
    }

    const url = `${environment.baseUrlApi}/api/webreport/${exportUrlName}`;

    return merge(timer(0, this.updateRequestsIntervalMs), this.requestUpdate$).pipe(
      switchMap(() =>
        this.httpClient.get(`${url}?${Math.random() * 1000}`).pipe(
          catchError(() => {
            this.navigatorService.navigateToErrorPage();
            return EMPTY;
          })
        )
      ),
      map((data) => WebReportAdapter.adapt(data, columnsInParams)),
      scan((prev, curr) => {
        if (!prev) {
          return curr;
        } else {
          prev.exportConfig = curr.exportConfig;
          prev.infotextsByTenantAlias = curr.infotextsByTenantAlias;
          prev.tenant = curr.tenant;
          prev.tenantOrders = curr.tenantOrders;
          prev.meteoInfosByTenantAlias = curr.meteoInfosByTenantAlias;
          prev.sisMediaSettingByTenantAlias = curr.sisMediaSettingByTenantAlias;

          prev.sisMediaItemsByCategory.forEach((items, category) => {
            if (!curr.sisMediaItemsByCategory.has(category)) {
              prev.sisMediaItemsByCategory.delete(category);
              return;
            }
            if (!prev.sisMediaItemsByCategory.has(category)) {
              prev.sisMediaItemsByCategory.set(category, curr.sisMediaItemsByCategory.get(category));
              return;
            }

            const newItems = curr.sisMediaItemsByCategory.get(category);
            newItems.forEach((item) => {
              const existingItem = items.find((i) => i.guid === item.guid);
              if (existingItem) {
                Object.assign(existingItem, item);
              } else {
                items.push(item);
              }
            });

            const removedItems = items.filter((i) => !newItems.find((n) => n.guid === i.guid));
            removedItems.forEach((i) =>
              items.splice(
                items.findIndex((o) => o.guid === i.guid),
                1
              )
            );
          });
        }

        return prev;
      })
    );
  }

  requestUpdate(): void {
    this.requestUpdate$.next();
  }
}

import { WebReportMeteoInfoItem } from 'src/app/webreport/domain/webreport-meteo-info-item.model';

export class WebReportMeteoInfoItemAdapter {
  static adapt(items: any[]): WebReportMeteoInfoItem[] {
    if (!items) {
      return [];
    }

    return items
      .map((i) => ({
        guid: i.guid,
        tenantGuid: i.tenantGuid,
        location: i.location,
        altitude: i.altitude,
        temperature: this.toNumberOrUndefined(i.temperature),
        snowDepth: this.toNumberOrUndefined(i.snowDepth),
        freshSnow: this.toNumberOrUndefined(i.freshSnow),
        weather: i.weather,
        avalancheDangerLevel: i.avalancheDangerLevel,
        snowConditionId: i.snowConditionId,
        slopeConditionId: i.slopeConditionId,
        windSpeed: i.windSpeed,
        latestSnowfall: this.toStringAsLocalDate(i.latestSnowfall),
      }))
      .sort((a, b) => a.altitude - b.altitude);
  }

  static toNumberOrUndefined(value: any): number | undefined {
    return isNaN(Number(value)) ? undefined : Number(value);
  }

  static toStringAsLocalDate(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date string');
    }
    return `${String(date.getUTCDate()).padStart(2, '0')}.${String(date.getUTCMonth() + 1).padStart(2, '0')}.${date.getUTCFullYear()}`;
  }

}

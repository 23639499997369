import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { Season } from 'src/app/core/domain/season.enum';
import { ComponentCanDeactivate } from 'src/app/core/guards/pending-changes.guard';
import { NavigatorService } from 'src/app/core/navigator/navigator.service';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { MeteoDataService } from 'src/app/maps/domain/livedata/meteodata.service';
import { MapRoutes } from 'src/app/maps/domain/map-routes';
import { LayerName } from 'src/app/maps/domain/masterdata/layername.enum';
import { MasterData } from 'src/app/maps/domain/masterdata/masterdata.model';
import { MasterDataService } from 'src/app/maps/domain/masterdata/masterdata.service';
import { MapStateService } from 'src/app/maps/map/map-state.service';
import { AssetEditorService } from 'src/app/maps/map/sidepane/asset-editor-tab/asset-editor.service';
import { WindIconService } from 'src/app/maps/map/wind-icon.service';

@Component({
  selector: 'sis-windmonitor-map',
  templateUrl: './windmonitor.page.html',
  styleUrls: ['./windmonitor.page.scss'],
})
export class WindmonitorPage extends Unsubscriber implements OnInit, ComponentCanDeactivate {
  private editorHasChanges = false;

  masterData: MasterData;
  editMode = false;

  constructor(
    private masterDataService: MasterDataService,
    private activatedRoute: ActivatedRoute,
    private navigator: NavigatorService,
    private windIconService: WindIconService,
    private meteoDataService: MeteoDataService,
    private assetEditorService: AssetEditorService,
    private mapStateService: MapStateService
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
      .pipe(take(1))
      .subscribe(([params, queryParams]) => {
        const routeSisId = params[MapRoutes.SisId];
        const routeAlias = params[MapRoutes.Alias];
        const routeSeason = params[MapRoutes.Season];
        this.editMode = queryParams['edit'] != null;
        const layers = [LayerName.Lift, LayerName.Place, LayerName.Wind];
        const parsedRouteSeason = Number.parseInt(routeSeason, 10);
        if (!(parsedRouteSeason in Season)) {
          this.navigator.navigateToErrorPage();
          return;
        }

        this.masterDataService.requestMasterData(routeSisId, routeAlias, routeSeason, layers);
      });

    this.masterDataService.masterData.pipe(takeUntil(this.onDestroy$)).subscribe({
      next: (masterData) => {
        if (masterData == null || (!this.editMode && !masterData?.winds?.length)) {
          this.navigator.navigateToErrorPage();
          return;
        }
        this.masterData = masterData;

        this.windIconService.startLiveData(10000, masterData.tenantGuid);
        this.meteoDataService.meteoData$.pipe(takeUntil(this.onDestroy$)).subscribe((meteoData) => {
          masterData.winds.forEach((wind) => {
            const liveData = meteoData?.find((f) => f.meteoDeviceSisId === wind.meteoStationSisId);
            if (!liveData || liveData.outdated || liveData.windSpeedKmh == null) {
              wind.windDirectionDegreeAxis = null;
              wind.windDirectionDegreeNorth = null;
              wind.windSpeedKmh = null;
            } else {
              wind.windDirectionDegreeAxis = liveData.windDirectionDegreeAxis;
              wind.windDirectionDegreeNorth = liveData.windDirectionDegreeNorth;
              wind.windSpeedKmh = liveData.windSpeedKmh;
            }
            this.mapStateService.updateMapElement(wind);
          });
        });
      },
      error: () => {
        this.navigator.navigateToErrorPage();
      },
    });

    this.assetEditorService.hasChanges$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((hasChanges) => (this.editorHasChanges = hasChanges));
  }

  @HostListener('window:beforeunload')
  nativeCanDeactivate(): boolean {
    return !this.editorHasChanges;
  }

  canDeactivate(): Observable<boolean> {
    return this.assetEditorService.hasChanges$.pipe(map((hasChanges) => !hasChanges));
  }
}

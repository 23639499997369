import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clickableUrl',
})
export class ClickableUrlPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    let result = value;

    // Regular Expression to match URLs
    const urlRegex =
      /(\b((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?))/gi;
    result = result.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');

    return this.addHttpsToUrls(result);
  }

  private addHttpsToUrls(text: string): string {
    const pattern = /href\s*=\s*("[^"]*"|'[^']*'|[^'"\s>]+)/g;

    const urls = text.match(pattern);

    urls?.forEach((url) => {
      const urlWithoutQuotes = url.replace(/^(href\s*=\s*['"])|['"]$/g, '');
      if (
        !/^https?:\/\//i.test(urlWithoutQuotes) &&
        !/^ftp:\/\//i.test(urlWithoutQuotes) &&
        !/^file:\/\//i.test(urlWithoutQuotes)
      ) {
        text = text.replace(url, 'href="https://' + urlWithoutQuotes + '"');
      }
    });

    return text;
  }
}

import { PointExpression } from 'leaflet';
import { Season } from 'src/app/core/domain/season.enum';
import { WritablePart } from 'src/app/core/utils/type-helper';
import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';

export class POI implements MapElement {
  static readonly defaultColor: string = 'white';
  static readonly defaultIconSize: PointExpression = [18, 18];
  static readonly defaultWeight = 2;
  static readonly empty: POI = new POI({ guid: null, status: AssetStatus.Disabled });

  readonly category = SisMapAssetCategory.Poi;

  guid: string;
  name: string;
  nameEn: string;
  type: string;
  info?: string;
  website?: string;
  season: Season;
  status: AssetStatus;
  path?: string;
  pathDashArray?: string;
  pathWeight?: number;
  pathColor?: string;
  paneName = 'poi';

  defaultPathColor: string;
  defaultPathWeight: number;

  iconPositions: MapPosition[];
  iconWidth: number;
  iconHeight: number;
  iconImageName?: string;

  constructor(init?: Partial<WritablePart<POI>>) {
    Object.assign(this, init);
  }
}

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';
import { IconService } from 'src/app/maps/map/icon-service';
import { AssetListItem } from 'src/app/maps/map/sidepane/add-asset-tab/asset-list/asset-list-item/asset-list-item.model';
import { AssetListItemButton } from 'src/app/maps/map/sidepane/add-asset-tab/asset-list/asset-list-item/asset-list-item-button.model';

@Component({
  selector: 'sis-sismap-sidepane-asset-list-item',
  templateUrl: './asset-list-item.component.html',
  styleUrls: ['./asset-list-item.component.scss'],
})
export class AssetListItemComponent extends Unsubscriber implements OnChanges {
  @Input() item: AssetListItem;
  @Input() selected = false;
  @Input() draggable = false;
  @Input() actionButtons: AssetListItemButton[] = [];

  @Output() buttonClicked = new EventEmitter<AssetListItemButton>();
  @Output() itemDraggedToMap = new EventEmitter<AssetListItem>();

  elementIcon: SafeHtml;
  elementName: string;
  elementLabel: string;
  elementLabelTooltipDisabled = false;
  elementTooltip = '';

  constructor(private iconService: IconService, private sanitizer: DomSanitizer) {
    super();
  }

  ngOnChanges(): void {
    this.elementName = this.item.element.name;
    this.elementLabel = this.item.element.label;
    this.iconService.getIconHtml(this.item.element).then((iconString) => {
      this.elementIcon = this.sanitizer.bypassSecurityTrustHtml(iconString);
    });
    if (this.item.changed) {
      this.elementTooltip = 'Dieses Asset wurde geändert';
    } else if (this.item.disabled) {
      this.elementTooltip = 'Dieses Asset ist bereits auf der Karte';
    } else {
      this.elementTooltip = SisMapAssetCategory[this.item.element.category];
    }
  }

  emitButtonAction(event: MouseEvent, button: AssetListItemButton): void {
    event.stopPropagation();
    this.buttonClicked.emit(button);
  }

  updateLabelTooltipDisabled(label: HTMLSpanElement): void {
    this.elementLabelTooltipDisabled = label.scrollWidth <= label.clientWidth;
  }

  dragEnd(ev: CdkDragDrop<MapElement, MapElement, MapElement>): void {
    if (ev.container.id === 'mapList') {
      // Element got dragged into map
      this.itemDraggedToMap.emit(this.item);
    }
  }
}

import { Injectable } from '@angular/core';
import { Coords } from 'src/app/maps/domain/masterdata/coords.model';
import { Wind } from 'src/app/maps/domain/masterdata/wind.model';

export enum ArrowStyle {
  NONE,
  ONE,
  TWO,
}

@Injectable({
  providedIn: 'root',
})
export class WindDisplayCalculatorService {
  static windSpeedUnknown = '?';

  static readonly defaultWarnRangeHigh = 40;
  static readonly defaultAlarmRangeHigh = 60;

  getRotationTransform(
    wind: Wind,
    directionOffsetPanomap: number,
    directionOffsetNorth: number,
    pivotPoint: Coords
  ): string {
    if (this.hasValue(wind.windDirectionDegreeNorth) && this.hasValue(directionOffsetNorth)) {
      return `rotate(${wind.windDirectionDegreeNorth - directionOffsetNorth + directionOffsetPanomap},${pivotPoint.x},${
        pivotPoint.y
      })`;
    } else if (this.hasValue(wind.windDirectionDegreeAxis)) {
      return `rotate(${wind.windDirectionDegreeAxis + directionOffsetPanomap},${pivotPoint.x},${pivotPoint.y})`;
    } else {
      return '';
    }
  }

  private hasValue(data: number): boolean {
    return !(data == null || isNaN(data));
  }

  getWindSpeed(wind: Wind): string {
    return wind.windSpeedKmh != null ? wind.windSpeedKmh.toString() : WindDisplayCalculatorService.windSpeedUnknown;
  }

  getArrowStyle(wind: Wind): ArrowStyle {
    if (wind.windSpeedKmh === 0 || wind.windSpeedKmh == null) {
      return ArrowStyle.NONE;
    }

    switch (wind.type) {
      case 'none':
        return ArrowStyle.NONE;
      case 'directional':
        return ArrowStyle.ONE;
      case 'across':
        return ArrowStyle.TWO;
      default:
        return ArrowStyle.ONE;
    }
  }
}

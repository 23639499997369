<div class="list-container">
  <ion-row class="input-container">
    <ion-col>
      <ion-item>
        <ion-input
          placeholder="Filter Assets"
          [ngModel]="assetFilter"
          (ngModelChange)="setAssetFilter($event)"
          (ionFocus)="selectText($event)"
          [clearInput]="true"
        />
      </ion-item>
    </ion-col>
    <ion-col sizeXl="5" sizeLg="6" *ngIf="showChangedCheckbox">
      <ion-item class="change-checkbox-container">
        <ion-checkbox
          id="changedCheckbox"
          [(ngModel)]="onlyShowChanged"
          (ionChange)="setChangedFilter($event.detail.checked)"
        ></ion-checkbox>
        <ion-label>Nur Geänderte</ion-label>
      </ion-item>
    </ion-col>
    <ion-col sizeXl="1" sizeLg="1" *ngIf="showRefreshButton" class="refresh-button-container">
      <button
        mat-icon-button
        matTooltip="Liste aktualisieren"
        [matTooltipShowDelay]="200"
        (click)="emitRefreshAction()"
      >
        <ion-icon name="refresh"> </ion-icon>
      </button>
    </ion-col>
  </ion-row>
  <cdk-virtual-scroll-viewport
    *ngIf="filteredElements.length > 0 && !isLoading; else loading"
    itemSize="32"
    class="scroll-container"
    minBufferPx="320"
    maxBufferPx="640"
  >
    <sis-sismap-sidepane-asset-list-item
      class="scroll-element"
      [element]="item.element"
      (click)="clickElement(item)"
      (buttonClicked)="emitActionButtonClick($event, item)"
      (itemDraggedToMap)="emitItemDraggedToMap($event)"
      [selected]="item.element.guid === selectedElement?.guid"
      [draggable]="enableDragging"
      [item]="item"
      [actionButtons]="actionButtons"
      *cdkVirtualFor="let item of filteredElements"
    >
    </sis-sismap-sidepane-asset-list-item>
  </cdk-virtual-scroll-viewport>
  <ng-template #loading>
    <ng-container *ngIf="isLoading; else empty">
      <ion-row>
        <ion-col> Lade Assets... </ion-col>
      </ion-row>
    </ng-container>
  </ng-template>
  <ng-template #empty>
    <ion-row>
      <ion-col> Keine Assets gefunden </ion-col>
    </ion-row>
  </ng-template>
</div>

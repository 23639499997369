import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sis-quickstart',
  template: '',
})
export class QuickstartPage implements OnInit {
  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    window.open(
      `https://prodsiscontrolpublic.blob.core.windows.net/public-assets/quickstart/quickstart_${this.translateService.defaultLang}.pdf`,
      '_self'
    );
  }
}

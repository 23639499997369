import { PointExpression } from 'leaflet';
import { Season } from 'src/app/core/domain/season.enum';
import { WritablePart } from 'src/app/core/utils/type-helper';
import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';

export class Trail implements MapElement {
  static readonly defaultColor: string = 'black';
  static readonly defaultWeight: number = 2;
  static readonly defaultIconSize: PointExpression = [18, 18];
  static readonly empty: Trail = new Trail({ guid: null, status: AssetStatus.Closed });

  readonly category = SisMapAssetCategory.Trail;

  guid: string;
  name: string;
  nameEn: string;
  type: string;
  info?: string;
  duration?: number;
  elevation?: number;
  website?: string;
  season: Season;
  label: string;
  status: AssetStatus;
  path?: string;
  pathDashArray?: string;
  pathWeight?: number;
  pathColor?: string;
  paneName = 'trail';

  defaultPathColor: string;
  defaultPathWeight: number;

  iconPositions: MapPosition[];
  iconWidth: number;
  iconHeight: number;
  iconImageName?: string;

  description: string;
  lengthMeter: number;

  constructor(init?: Partial<WritablePart<Trail>>) {
    Object.assign(this, init);
  }
}

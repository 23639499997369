import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NumberPickerComponent } from 'src/app/core/components/number-picker/number-picker.component';
import { GeneralButtonComponent } from 'src/app/core/general-button/general-button.component';
import { LanguageSelector } from 'src/app/core/language-selector/language-selector.component';
import { ClickableUrlPipe } from 'src/app/core/utils/clickable-url.pipe';
import { MetersToKilometersPipe } from 'src/app/core/utils/meter-kilometer.pipe';

@NgModule({
  declarations: [
    LanguageSelector,
    NumberPickerComponent,
    GeneralButtonComponent,
    ClickableUrlPipe,
    MetersToKilometersPipe,
  ],
  exports: [LanguageSelector, NumberPickerComponent, GeneralButtonComponent, ClickableUrlPipe, MetersToKilometersPipe],
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule],
})
export class CoreModule {}

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';
import { AssetListItem } from 'src/app/maps/map/sidepane/add-asset-tab/asset-list/asset-list-item/asset-list-item.model';
import { AssetListItemButton } from 'src/app/maps/map/sidepane/add-asset-tab/asset-list/asset-list-item/asset-list-item-button.model';

@Component({
  selector: 'sis-sismap-sidepane-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss'],
})
export class AssetListComponent extends Unsubscriber implements OnChanges {
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  @Input() elements: AssetListItem[] = [];
  @Input() selectedElement: MapElement;
  @Input() enableDragging = false;
  @Input() showChangedCheckbox = false;
  @Input() showRefreshButton = false;
  @Input() isLoading = false;
  @Input() actionButtons: AssetListItemButton[];

  @Output() clickedElement = new EventEmitter<AssetListItem>();
  @Output() itemDraggedToMap = new EventEmitter<AssetListItem>();
  @Output() refreshTable = new EventEmitter<void>();
  @Output() buttonClicked = new EventEmitter<{ item: AssetListItem; button: AssetListItemButton }>();
  filteredElements: AssetListItem[] = [];

  assetFilter = '';

  onlyShowChanged = false;

  ngOnChanges(changes: SimpleChanges): void {
    if ('elements' in changes) {
      this.filterElements(this.assetFilter);
    }
    if ('selectedElement' in changes && this.selectedElement != null) {
      const index = this.filteredElements.findIndex((e) => e.element.guid === this.selectedElement.guid);
      if (index > -1) {
        this.viewPort.scrollToIndex(index, 'smooth');
      }
    }
  }

  setChangedFilter(value: boolean): void {
    this.onlyShowChanged = value;
    this.filterElements(this.assetFilter);
  }

  setAssetFilter(value: string): void {
    this.assetFilter = value;
    this.filterElements(value);
  }

  clickElement(item: AssetListItem): void {
    this.clickedElement.emit(item);
  }

  emitActionButtonClick(button: AssetListItemButton, item: AssetListItem): void {
    this.buttonClicked.emit({ item, button });
  }

  emitItemDraggedToMap(item: AssetListItem): void {
    this.itemDraggedToMap.emit(item);
  }

  emitRefreshAction(): void {
    this.refreshTable.emit();
  }

  filterElements(value: string): void {
    if (this.elements == null) {
      this.filteredElements = [];
      return;
    }
    const terms = value.toLocaleLowerCase().trim().split(/\s+/);
    this.filteredElements = this.elements.filter(
      (el) =>
        terms.every(
          (term) =>
            el.element.name?.toLocaleLowerCase().includes(term) ||
            el.element.type?.toLocaleLowerCase() === term ||
            SisMapAssetCategory[el.element.category].toLocaleLowerCase() === term ||
            el.element.label?.toLocaleLowerCase().includes(term)
        ) &&
        (!this.onlyShowChanged || el.changed || el.deleted)
    );
  }

  async selectText(event: FocusEvent): Promise<void> {
    let inputElement: HTMLInputElement;
    if (event.target instanceof HTMLInputElement) {
      inputElement = event.target;
    } else if ('getInputElement' in event.target) {
      // Ionic input wrapper
      inputElement = await (event.target as EventTarget & IonInput).getInputElement();
    }
    setTimeout(() => {
      inputElement?.select();
    });
  }
}

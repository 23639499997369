<ion-grid>
  <ion-row
    class="sis-title"
    [ngStyle]="{ 'background-color': this.itemColor }"
    data-test="sis-operating-info-item-title"
  >
    <ion-col>
      {{ this.operatingInfoTitle }}
    </ion-col>
  </ion-row>

  <ion-row
    class="sis-text"
    [ngStyle]="{ 'background-color': this.itemColor + '66' }"
    data-test="sis-operating-info-item-text"
  >
    <ion-col>
      <div [innerHTML]="this.operatingInfoText"></div>
      <div class="sis-timestamp">{{ this.operatingInfoTimestamp | date : 'dd.MM.yyyy HH:mm' }}</div>
    </ion-col>
  </ion-row>
</ion-grid>

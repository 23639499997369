import 'leaflet.fullscreen';

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import {
  CRS,
  imageOverlay,
  LatLng,
  LatLngBoundsExpression,
  Layer,
  LayerGroup,
  layerGroup,
  LeafletMouseEvent,
  Map,
  MapOptions,
} from 'leaflet';
import { firstValueFrom, ReplaySubject, timer } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { delay, take, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { CustomIcon } from 'src/app/maps/domain/masterdata/custom-icon.model';
import { CustomPath } from 'src/app/maps/domain/masterdata/custom-path.model';
import { Gastro } from 'src/app/maps/domain/masterdata/gastro.model';
import { Lift } from 'src/app/maps/domain/masterdata/lift.model';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { MasterData } from 'src/app/maps/domain/masterdata/masterdata.model';
import { Place } from 'src/app/maps/domain/masterdata/place.model';
import { POI } from 'src/app/maps/domain/masterdata/poi.model';
import { Slope } from 'src/app/maps/domain/masterdata/slope.model';
import { Trail } from 'src/app/maps/domain/masterdata/trail.model';
import { Webcam } from 'src/app/maps/domain/masterdata/webcam.model';
import { Wind } from 'src/app/maps/domain/masterdata/wind.model';
import { HoverService } from 'src/app/maps/map/hover.service';
import { MapStateService } from 'src/app/maps/map/map-state.service';
import { AssetEditorService } from 'src/app/maps/map/sidepane/asset-editor-tab/asset-editor.service';
import { WebcamModalComponent } from 'src/app/maps/map/webcam-modal/webcam-modal.component';
import { SisZoom } from 'src/app/maps/map/zoom.model';
import { ZoomService } from 'src/app/maps/map/zoom.service';
import { environment } from 'src/environments/environment';

declare let L: any;
@Component({
  selector: 'sis-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent extends Unsubscriber implements OnInit, AfterViewInit {
  static readonly defaultOpacity: number = 0.9;
  static readonly selectedOpacity: number = 0.6;

  private readonly mapReady$ = new ReplaySubject<void>(1);

  private readonly paneNames: string[] = [
    'customPath',
    'slope',
    'trail',
    'poi',
    'lift',
    'gastro',
    'webcam',
    'wind',
    'customIcon',
    'place',
  ];

  fullScreenControl: any;
  map: Map;
  options: MapOptions;
  layers: Layer[];
  bigScreenMode: boolean;
  openSidepane: boolean;

  wasVisibleAtLeastOnce = false;

  lifts: Lift[] = [];
  liftLayerGroup: LayerGroup;
  slopes: Slope[] = [];
  slopeLayerGroup: LayerGroup;
  trails: Trail[] = [];
  trailLayerGroup: LayerGroup;
  places: Place[] = [];
  placeLayerGroup: LayerGroup;
  customIcons: CustomIcon[] = [];
  customIconLayerGroup: LayerGroup;
  customPaths: CustomPath[] = [];
  customPathLayerGroup: LayerGroup;
  pois: POI[] = [];
  poiLayerGroup: LayerGroup;
  gastros: Gastro[] = [];
  gastroLayerGroup: LayerGroup;
  webcams: Webcam[] = [];
  webcamLayerGroup: LayerGroup;
  winds: Wind[] = [];
  windLayerGroup: LayerGroup;

  private imageResX: number;
  private imageResY: number;
  private imageBounds: LatLngBoundsExpression;
  private lastContextPosition: MapPosition | null = null;

  @Input() masterData: MasterData;
  @Input() hoverDisabled = false;
  @Input() showStatus = true;
  @Input() showStatusIcons = false;
  @Input() backgroundColor = 'white';
  @Input() editMode = false;
  @Input() enableAssetSidepane = true;
  @Input() allowElementSelection = true;

  @ViewChild('leafletContainer') leafletContainer: ElementRef<HTMLDivElement>;

  constructor(
    private zone: NgZone,
    private zoomService: ZoomService,
    private screenSizeService: ScreenSizeService,
    private hoverService: HoverService,
    private activatedRoute: ActivatedRoute,
    private mapStateService: MapStateService,
    private modalCtrl: ModalController,
    private assetEditorService: AssetEditorService
  ) {
    super();
    this.initLeafletConfigureOptions();
    this.initLayers();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.mapReady$.next());
  }

  ngOnInit(): void {
    // Show status icons in edit mode
    this.showStatusIcons ||= this.editMode;
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.hoverService.hoverDisabled = this.hoverDisabled;

    this.mapReady$.pipe(take(1)).subscribe(() => {
      if (this.masterData) {
        this.lifts = this.masterData.lifts;
        this.slopes = this.masterData.slopes;
        this.trails = this.masterData.trails;
        this.pois = this.masterData.pois;
        this.places = this.masterData.places;
        this.customIcons = this.masterData.customIcons;
        this.customPaths = this.masterData.customPaths;
        this.gastros = this.masterData.gastros;
        this.webcams = this.masterData.webcams;
        this.winds = this.masterData.winds;

        this.setupMap();
      }
    });

    this.mapStateService.elementClicked$.pipe(takeUntil(this.onDestroy$)).subscribe(({ element, iconIndex }) => {
      if (this.allowElementSelection) {
        this.mapStateService.selectElement(element, iconIndex);
      }

      if (this.editMode) {
        return;
      }

      if (element instanceof Webcam) {
        let width = 1280;
        let height = 720;
        let cssClass = 'sis-sismap-webcam-modal';

        switch (element.aspectRatio) {
          case Webcam.ratio_16_9:
            width = 1280;
            height = 720;
            cssClass = 'sis-sismap-webcam-16-9-modal';
            break;
          case Webcam.ratio_4_3:
            width = 1024;
            height = 768;
            cssClass = 'sis-sismap-webcam-4-3-modal';
            break;
          case Webcam.ratio_3_2:
            width = 1152;
            height = 768;
            cssClass = 'sis-sismap-webcam-3-2-modal';
            break;
          default:
            break;
        }

        if (element.type === Webcam.external) {
          const windowFeatures = `popup=true,top=100,left=100,width=${width},height=${height}`;
          window.open(element.url, 'Webcam', windowFeatures);
        } else {
          this.modalCtrl
            .create({
              component: WebcamModalComponent,
              cssClass,
              componentProps: { webcam: element },
            })
            .then((modal) => modal.present());
        }
      } else if (element instanceof CustomIcon && element.url != null) {
        window.open(element.url, '_self');
      }
    });

    this.mapStateService.centerElement$.pipe(takeUntil(this.onDestroy$)).subscribe(({ element, iconIndex }) => {
      const coords = element.iconPositions[iconIndex];
      if (coords != null) {
        this.map.panTo(new LatLng(coords.x, coords.y));
      }
    });
  }

  setMapVisibility($event: boolean): void {
    if ($event) {
      this.wasVisibleAtLeastOnce = true;
    }
  }

  onMapReady(map: Map): void {
    this.map = map;
  }

  onMapFullscreenToggle(): void {
    timer(50)
      .pipe(take(1))
      .subscribe(() =>
        this.zone.run(() => {
          if (this.shouldMapFitToHeight()) {
            this.fitMapToHeight();
          } else {
            this.fitMapToWidth();
          }
        })
      );
  }

  onFitMapToHeight(event: MouseEvent): void {
    event.stopPropagation();
    this.map.fitBounds([
      [0, 0],
      [this.imageResY, 0],
    ]);
  }

  fitMapToHeight(): void {
    this.zone.run(() => {
      const bounds: LatLngBoundsExpression = [
        [0, 0],
        [this.imageResY, 0],
      ];
      this.map.setMinZoom(-20);
      this.map.fitBounds(bounds);
      this.map.setMinZoom(this.map.getBoundsZoom(bounds, false));
    });
  }

  onFitMapToWidth(event: MouseEvent): void {
    event.stopPropagation();
    this.map.fitBounds([
      [0, 0],
      [0, this.imageResX],
    ]);
  }

  fitMapToWidth(): void {
    this.zone.run(() => {
      const bounds: LatLngBoundsExpression = [
        [0, 0],
        [0, this.imageResX],
      ];
      this.map.setMinZoom(-20);
      this.map.fitBounds(bounds);
      this.map.setMinZoom(this.map.getBoundsZoom(bounds, false));
    });
  }

  onZoomIn(event: MouseEvent): void {
    event.stopPropagation();
    this.zoomIn();
  }

  onZoomOut(event: MouseEvent): void {
    event.stopPropagation();
    this.zoomOut();
  }

  onFullscreen(event: MouseEvent): void {
    event.stopPropagation();
    this.fullscreen();
  }

  onOpenSidepaneClick(): void {
    this.openSidepane = !this.openSidepane;

    if (this.map) {
      setTimeout(() => {
        this.map.invalidateSize();
      }, 50);
    }
  }

  trackByGuid(_index: number, element: MapElement): string {
    return element.guid;
  }

  drop(event: CdkDragDrop<MapElement, MapElement, MapElement>): void {
    const element = event.item.data;
    const mapPos = this.map.containerPointToLatLng([event.dropPoint.x, event.dropPoint.y]);
    if (this.map.getBounds().contains(mapPos)) {
      this.assetEditorService.addAsset(element, { x: mapPos.lat, y: mapPos.lng });
    }
  }

  setContextPosition(event: LeafletMouseEvent): void {
    this.lastContextPosition = { x: event.latlng.lat, y: event.latlng.lng };
  }

  addWebcam(): void {
    this.assetEditorService.addAsset(new Webcam(), this.lastContextPosition);
  }

  addPlace(): void {
    this.assetEditorService.addAsset(new Place(), this.lastContextPosition);
  }

  addCustomIcon(): void {
    this.assetEditorService.addAsset(new CustomIcon(), this.lastContextPosition);
  }

  addCustomPath(): void {
    this.assetEditorService.addAsset(
      new CustomPath({
        type: 'wandern',
        disallowPopUp: true,
        disallowHighlight: true,
        iconSvgBlobName: 'customPath',
      })
    );
  }

  private zoomIn(): void {
    this.map.setZoom(this.map.getZoom() + 0.2);
  }

  private zoomOut(): void {
    this.map.setZoom(this.map.getZoom() - 0.2);
  }

  private fullscreen(): void {
    this.fullScreenControl.toggleFullScreen();
    if (this.shouldMapFitToHeight()) {
      this.fitMapToHeight();
    } else {
      this.fitMapToWidth();
    }
  }

  private async getImageDimension(imageUrl: string): Promise<{ height: number; width: number }> {
    return new Promise((resolve) => {
      if (imageUrl.split('/').pop() !== '') {
        const img = new Image();
        img.onload = (event): void => {
          const loadedImage: any = event.currentTarget;
          resolve({ width: loadedImage.width, height: loadedImage.height });
        };

        img.src = imageUrl;
      } else {
        resolve({ width: 1, height: 1 });
      }
    });
  }

  private initLeafletConfigureOptions(): void {
    this.options = {
      crs: CRS.Simple,
      wheelPxPerZoomLevel: 200,
      zoomControl: false,
      zoomSnap: 0,
      zoomDelta: 0.1,
      maxBoundsViscosity: 1,
      attributionControl: false,
      zoomAnimation: false,
    };
  }

  private initLayers(): void {
    this.slopeLayerGroup = layerGroup([], {
      pane: 'slope',
    });
    this.trailLayerGroup = layerGroup([], {
      pane: 'trail',
    });
    this.poiLayerGroup = layerGroup([], {
      pane: 'poi',
    });
    this.liftLayerGroup = layerGroup([], {
      pane: 'lift',
    });
    this.gastroLayerGroup = layerGroup([], {
      pane: 'gastro',
    });
    this.webcamLayerGroup = layerGroup([], {
      pane: 'webcam',
    });
    this.windLayerGroup = layerGroup([], {
      pane: 'wind',
    });
    this.customIconLayerGroup = layerGroup([], {
      pane: 'customIcon',
    });
    this.customPathLayerGroup = layerGroup([], {
      pane: 'customPath',
    });
    this.placeLayerGroup = layerGroup([], {
      pane: 'place',
    });

    this.layers = [
      this.slopeLayerGroup,
      this.trailLayerGroup,
      this.poiLayerGroup,
      this.liftLayerGroup,
      this.gastroLayerGroup,
      this.webcamLayerGroup,
      this.windLayerGroup,
      this.customIconLayerGroup,
      this.customPathLayerGroup,
      this.placeLayerGroup,
    ];
  }

  private shouldMapFitToHeight(): boolean {
    const params = this.activatedRoute.snapshot.queryParams;

    if (params && params['x']) {
      return true;
    }

    const leafletMap = document.getElementsByClassName('sis-pano-map') as HTMLCollectionOf<HTMLElement>;
    const screenWidth = leafletMap[0].clientWidth;
    const screenHeight = leafletMap[0].clientHeight;
    return !this.bigScreenMode || screenHeight - this.imageResY < screenWidth - this.imageResX;
  }

  private async setupMap(): Promise<void> {
    if (
      this.leafletContainer?.nativeElement == null ||
      (this.leafletContainer.nativeElement.offsetWidth === 0 && this.leafletContainer.nativeElement.offsetHeight === 0)
    ) {
      // There is a race condition between ngx-leaflet calling this method and
      // the div-element having its width and height set, which causes leaflet
      // to try to set NaN to be read when setting as mapbounds, which crashes.
      // To solve this issue we wait here until the div container has its dimensions
      // set so we can initialize the map safely
      setTimeout(() => this.setupMap(), 50);
      return;
    }

    this.paneNames.forEach((name, i) => {
      this.map.createPane(name).style.zIndex = `${500 + i + 1}`;
    });

    const panoMapUrl = `${environment.baseUrlPublicAssets}/map/${this.masterData.panoMapFilename}`;

    let imageDimension = await this.getImageDimensionFromMetadata(panoMapUrl);

    if (!imageDimension) {
      imageDimension = await this.getImageDimension(panoMapUrl);

      console.error(
        `missing image dimension metadata: height: ${imageDimension.height} , width: ${imageDimension.width} . Url: ${panoMapUrl}`
      );
    }

    this.imageResY = imageDimension.height;
    this.imageResX = imageDimension.width;

    this.imageBounds = [
      [0, 0],
      [this.imageResY, this.imageResX],
    ];

    // add the static Panorama Destination Image to the map with the bounds given by the dimension (height and width) of the image.
    this.layers.push(imageOverlay(panoMapUrl, this.imageBounds));

    this.map.setMaxBounds(this.imageBounds);
    this.fitMapToHeight();

    if (this.editMode) {
      this.map.setMaxZoom(this.map.getZoom() + 5);
    } else {
      this.map.setMaxZoom(this.map.getZoom() + 2);
    }
    this.map.setMinZoom(this.map.getZoom());

    this.map.on('contextmenu', (e) => this.setContextPosition(e));

    this.addZoomListener();

    this.map.on('click', () => {
      this.hoverService.hover({ guid: undefined, hover: false, iconHovered: false });
      this.mapStateService.selectElement(null, null);
    });

    if (this.shouldMapFitToHeight()) {
      this.fitMapToHeight();
    } else {
      this.fitMapToWidth();
    }

    this.map.whenReady(() => {
      this.activatedRoute.queryParams.pipe(take(1), delay(200)).subscribe((params) => {
        const x: string = params['x'];
        if (x) {
          const lng = Number.parseInt(x, 10);
          const y = Number.parseInt(params['y'], 10);
          const lat = !isNaN(y) ? y : this.map.getBounds().getCenter().lat;
          const z = Number.parseFloat(params['z']);
          const zoom = !isNaN(z) ? this.map.getZoom() + z : this.map.getZoom();

          this.map.setView([lat, lng], zoom, { animate: false });
        }

        if (this.editMode) {
          this.onOpenSidepaneClick();
        }
      });
    });
  }

  private addZoomListener(): void {
    const minZoom = this.map.getMinZoom();
    const defaultZoom = this.map.getZoom() + Math.abs(minZoom);

    this.map.on('zoom', () => {
      this.map.fire('viewreset');
      const currentZoom = this.map.getZoom() + Math.abs(minZoom);
      const diff = 1 + currentZoom - defaultZoom;
      const zoom = this.getMinZoomRatio(0.5, diff);

      this.zoomService.zoom(
        new SisZoom(
          zoom,
          this.bigScreenMode ? this.masterData.showIconZoomThreshold : this.masterData.showIconZoomThresholdMobile,
          this.map,
          this.masterData.iconZoomFactor
        )
      );
    });

    this.map.fireEvent('zoom');
  }

  private getMinZoomRatio(minRatio: number, diff: number): number {
    return diff + minRatio * (1 - diff);
  }

  private async getImageDimensionFromMetadata(blobUrl: string): Promise<{ height: number; width: number }> {
    const response = await firstValueFrom(
      ajax({
        url: blobUrl + '?comp=metadata',
        method: 'HEAD',
      })
    );

    const height = response.responseHeaders['x-ms-meta-height'];
    const width = response.responseHeaders['x-ms-meta-width'];

    if (height && width) {
      return { height: Number.parseInt(height, 10), width: Number.parseInt(width, 10) };
    }

    return null;
  }
}

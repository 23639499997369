import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { BreakdownInfoAdapter } from 'src/app/breakdowninfo/breakdowninfo.adapter';
import { BreakdownInfoPage } from 'src/app/breakdowninfo/breakdowninfo.page';
import { BreakdownInfoService } from 'src/app/breakdowninfo/breakdowninfo.service';
import { CoreModule } from 'src/app/core/core.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: BreakdownInfoPage
      }
    ]),
    CoreModule,
    TranslateModule
  ],
  declarations: [BreakdownInfoPage],
  providers: [BreakdownInfoService, BreakdownInfoAdapter]
})
export class BreakdownInfoPageModule {}

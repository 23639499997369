import { Component, Input, OnInit } from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { SisMediaSetting } from 'src/app/webreport/domain/sismedia-setting.model';
import { WebReportConfig } from 'src/app/webreport/domain/webreport-config.model';
import { WebReportMeteoInfoItem } from 'src/app/webreport/domain/webreport-meteo-info-item.model';

@Component({
  selector: 'sis-webreport-meteo-mobile',
  templateUrl: './webreport-meteo-mobile.component.html',
  styleUrls: ['./webreport-meteo-mobile.component.scss'],
})
export class WebreportMeteoMobileComponent extends Unsubscriber implements OnInit {
  @Input() meteoInfos$: Observable<Map<string, WebReportMeteoInfoItem[]>>;
  @Input() config: WebReportConfig;
  @Input() mobileStyleTenant: boolean;
  @Input() tenantAlias: string;
  @Input() sisMediaSettingByTenantAlias: Map<string, SisMediaSetting>;

  meteoInfosReport: Map<string, WebReportMeteoInfoItem[]>;

  ngOnInit(): void {
    this.meteoInfos$.pipe(takeUntil(this.onDestroy$)).subscribe((meteoInfos) => {
      this.meteoInfosReport = meteoInfos;
    });
  }

  trackByTenantKey(_index: number, tenant: { key: string; value: WebReportMeteoInfoItem[] }): string {
    return tenant.key;
  }

  trackByGuid(_index: number, item: WebReportMeteoInfoItem): string {
    return item.guid;
  }
}

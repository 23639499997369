import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CustomIcon } from 'src/app/maps/domain/masterdata/custom-icon.model';
import { CustomPath } from 'src/app/maps/domain/masterdata/custom-path.model';
import { Gastro } from 'src/app/maps/domain/masterdata/gastro.model';
import { LayerName } from 'src/app/maps/domain/masterdata/layername.enum';
import { Lift } from 'src/app/maps/domain/masterdata/lift.model';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MasterDataAdapter } from 'src/app/maps/domain/masterdata/masterdata.adapter';
import { MasterData } from 'src/app/maps/domain/masterdata/masterdata.model';
import { Place } from 'src/app/maps/domain/masterdata/place.model';
import { POI } from 'src/app/maps/domain/masterdata/poi.model';
import { Slope } from 'src/app/maps/domain/masterdata/slope.model';
import { Trail } from 'src/app/maps/domain/masterdata/trail.model';
import { Webcam } from 'src/app/maps/domain/masterdata/webcam.model';
import { Wind } from 'src/app/maps/domain/masterdata/wind.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  private static readonly masterDataRequestUrl = '/api/sismap/masterdata/';

  private readonly masterData$ = new ReplaySubject<MasterData>(1);

  readonly masterData = this.masterData$.asObservable();

  constructor(private http: HttpClient) {}

  requestMasterData(sisId: string, alias: string, season: string, layers: LayerName[]): void {
    if (!layers || layers.length === 0) {
      this.masterData$.next(null);
    } else {
      const layersUrlString = layers.map((layerName) => LayerName[layerName]).join(',');

      this.http
        .get(
          `${
            environment.baseUrlApi + MasterDataService.masterDataRequestUrl + sisId
          }/${alias}/${season}/${layersUrlString}?${Math.floor(Math.random() * 1000).toString()}`
        )
        .pipe(
          map((data: any) => MasterDataAdapter.adapt(data)),
          take(1)
        )
        .subscribe((data) => this.masterData$.next(data));
    }
  }

  async addMapElement(element: MapElement): Promise<void> {
    const masterData = await firstValueFrom(this.masterData);
    if (element instanceof Lift) {
      masterData.lifts.push(element);
    } else if (element instanceof CustomIcon) {
      masterData.customIcons.push(element);
    } else if (element instanceof CustomPath) {
      masterData.customPaths.push(element);
    } else if (element instanceof Gastro) {
      masterData.gastros.push(element);
    } else if (element instanceof Place) {
      masterData.places.push(element);
    } else if (element instanceof POI) {
      masterData.pois.push(element);
    } else if (element instanceof Slope) {
      masterData.slopes.push(element);
    } else if (element instanceof Trail) {
      masterData.trails.push(element);
    } else if (element instanceof Webcam) {
      masterData.webcams.push(element);
    } else if (element instanceof Wind) {
      masterData.winds.push(element);
    } else {
      console.error('MasterData: Trying to add unknown map element type', element);
      return;
    }

    this.masterData$.next(masterData);
  }

  async removeMapElement(element: MapElement): Promise<void> {
    const masterData = await firstValueFrom(this.masterData);
    let elementList: MapElement[];
    if (element instanceof Lift) {
      elementList = masterData.lifts;
    } else if (element instanceof CustomIcon) {
      elementList = masterData.customIcons;
    } else if (element instanceof CustomPath) {
      elementList = masterData.customPaths;
    } else if (element instanceof Gastro) {
      elementList = masterData.gastros;
    } else if (element instanceof Place) {
      elementList = masterData.places;
    } else if (element instanceof POI) {
      elementList = masterData.pois;
    } else if (element instanceof Slope) {
      elementList = masterData.slopes;
    } else if (element instanceof Trail) {
      elementList = masterData.trails;
    } else if (element instanceof Webcam) {
      elementList = masterData.webcams;
    } else if (element instanceof Wind) {
      elementList = masterData.winds;
    } else {
      console.error('MasterData: Trying to delete unknown map element type', element);
      return;
    }

    const index = elementList.findIndex((e) => e.guid === element.guid);
    if (index > -1) {
      elementList.splice(index, 1);
    }

    this.masterData$.next(masterData);
  }

  updateMapElementPosition(
    layer: LayerName | string,
    elementguid: string,
    position: string,
    editToken: string
  ): Observable<void> {
    const layerName = typeof layer === 'string' ? layer : LayerName[layer];
    return this.http
      .post(
        `${
          environment.baseUrlApi + MasterDataService.masterDataRequestUrl
        }map-by-edittoken/${layerName}/${elementguid}/position`,
        {
          position,
          editToken,
        }
      )
      .pipe(map(() => {}));
  }
}

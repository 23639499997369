<ion-header>
  <ion-toolbar color="default">
    <img src="assets\sis-logo\siscontrol-gray-optimized.svg" class="sis-logo" routerLink="/home" />
  </ion-toolbar>
</ion-header>
<ion-content class="sis-content">
  <ion-row>
    <ion-col></ion-col>
    <ion-col class="sis-title">
      <span translate>error.title</span>
    </ion-col>
    <ion-col></ion-col>
  </ion-row>
  <ion-row>
    <ion-col></ion-col>
    <ion-col class="sis-text">
      <span class="ion-text-wrap" translate>error.text</span>
    </ion-col>
    <ion-col></ion-col>
  </ion-row>
</ion-content>

<ion-grid *ngIf="this.asset">
  <ion-row>
    <ion-col style="font-weight: bold; color: var(--ion-color-secondary)">
      {{ this.assetCategory | uppercase }}</ion-col
    >
  </ion-row>
  <ion-row>
    <ion-col style="padding-top: 0px; min-height: 24px">
      <span *ngIf="this.asset.label">{{ this.asset.label }} </span>
      <span style="font-weight: bold" [innerHTML]="this.asset.name"></span>
    </ion-col>
  </ion-row>
  <form *ngIf="this.formGroup" [formGroup]="this.formGroup">
    <ion-row *ngIf="this.asset.iconPositions">
      <ion-col>
        <ion-grid formArrayName="iconPositions">
          <ion-row>
            <ion-col class="ion-no-padding">Icon-Koordinaten: </ion-col>
          </ion-row>
          <ion-row
            *ngFor="let iconPos of this.iconPositionInputs.controls; let i = index"
            [ngClass]="{ 'sis-selected-icon-row': this.selectedIconIndex === i }"
            [formGroup]="iconPos"
          >
            <ion-col size="1">
              <label>x: </label>
            </ion-col>
            <ion-col size="4">
              <input [ngClass]="{ 'sis-input-error': iconPos.get('x').errors }" type="text" formControlName="x" />
            </ion-col>
            <ion-col size="1">
              <label>y: </label>
            </ion-col>
            <ion-col size="4">
              <input [ngClass]="{ 'sis-input-error': iconPos.get('y').errors }" type="text" formControlName="y" />
            </ion-col>
            <ion-col size="1" class="sis-editor-icon">
              <ion-icon
                *ngIf="i <= this.originalAssetMaxIconPositionIndex"
                name="return-up-back-outline"
                (click)="this.resetPosition(i)"
              >
              </ion-icon>
            </ion-col>
            <ion-col *ngIf="this.canRemoveIcon" size="1" class="sis-editor-icon">
              <ion-icon name="trash-outline" (click)="this.removePosition(i)"> </ion-icon>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="!this.maxIconCountReached" class="ion-no-padding">
            <ion-col style="padding-left: 0">
              <sis-general-button
                [btnAlign]="'Left'"
                [btnType]="'Secondary'"
                width="50px"
                text="+"
                (sisClick)="this.addPosition()"
              ></sis-general-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
    </ion-row>

    <ion-row *ngFor="let property of this.config.editableProperties">
      <ion-col size="4" style="margin: auto"> {{ property.key }}: </ion-col>
      <ion-col>
        <input
          *ngIf="property.key !== 'path' && !property.selectableValues"
          type="text"
          [formControlName]="property.key"
          style="width: 100%"
          [ngClass]="{ 'sis-input-error': this.formGroup.controls[property.key].status === 'INVALID' }"
        />
        <select
          *ngIf="property.key !== 'path' && property.selectableValues"
          [formControlName]="property.key"
          style="width: 100%"
          [ngClass]="{ 'sis-input-error': this.formGroup.controls[property.key].status === 'INVALID' }"
        >
          <option *ngFor="let value of property.selectableValues" [value]="value">{{ value }}</option>
        </select>
        <textarea
          *ngIf="property.key === 'path'"
          rows="8"
          [formControlName]="property.key"
          [ngClass]="{ 'sis-input-error': this.formGroup.errors?.path }"
          style="width: 100%; font-size: 12px"
        ></textarea>
      </ion-col>
      <ion-col size="1" class="sis-editor-icon">
        <ion-icon name="return-up-back-outline" (click)="this.resetProperty(property.key)"> </ion-icon>
      </ion-col>
    </ion-row>
  </form>

  <ion-row class="sis-button-row">
    <ion-col size="4">
      <sis-general-button
        text="general.term.delete"
        expand="block"
        btnAlign="Left"
        btnType="Red"
        (sisClick)="this.delete()"
        width="100%"
      >
      </sis-general-button>
    </ion-col>
    <ion-col size="4">
      <sis-general-button
        *ngIf="this.canBeDuplicated"
        text="general.term.duplicate"
        expand="block"
        btnAlign="Left"
        btnType="Secondary"
        (sisClick)="this.duplicate()"
        width="100%"
      >
      </sis-general-button>
    </ion-col>
    <ion-col size="4">
      <sis-general-button
        text="general.term.reset"
        expand="block"
        btnAlign="Right"
        [disabled]="!this.hasChanges"
        btnType="Secondary"
        (sisClick)="this.reset()"
        width="100%"
      >
      </sis-general-button>
    </ion-col>
  </ion-row>
</ion-grid>
<div *ngIf="!this.asset" style="padding: 10px">Nichts ausgewählt</div>

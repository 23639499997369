import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, takeUntil } from 'rxjs/operators';
import { BreakdownInfo } from 'src/app/breakdowninfo/breakdowninfo.model';
import { BreakdownInfoService } from 'src/app/breakdowninfo/breakdowninfo.service';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sis-breakdowninfo',
  templateUrl: 'breakdowninfo.page.html',
  styleUrls: ['breakdowninfo.page.scss'],
})
export class BreakdownInfoPage extends Unsubscriber implements OnInit {
  private static readonly BreakdownInfoGuid = 'breakdownInfoGuid';

  private readonly updateInterval = 60_000;

  title: string;
  text: string;
  logoUrl: string;
  breakdownInfoIconUrl: string;
  homepage: string;
  breakdownInfo: BreakdownInfo;

  constructor(
    private translateService: TranslateService,
    private breakdownInfoService: BreakdownInfoService,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        switchMap((params) =>
          this.breakdownInfoService.periodicallyRequestBreakdownInfoData(
            this.updateInterval,
            params[BreakdownInfoPage.BreakdownInfoGuid]
          )
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe((data: BreakdownInfo) => {
        if (!data) {
          return;
        }

        this.breakdownInfo = data;
        this.setLogoUrl(this.breakdownInfo.logoFilename);
        this.setbreakdownInfoIconUrl(this.breakdownInfo.iconName);
        this.setTextAndTitle(
          this.translateService.currentLang ?? this.translateService.defaultLang,
          this.breakdownInfo
        );
        this.setWebsite(this.breakdownInfo.websiteUrl);
      });
  }

  private setLogoUrl(logoFilename: string): void {
    this.logoUrl = `${environment.baseUrlPublicAssets}/logo/${logoFilename}`;
  }

  private setbreakdownInfoIconUrl(breakdownInfoIconFilename: string): void {
    this.breakdownInfoIconUrl = `${environment.baseUrlPublicAssets}/breakdowninfo/${breakdownInfoIconFilename}`;
  }

  private setWebsite(homepageUrl: string): void {
    this.homepage = homepageUrl.replace(/^https?:\/\//, '');
  }

  private setTextAndTitle(languageKey: string, breakdownInfo: BreakdownInfo): void {
    if (!breakdownInfo) {
      return;
    }
    switch (languageKey) {
      case 'de':
        this.title = breakdownInfo.titleDe;
        this.text = breakdownInfo.textDe;
        break;
      case 'en':
        this.title = breakdownInfo.titleEn;
        this.text = breakdownInfo.textEn;
        break;
      case 'fr':
        this.title = breakdownInfo.titleFr;
        this.text = breakdownInfo.textFr;
        break;
      case 'it':
        this.title = breakdownInfo.titleIt;
        this.text = breakdownInfo.textIt;
        break;
      default:
        break;
    }
  }
}

import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ToastButton } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserMessage } from 'src/app/core/user-message/user-message.model';

@Injectable({
  providedIn: 'root',
})
export class UserMessageService {
  static readonly toastCssClass = 'sis-global-toast';

  private readonly minTimeBetweenSameMessageMs = 5000;
  private readonly messageOutdatedAfterMs = 20000;

  private userMessages: UserMessage[] = [];
  private toastActive: boolean;
  private messageTimeMap: Map<string, number>;

  constructor(private toastController: ToastController, private translateService: TranslateService) {
    this.messageTimeMap = new Map<string, number>();
  }

  async createToast(userMessage: UserMessage): Promise<HTMLIonToastElement> {
    const toastButtons: Array<string | ToastButton> = [];
    const translatedMessage = await this.getTranslatedString(userMessage.messageParts);
    if (userMessage.showCloseButton) {
      toastButtons.push({ side: 'end', icon: 'close' });
    }
    if (userMessage.icon) {
      toastButtons.push({ side: 'start', icon: userMessage.icon });
    }
    return this.toastController.create({
      message: translatedMessage,
      duration: userMessage.durationMs,
      position: userMessage.position,
      color: userMessage.color,
      cssClass: UserMessageService.toastCssClass,
      animated: true,
      buttons: toastButtons,
    });
  }

  async presentToast(userMessage: UserMessage): Promise<void> {
    if (!document.hasFocus()) {
      return;
    }

    const now = Date.now();
    const lastTimeSent = this.messageTimeMap.get(userMessage.messageParts.join());
    if (lastTimeSent && now < lastTimeSent + this.minTimeBetweenSameMessageMs) {
      return;
    }

    this.userMessages.push(userMessage);
    this.presentAllToasts();
  }

  async showSingleToast(userMessage: UserMessage): Promise<HTMLIonToastElement> {
    const toastButtons: Array<string | ToastButton> = [];
    const translatedMessage = await this.getTranslatedString(userMessage.messageParts);
    if (userMessage.showCloseButton) {
      toastButtons.push({ side: 'end', icon: 'close' });
    }
    if (userMessage.icon) {
      toastButtons.push({ side: 'start', icon: userMessage.icon });
    }
    return this.toastController.create({
      message: translatedMessage,
      duration: userMessage.durationMs,
      position: userMessage.position,
      color: userMessage.color,
      cssClass: UserMessageService.toastCssClass,
      animated: true,
      buttons: toastButtons,
    });
  }

  private getTranslatedString(translateKeys: string[]): Promise<string> {
    return firstValueFrom(
      this.translateService.get(translateKeys).pipe(map((translation) => Object.values(translation).join('')))
    );
  }

  private presentAllToasts(): void {
    if (!this.toastActive && this.userMessages.length > 0) {
      const userMessage = this.userMessages.shift();
      if (!userMessage || userMessage.timestamp + this.messageOutdatedAfterMs < Date.now()) {
        this.presentAllToasts();
        return;
      }

      const now = Date.now();
      const lastTimeSent = this.messageTimeMap.get(userMessage.messageParts.join(''));
      if (lastTimeSent && now < lastTimeSent + this.minTimeBetweenSameMessageMs) {
        this.presentAllToasts();
        return;
      }

      this.messageTimeMap.set(userMessage.messageParts.join(''), Date.now());

      this.toastActive = true;
      this.showSingleToast(userMessage).then((toast) => {
        toast.onDidDismiss().then(() => {
          this.toastActive = false;
          this.presentAllToasts();
        });
        toast.present();
      });
    }
  }
}

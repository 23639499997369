import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { POI } from 'src/app/maps/domain/masterdata/poi.model';

export class POIAdapter {
  static adapt(item: any): POI {
    if (!item) {
      return POI.empty;
    }

    const defaultIconSize = item.defaultIconSize ? item.defaultIconSize : POI.defaultIconSize;
    const weight = item.weight ? Number(item.weight) : null;

    let positions: MapPosition[] = [];
    if (item.iconPosition && item.iconPosition !== '') {
      let iconPositions: MapPosition[] | MapPosition = JSON.parse(item.iconPosition);
      if (!Array.isArray(iconPositions)) {
        iconPositions = [iconPositions];
      }
      positions = iconPositions;
    }

    return new POI({
      guid: item.guid,
      name: item.name,
      nameEn: item.nameEn,
      type: item.type,
      info: item.info,
      website: item.website,
      season: item.season,
      status: item.status != null ? item.status : AssetStatus.NoStatus,
      path: item.path,
      pathDashArray: item.dashArray,
      pathColor: item.color,
      pathWeight: weight,
      defaultPathColor: POI.defaultColor,
      defaultPathWeight: POI.defaultWeight,
      iconPositions: positions,
      iconImageName: item.type,
      iconWidth: defaultIconSize[0],
      iconHeight: defaultIconSize[1],
    });
  }

  static adaptForApi(poi: POI): any {
    const path = poi.path || null;
    const color = poi.pathColor === POI.defaultColor ? null : poi.pathColor || null;
    const weight = poi.pathWeight === POI.defaultWeight ? null : poi.pathWeight || null;
    const dashArray = poi.pathDashArray || null;

    return {
      guid: poi.guid,
      assetCategory: poi.category,
      path,
      iconPosition: JSON.stringify(poi.iconPositions),
      color,
      dashArray,
      weight,
    };
  }
}

import { CustomIconAdapter } from 'src/app/maps/domain/masterdata/custom-icon.adapter';
import { CustomIcon } from 'src/app/maps/domain/masterdata/custom-icon.model';
import { CustomPathAdapter } from 'src/app/maps/domain/masterdata/custom-path.adapter';
import { CustomPath } from 'src/app/maps/domain/masterdata/custom-path.model';
import { GastroAdapter } from 'src/app/maps/domain/masterdata/gastro.adapter';
import { Gastro } from 'src/app/maps/domain/masterdata/gastro.model';
import { LiftAdapter } from 'src/app/maps/domain/masterdata/lift.adapter';
import { Lift } from 'src/app/maps/domain/masterdata/lift.model';
import { MasterData } from 'src/app/maps/domain/masterdata/masterdata.model';
import { PlaceAdapter } from 'src/app/maps/domain/masterdata/place.adapter';
import { Place } from 'src/app/maps/domain/masterdata/place.model';
import { POIAdapter } from 'src/app/maps/domain/masterdata/poi.adapter';
import { POI } from 'src/app/maps/domain/masterdata/poi.model';
import { SignPost } from 'src/app/maps/domain/masterdata/sign-post.model';
import { SlopeAdapter } from 'src/app/maps/domain/masterdata/slope.adapter';
import { Slope } from 'src/app/maps/domain/masterdata/slope.model';
import { TrailAdapter } from 'src/app/maps/domain/masterdata/trail.adapter';
import { Trail } from 'src/app/maps/domain/masterdata/trail.model';
import { WebcamAdapter } from 'src/app/maps/domain/masterdata/webcam.adapter';
import { Webcam } from 'src/app/maps/domain/masterdata/webcam.model';
import { WindAdapter } from 'src/app/maps/domain/masterdata/wind.adapter';
import { Wind } from 'src/app/maps/domain/masterdata/wind.model';
import { v4 as v4guid } from 'uuid';

export class MasterDataAdapter {
  static adapt(item: any): MasterData {
    if (!item) {
      return {
        tenantGuid: v4guid(),
        alias: '',
        name: '',
        panoMapFilename: '',
        logoFilename: '',
        showIconZoomThreshold: 1.0,
        showIconZoomThresholdMobile: 1.0,
        iconZoomFactor: 1.0,
        season: 0,
        areaUrlName: '',
        sisId: '',
        oppositeMapExist: false,
        lifts: [],
        slopes: [],
        trails: [],
        pois: [],
        places: [],
        customIcons: [],
        customPaths: [],
        gastros: [],
        webcams: [],
        winds: [],
        style: undefined,
        labelInTooltip: false,
        signPosts: [],
      };
    }

    const lifts: Lift[] = MasterDataAdapter.createList<Lift>(item.lifts, LiftAdapter.adapt).sort(
      (a, b) => a.status - b.status
    );

    lifts.forEach((lift) => {
      lift.name = MasterDataAdapter.getDisplayName(lift);
    });

    const slopes: Slope[] = MasterDataAdapter.createList<Slope>(item.slopes, SlopeAdapter.adapt).sort(
      (a, b) => a.status - b.status
    );

    slopes.forEach((slope) => {
      slope.name = MasterDataAdapter.getDisplayName(slope);
    });

    if (item.style === 'andermatt') {
      slopes
        .filter((slope) => slope.type?.toLowerCase() === 'rennpiste')
        .forEach((slope) => {
          slope.iconUseStatus = false;
          slope.iconWidth = Slope.defaultIconSize[0];
          slope.iconHeight = Slope.defaultIconSize[1];
        });
    }

    const trails: Trail[] = MasterDataAdapter.createList<Trail>(item.trails, TrailAdapter.adapt).sort(
      (a, b) => a.status - b.status
    );

    trails.forEach((trail) => {
      trail.name = MasterDataAdapter.getDisplayName(trail);
    });

    const pois: POI[] = MasterDataAdapter.createList<POI>(item.pois, POIAdapter.adapt).sort(
      (a, b) => a.status - b.status
    );

    pois.forEach((poi) => {
      poi.name = MasterDataAdapter.getDisplayName(poi);
    });

    const places: Place[] = MasterDataAdapter.createList<Place>(item.places, PlaceAdapter.adapt);

    if (item.style === 'andermatt') {
      places.forEach((place) => {
        // eslint-disable-next-line @typescript-eslint/quotes
        place.fontFamily = "'Neue Haas Grotesk Disp W04 65Md'";
        place.padding = '4px 6px 2px 6px';
      });
    }

    const customIcons: CustomIcon[] = MasterDataAdapter.createList<CustomIcon>(
      item.customIcons,
      CustomIconAdapter.adapt
    );

    const customPaths: CustomPath[] = MasterDataAdapter.createList<CustomPath>(
      item.customPaths,
      CustomPathAdapter.adapt
    );

    const gastros: Gastro[] = MasterDataAdapter.createList<Gastro>(item.gastros, GastroAdapter.adapt);

    gastros.forEach((gastro) => {
      gastro.name = MasterDataAdapter.getDisplayName(gastro);
    });

    const webcams: Webcam[] = MasterDataAdapter.createList<Webcam>(item.webcams, WebcamAdapter.adapt);

    const winds: Wind[] = MasterDataAdapter.createList<Wind>(item.windIcons, WindAdapter.adapt);

    const signPosts: SignPost[] = MasterDataAdapter.createList<SignPost>(item.signPosts, (s: any) => ({
      url: s.url.replace(/\/+$/, ''),
      name: s.name,
      displayOrder: s.displayOrder,
    })).sort((a, b) => a.displayOrder - b.displayOrder);

    return {
      tenantGuid: item.tenantGuid,
      alias: item.alias,
      name: item.name,
      panoMapFilename: item.panoMapFilename,
      logoFilename: item.logoFilename,
      mapGuid: item.mapGuid,
      showIconZoomThreshold: item.showIconZoomThreshold,
      showIconZoomThresholdMobile: item.showIconZoomThresholdMobile,
      iconZoomFactor: item.iconZoomFactor,
      season: item.season,
      areaUrlName: item.areaUrlName,
      sisId: item.sisId,
      oppositeMapExist: item.oppositeMapExist,
      lifts,
      slopes,
      trails,
      pois,
      places,
      customIcons,
      customPaths,
      gastros,
      webcams,
      winds,
      style: item.style ?? 'sisag',
      labelInTooltip: item.labelInTooltip,
      signPosts,
    };
  }

  private static createList<T>(item: any[], adapt: (a: any) => T): T[] {
    return item?.map((e) => adapt(e)) ?? [];
  }

  private static getDisplayName(element: { name: string; nameEn?: string }): string {
    const language = new URLSearchParams(window.location.search).get('language') || navigator.language;
    return (language.startsWith('en') && element.nameEn) ? element.nameEn : element.name;
  }

}

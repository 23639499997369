import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, firstValueFrom, map, startWith, takeUntil } from 'rxjs';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { UserMessage } from 'src/app/core/user-message/user-message.model';
import { UserMessageService } from 'src/app/core/user-message/user-message.service';
import { UserMessageColor } from 'src/app/core/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/core/user-message/user-message-icon';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';
import { MapStateService } from 'src/app/maps/map/map-state.service';
import { AssetEditorService } from 'src/app/maps/map/sidepane/asset-editor-tab/asset-editor.service';

@Component({
  selector: 'sis-sismap-sidepane',
  templateUrl: './sidepane.component.html',
  styleUrls: ['./sidepane.component.scss'],
})
export class SidepaneComponent extends Unsubscriber implements OnInit {
  readonly hasErrors$ = this.assetEditorService.assetsWithError$.pipe(
    map((assetsWithError) => assetsWithError.size > 0)
  );

  readonly tabs = ['Eigenschaften', 'Hinzufügen'];

  readonly canSave$ = combineLatest([this.hasErrors$, this.assetEditorService.hasChanges$]).pipe(
    map(([hasErrors, hasChanges]) => !hasErrors && hasChanges),
    startWith(false)
  );

  readonly editToken$ = this.activatedRoute.queryParams.pipe(map((params) => params['edit']));
  readonly assetsWithErrorTooltip$ = this.assetEditorService.assetsWithError$.pipe(
    map((assetMap) =>
      [...assetMap.values()].map((asset) => `${SisMapAssetCategory[asset.category]}: ${asset.name}`).join(' \n ')
    )
  );

  selectedTab = 1;

  constructor(
    private assetEditorService: AssetEditorService,
    private userMessageService: UserMessageService,
    private mapStateService: MapStateService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.mapStateService.elementClicked$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((element) => (this.selectedTab = element.element == null ? 1 : 0));
    this.assetEditorService.addedAsset$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((element) => (this.selectedTab = element == null ? 1 : 0));
  }

  changeToTab(tabIndex: number): void {
    this.selectedTab = tabIndex;
  }

  async save(): Promise<void> {
    const success = await this.assetEditorService.saveAll();

    if (success) {
      const selectedElement = await firstValueFrom(this.mapStateService.selectedElement$);
      this.mapStateService.selectElement(selectedElement.element, selectedElement.iconIndex);
    }

    const userMessage = new UserMessage({
      color: success ? UserMessageColor.green : UserMessageColor.red,
      durationMs: 4000,
      icon: success ? UserMessageIcon.success : UserMessageIcon.failed,
      message: success ? 'user.message.success' : 'user.message.failed',
      position: 'top',
    });

    const toast = await this.userMessageService.showSingleToast(userMessage);
    await toast.present();
  }

  async initStatus(): Promise<void> {
    let userMessage: UserMessage;
    try {
      await this.assetEditorService.initializeAssetStatus();
      userMessage = new UserMessage({
        color: UserMessageColor.green,
        durationMs: 4000,
        icon: UserMessageIcon.success,
        message: 'user.message.statusInitSuccess',
        position: 'top',
      });
    } catch (e) {
      userMessage = new UserMessage({
        color: UserMessageColor.red,
        durationMs: 4000,
        icon: UserMessageIcon.failed,
        message: 'user.message.statusInitFailed',
        position: 'top',
      });
    }
    const toast = await this.userMessageService.showSingleToast(userMessage);
    await toast.present();
  }

  tokenInput(event: any): void {
    const token = event.target.value;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { edit: token },
      queryParamsHandling: 'merge',
    });
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomUtil, LatLng, latLng, LayerGroup, Marker } from 'leaflet';
import { combineLatest, firstValueFrom, merge } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { Lift } from 'src/app/maps/domain/masterdata/lift.model';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { MasterDataService } from 'src/app/maps/domain/masterdata/masterdata.service';
import { HoverService } from 'src/app/maps/map/hover.service';
import { IconService } from 'src/app/maps/map/icon-service';
import { LayerService } from 'src/app/maps/map/layer-selector/layer.service';
import { MapStateService } from 'src/app/maps/map/map-state.service';
import { AssetEditorService } from 'src/app/maps/map/sidepane/asset-editor-tab/asset-editor.service';
import { MapStyleService } from 'src/app/maps/map/style/map-style.service';
import { SisZoom } from 'src/app/maps/map/zoom.model';
import { ZoomService } from 'src/app/maps/map/zoom.service';

@Component({
  selector: 'sis-map-element-icon',
  template: '',
  styleUrls: ['./map-element-icon.component.scss'],
})
export class MapElementIconComponent extends Unsubscriber implements OnInit, OnDestroy {
  @Input() element: MapElement;
  @Input() layerGroup: LayerGroup;
  @Input() positionIndex: number;
  @Input() showStatus = true;
  @Input() editMode: boolean;

  private marker: Marker;
  private showIcon = false;
  private isMarked = false;
  private hasError = false;
  private showFindMarkerAnimation = false;
  private bigScreenMode = false;

  constructor(
    private iconService: IconService,
    private zoomService: ZoomService,
    private hoverService: HoverService,
    private layerService: LayerService,
    private mapStateService: MapStateService,
    private mapStyleService: MapStyleService,
    private screenSizeService: ScreenSizeService,
    private assetEditorService: AssetEditorService,
    private masterDataService: MasterDataService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    if (this.element && this.element.iconPositions.length > 0) {
      const marker = await this.iconService.getMarker(this.element.iconPositions[this.positionIndex], this.element);

      this.setupMarker(marker, this.element.guid);

      this.mapStateService.elementUpdated$
        .pipe(
          filter((e) => e.guid === this.element.guid),
          takeUntil(this.onDestroy$)
        )
        .subscribe((e) => this.updateIcon(e));

      this.mapStateService.highlightedElement
        .pipe(
          filter(
            ({ element, iconIndex }) =>
              element.guid === this.element.guid && (iconIndex === null || iconIndex === this.positionIndex)
          ),
          takeUntil(this.onDestroy$)
        )
        .subscribe(() => {
          this.showFindMarkerAnimation = true;
          this.updateIcon();
        });

      combineLatest([
        merge(this.mapStateService.selectedElement$, this.mapStateService.elementMoved$),
        this.assetEditorService.assetsWithError$,
      ])
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(([selectedOrMovedEvent, errorMap]) => {
          const element = selectedOrMovedEvent.element;
          const iconIndex = selectedOrMovedEvent.iconIndex;
          const showFindMarkerAnimation =
            'showFindMarkerAnimation' in selectedOrMovedEvent && selectedOrMovedEvent.showFindMarkerAnimation != null
              ? selectedOrMovedEvent.showFindMarkerAnimation
              : false;
          this.hasError = errorMap.has(this.element.guid);

          if (
            (element?.guid === this.element.guid && (iconIndex === null || iconIndex === this.positionIndex)) ||
            this.hasError
          ) {
            this.markElement(showFindMarkerAnimation);
          } else {
            this.unmarkElement();
          }
        });

      this.screenSizeService.bigScreenMode$.pipe(takeUntil(this.onDestroy$)).subscribe((bigScreenMode) => {
        this.bigScreenMode = bigScreenMode;
        const draggable = bigScreenMode && this.editMode;
        this.marker.options.draggable = draggable;
        if (draggable) {
          this.marker.dragging?.enable();
        } else {
          this.marker.dragging?.disable();
        }
      });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.layerGroup.removeLayer(this.marker);
  }

  private async updateIcon(element?: MapElement): Promise<void> {
    if (element != null) {
      Object.assign(this.element, element);
    }

    const icon = await this.iconService.createIconElement(this.element);
    this.marker.setIcon(icon);
    if (this.element.status != null) {
      const colors =
        this.element instanceof Lift
          ? await this.mapStyleService.getLiftColor(this.element.status, this.element, true)
          : await this.mapStyleService.getColor(this.element.status);
      await this.mapStyleService.setIconColor(this.marker, colors.statusColor, colors.textColor);
    }

    if (this.isMarked && this.marker.getElement() != null && this.editMode) {
      DomUtil.addClass(this.marker.getElement(), 'map-element-icon-marked');
      if (this.hasError) {
        DomUtil.addClass(this.marker.getElement(), 'map-element-icon-error');
      }
    }
    if (this.showFindMarkerAnimation && this.marker.getElement() != null) {
      DomUtil.addClass(this.marker.getElement(), 'map-element-icon-find-marker');
      this.marker.getElement().addEventListener(
        'animationend',
        () => {
          DomUtil.removeClass(this.marker.getElement(), 'map-element-icon-find-marker');
        },
        { once: true }
      );
      this.showFindMarkerAnimation = false;
    }

    await this.mapStyleService.setIconBorder(this.marker);
    const pos = this.element.iconPositions[this.positionIndex];

    if (pos == null) {
      // Due to asynchronous execution the position index got deleted while
      // we were waiting for the icon element. Just return here as component will be
      // deleted later by angular
      return;
    }

    this.marker.setLatLng(latLng(pos.x, pos.y));

    const zoom = await firstValueFrom(this.zoomService.zoomChanged$);
    this.zoomIcon(zoom);
  }

  private markElement(highlight: boolean): void {
    this.isMarked = true;
    this.showFindMarkerAnimation = highlight;
    this.updateIcon();
  }

  private unmarkElement(): void {
    if (this.isMarked) {
      this.isMarked = false;
      this.showFindMarkerAnimation = false;
      this.updateIcon();
    }
  }

  private onMarkerAdded(): void {
    this.updateIcon();
  }

  private setupMarker(marker: Marker, guid: string, drawBorder: boolean = true): void {
    this.marker = marker;

    this.hoverService.onHover.pipe(takeUntil(this.onDestroy$)).subscribe((hoverData) => {
      if (!this.bigScreenMode) {
        return;
      }

      const hovered =
        !this.hoverService.hoverDisabled &&
        hoverData.guid === guid &&
        hoverData.hover &&
        (!hoverData.marker || hoverData.marker === this.marker);

      this.highlightMarker(hovered);

      if (hovered && hoverData.iconHovered && !this.element.disallowPopUp && this.showStatus) {
        this.createAndShowPopup(this.marker);
      } else {
        this.marker.closePopup();
      }
    });

    this.addEventListeners();

    combineLatest([this.layerService.shownAssetGuids$, this.zoomService.zoomChanged$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([shownAssetGuids, zoom]) => {
        if (shownAssetGuids.includes(this.element.guid) && zoom.showIcons) {
          this.showLayer();
        } else {
          this.hideLayer();
        }
      });
  }

  private async createAndShowPopup(marker: Marker): Promise<void> {
    if (marker == null) {
      return;
    }

    const masterData = await firstValueFrom(this.masterDataService.masterData);
    const zoom = await firstValueFrom(this.zoomService.zoomChanged$);
    const offsetY = (this.element.iconHeight * zoom.iconZoom * 1.25) / 2 - 7;
    const { popupContent, className } = await this.mapStyleService.createIconPopupContent(
      this.element,
      masterData.labelInTooltip
    );

    marker.unbindPopup();
    marker.bindPopup(popupContent, {
      closeButton: false,
      offset: [0, -offsetY],
      className: `sis-map-tooltip ${className}`,
      autoPan: false,
      maxWidth: 1000,
    });
    marker.openPopup();
  }

  private async highlightMarker(highlight: boolean): Promise<void> {
    if (this.element.disallowHighlight) {
      return;
    }
    const zoom = await firstValueFrom(this.zoomService.zoomChanged$);
    this.iconService.scaleIcon(this.marker, zoom, this.element, highlight);
  }

  private addEventListeners(): void {
    this.marker.on('add', () => {
      this.onMarkerAdded();
    });

    this.marker.once('add', () => {
      if (!this.showIcon || this.hoverService.hoverDisabled) {
        this.marker.getElement().style.cursor = 'grab';
      }

      this.zoomService.zoomChanged$.pipe(takeUntil(this.onDestroy$)).subscribe((zoom) => {
        this.zoomIcon(zoom);
      });

      this.marker.on('click', () => {
        this.hoverService.hover({ guid: this.element.guid, hover: false, iconHovered: false, marker: this.marker });
        this.mapStateService.clickElement(this.element, this.positionIndex);
      });

      this.marker.on('mouseover', () => {
        this.hoverService.hover({ guid: this.element.guid, hover: true, iconHovered: true, marker: this.marker });
      });

      this.marker.on('mouseout', () => {
        this.hoverService.hover({ guid: this.element.guid, hover: false, iconHovered: false, marker: this.marker });
      });

      this.marker.on('drag', () => {
        const markerPos = this.marker.getLatLng();
        this.marker.setLatLng(new LatLng(Math.trunc(markerPos.lat), Math.trunc(markerPos.lng)));
      });

      this.marker.on('dragend', () => {
        const markerPos = this.marker.getLatLng();
        this.marker.setLatLng(new LatLng(Math.trunc(markerPos.lat), Math.trunc(markerPos.lng)));
        const newPosition: MapPosition = { x: markerPos.lat, y: markerPos.lng };
        this.mapStateService.moveMapElement(this.element, this.positionIndex, newPosition);
        this.hoverService.hover({ guid: this.element.guid, hover: true, iconHovered: true, marker: this.marker });
      });
    });
  }

  private zoomIcon(zoom: SisZoom): void {
    this.iconService.scaleIcon(this.marker, zoom, this.element);
    this.showIcon = zoom.showIcons;
    const e = this.marker.getElement();

    if (
      this.editMode ||
      (this.showIcon &&
        !this.hoverService.hoverDisabled &&
        !(this.element.disallowHighlight && this.element.disallowPopUp))
    ) {
      if (e) {
        e.style.cursor = 'pointer';
      }
    }
  }

  private showLayer(): void {
    if (!this.layerGroup.hasLayer(this.marker)) {
      this.layerGroup.addLayer(this.marker);
    }
  }

  private hideLayer(): void {
    if (this.layerGroup.hasLayer(this.marker)) {
      this.layerGroup.removeLayer(this.marker);
    }
  }
}

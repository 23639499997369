import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { AppComponent } from 'src/app/app.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { CoreModule } from 'src/app/core/core.module';
import { NavigatorService } from 'src/app/core/navigator/navigator.service';

declare const VERSION: string;

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'www.siscontrol.ch',
  },
  palette: {
    popup: {
      background: '#6b6b6b',
    },
    button: {
      background: '#0fafa9',
    },
  },
  theme: 'edgeless',
  position: 'bottom',
  type: 'info',
  showLink: false,
  content: {},
};

export function translateHttpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpBackend), './assets/translations/', `.json?version=${VERSION}`);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    CoreModule,
    BrowserAnimationsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    NavigatorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

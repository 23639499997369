import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { OperatingInfo } from 'src/app/operating-info/domain/operating-info.model';
import { OperatingInfoTenant } from 'src/app/operating-info/domain/operating-info-tenant.model';
import { OperatingInfoLink } from 'src/app/operating-info/domain/operting-info-link.model';
import { OperatingInfoService } from 'src/app/operating-info/operating-info.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sis-operating-info',
  templateUrl: 'operating-info.page.html',
  styleUrls: ['operating-info.page.scss'],
})
export class OperatingInfoPage extends Unsubscriber implements OnInit {
  private static readonly OperatingInfoUrl = 'operatinginfoUrl';

  private readonly updateInterval = 60_000;

  logoUrl: string;
  operatingInfo: OperatingInfo;
  isMultiTenant: boolean;
  publicAssetLogoBaseUrl: string;

  constructor(
    private operatingInfoService: OperatingInfoService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.publicAssetLogoBaseUrl = `${environment.baseUrlPublicAssets}/logo/`;

    this.activatedRoute.params
      .pipe(
        switchMap((params) =>
          this.operatingInfoService.periodicallyRequestOperatingInfoData(
            this.updateInterval,
            params[OperatingInfoPage.OperatingInfoUrl]
          )
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe((data: OperatingInfo) => {
        if (!data) {
          return;
        }

        data.links = data.links.sort((a, b) => a.displayOrder - b.displayOrder);
        // sort: newest first
        data.entries = data.entries.sort((a, b) => b.timestamp.valueOf() - a.timestamp.valueOf());

        // if at least one entry with prio > 0, remove all prio 0 entries
        if (data.entries.find((entry) => entry.priority !== 0)) {
          data.entries = data.entries.filter((entry) => entry.priority !== 0);
        } else {
          // remove double 'No information available' entries for multi tenants and set global title
          if (data.entries.length > 1) {
            data.entries = data.entries.slice(0, 1);
            data.entries[0].texts.forEach((text) => (text.title = data.title));
          }
        }

        this.isMultiTenant = data.tenants.length > 1;
        if (this.isMultiTenant) {
          data.tenants.sort((a, b) => a.order - b.order);
        }
        this.operatingInfo = data;

        this.logoUrl = `${environment.baseUrlPublicAssets}/logo/${data.logo}`;
        this.setDescriptions(
          this.translateService.currentLang ?? this.translateService.defaultLang,
          this.operatingInfo.links
        );
      });

    this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe((event) => {
      if (this.operatingInfo) {
        this.setDescriptions(event.lang, this.operatingInfo.links);
      }
    });
  }

  trackByGuid(_: number, tenant: OperatingInfoTenant): string {
    return tenant.guid;
  }

  private setDescriptions(languageKey: string, links: OperatingInfoLink[]): void {
    if (!links) {
      return;
    }
    links.forEach((link) => {
      switch (languageKey) {
        case 'de':
          link.description = link.descriptionDe;
          break;
        case 'en':
          link.description = link.descriptionEn;
          break;
        case 'fr':
          link.description = link.descriptionFr;
          break;
        case 'it':
          link.description = link.descriptionIt;
          break;
        default:
          link.description = link.descriptionEn;
          break;
      }
    });
  }
}

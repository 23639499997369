import { PointExpression } from 'leaflet';
import { Season } from 'src/app/core/domain/season.enum';
import { WritablePart } from 'src/app/core/utils/type-helper';
import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';

export class Gastro implements MapElement {
  static readonly defaultColor: string = 'white';
  static readonly defaultIconSize: PointExpression = [18, 18];

  static readonly empty: Gastro = new Gastro({ guid: null, status: AssetStatus.Disabled });

  readonly category = SisMapAssetCategory.Gastro;

  guid: string;
  name: string;
  nameEn: string;
  season: Season;
  status: AssetStatus;
  type: string;
  contact?: string;
  location?: string;
  phone?: string;
  email?: string;
  website?: string;
  info?: string;
  iconPositions: MapPosition[];
  iconWidth: number;
  iconHeight: number;
  iconImageName?: string;
  paneName = 'gastro';

  constructor(init?: Partial<WritablePart<Gastro>>) {
    Object.assign(this, init);
  }
}

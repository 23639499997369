import { Season } from 'src/app/core/domain/season.enum';
import { WritablePart } from 'src/app/core/utils/type-helper';
import { AssetStatus } from 'src/app/maps/domain/livedata/status.enum';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';

export class CustomPath implements MapElement {
  static readonly defaultColor: string = 'black';
  static readonly defaultWeight: number = 2;
  static readonly empty: CustomPath = new CustomPath({ guid: null });

  readonly category = SisMapAssetCategory.CustomPath;

  guid: string;
  season: Season;
  name: string;
  type: string;
  path?: string;
  pathDashArray?: string;
  pathWeight?: number;
  pathColor?: string;
  paneName = 'customPath';
  status?: AssetStatus = AssetStatus.NoStatus;

  defaultPathColor: string;
  defaultPathWeight: number;

  disallowHighlight: boolean;
  disallowPopUp: boolean;
  iconPositions: MapPosition[];
  iconWidth: number;
  iconHeight: number;
  iconSvgBlobName?: string;

  url: string;

  constructor(init?: Partial<WritablePart<CustomPath>>) {
    Object.assign(this, init);

    this.defaultPathColor = CustomPath.defaultColor;
    this.defaultPathWeight = CustomPath.defaultWeight;
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { from, map, Observable } from 'rxjs';
import { LayerSelectorItem } from 'src/app/maps/map/layer-selector/layer-selector-item/layer-selector-item.model';
import { MapStyleService } from 'src/app/maps/map/style/map-style.service';

@Component({
  selector: 'sis-layer-selector-item',
  templateUrl: './layer-selector-item.component.html',
  styleUrls: ['./layer-selector-item.component.scss'],
})
export class LayerSelectorItemComponent implements OnInit, OnChanges {
  @Input() layerSelector: LayerSelectorItem;
  @Input() active: boolean;
  @Output() clicked = new EventEmitter();

  iconUrl$: Promise<string>;
  iconClass$: Observable<string>;
  tooltipTranslationString: string;
  dataTestKey: string;

  constructor(private mapStyleService: MapStyleService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['active']) {
      this.iconUrl$ = this.mapStyleService.getLayerIconSvgUrl(this.layerSelector.imageName, this.active);
      this.iconClass$ = from(this.mapStyleService.getStyleName()).pipe(map((name) => `sis-icon-${name}`));
    }
  }

  ngOnInit(): void {
    this.tooltipTranslationString = `map.layerselector.term.${this.layerSelector.category.name}`;
    this.dataTestKey = `sis-layer-selector-item-${this.layerSelector.category.name}`;
  }

  onClick($event): void {
    $event.stopPropagation();
    this.clicked.emit();
  }
}

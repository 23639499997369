import { Season } from 'src/app/core/domain/season.enum';
import { WritablePart } from 'src/app/core/utils/type-helper';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { MapPosition } from 'src/app/maps/domain/masterdata/map-position.model';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';

export class CustomIcon implements MapElement {
  static readonly defaultColor: string = 'white';
  static readonly empty: CustomIcon = new CustomIcon({ guid: null });

  readonly category = SisMapAssetCategory.CustomIcon;

  guid: string;
  season: Season;
  name: string;
  type: string;
  disallowHighlight: boolean;
  disallowPopUp: boolean;
  iconPositions: MapPosition[];
  iconWidth: number;
  iconHeight: number;
  iconSvgBlobName?: string;
  paneName = 'customIcon';

  url: string;

  constructor(init?: Partial<WritablePart<CustomIcon>>) {
    Object.assign(this, init);
  }
}

<div *ngIf="layerGroup">
  <sis-map-element-curve
    *ngIf="element.path"
    [attr.data-test]="curveTestClass"
    [layerGroup]="layerGroup"
    [element]="element"
    [showStatus]="this.showStatus"
    [editMode]="this.editMode"
  ></sis-map-element-curve>
  <ng-container *ngIf="!this.element.iconUseStatus || this.showStatusIcons">
    <sis-map-element-icon
      *ngFor="let icon of this.element.iconPositions; index as i"
      [attr.data-test]="iconTestClass"
      [layerGroup]="layerGroup"
      [element]="element"
      [positionIndex]="i"
      [editMode]="this.editMode"
    ></sis-map-element-icon>
  </ng-container>
</div>
